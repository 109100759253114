import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import { useReactToPrint } from "react-to-print";
import DownloadComponent from "../CSVDownload/DownloadComponent";
import DownloadPDFButton from "../CSVDownload/DownloadPDFButton";

const ItemDetailReport = ({ StartDate }) => {
  const storeData = JSON.parse(localStorage.getItem("STORE_DATA"));
  const { saasId, storeId } = storeData;
  const [data, setData] = useState([]);
  const GetData = async () => {
    try {
      const response = await axios.get(
        `${BASE_Url}/user-master/item_report/${saasId}/${storeId}/${StartDate}`
      );
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetData();
  }, [StartDate]);

  //new update code
  const tableRef = useRef(null); // Ref for the table container
    const [printingstart, setPrintingStart] = React.useState(false);
  const handleTablePrint = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: "Item Report",
    onBeforePrint: () => {
      console.log("pting Item Reportt...");
      setPrintingStart(true);
    },
    onAfterPrint: () => {
      setPrintingStart(false) 
      
    },
  });

  const Header = [
    { label: "Quantity", key: "qty" },
    { label: "Category Name", key: "name" },
    { label: "Item Name", key: "item_name" },
    { label: "Total Sales", key: "total_sale" },
  ];
  return (
    <>
      <DownloadComponent
        headers={Header}
        data={data}
        fileName="ItemReport"
      />
      <Button
        variant="contained"
        className="mx-2"
        style={{ background: "rgb(14, 41, 84)", color: "white" }}
        color="primary"
        onClick={handleTablePrint}
      >
        PRINT
      </Button>
      {/* Reusable PDF Download Button */}
      <DownloadPDFButton
        data={data}
        headers={Header}
        fileName="ItemReport.pdf"
      />
        <TableContainer 
           style={{maxHeight: printingstart ? "":'calc(100vh - 250px)'  ,  maxWidth: 'calc(100vh + 250px)' , overflow:"auto"}} 
           component={Paper}>
        <Table ref={tableRef}>
          <TableHead className="bg-dark-purple">
            <TableRow>
              <TableCell className=" text-white">Category Name</TableCell>
              <TableCell className=" text-white">Item Name</TableCell>
              <TableCell className=" text-white">Quantity</TableCell>
              <TableCell className=" text-white">Total Sale</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.item_name}</TableCell>
                <TableCell>{row.qty}</TableCell>
                <TableCell>{row.total_sale}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ItemDetailReport;
