import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip, LabelList, Label } from 'recharts';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { BASE_Url } from '../../URL.js/URL';
import DownloadComponent from '../CSVDownload/DownloadComponent';
import DownloadPDFButton from '../CSVDownload/DownloadPDFButton';
import { useReactToPrint } from 'react-to-print';

function ItemReport({ formDate, toDate }) {
  const [allData, setAllData] = useState([]);
  const {storeId, saasId}= JSON.parse(localStorage.getItem('STORE_DATA'));
  useEffect(() => {
    axios.get(`${BASE_Url}/user-master/item_performance/${saasId}/${storeId}/${formDate}/${toDate}`)
      .then((res) => {
        setAllData(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [formDate, toDate]);

  const formattedData = allData.map(item => ({
    name: item.item_name,
    sales: parseFloat(item.sales),
    order_count: parseInt(item.order_count)
  }));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{
          background: '#fff',
          border: '1px solid #ccc',
          borderRadius: '10px',
          padding: '10px',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        }}>
          <p style={{ margin: 0, }}>{` ${label}`}</p>
          <p style={{ margin: 0 }}>{`Order Count: ${data.order_count}`}</p>
          <p style={{ margin: 0, color:"green" }}>{`Sales: ${data.sales}`}</p>
        </div>
      );
    }
    return null;
  };

  const header =[
    { label: 'Category Name', key: 'category', width: 500 }, 
    { label: 'Item Name', key: 'item_name', width: 500 }, 
    { label: 'Quantity', key: 'qty', width: 500 }, 
    { label: 'Sales', key: 'sales', width: 150 }, 
    { label: 'Order Count', key: 'order_count', width: 150 }, 
  ]


  
    //new update code
    const tableRef = useRef(null); // Ref for the table container
    const [printingstart, setPrintingStart] = React.useState(false);
    const handleTablePrint = useReactToPrint({
      content: () => tableRef.current,
      documentTitle: 'Sales_Report',
      onBeforePrint: () => {
        console.log("pting Sales_Report...");
        setPrintingStart(true);
      },
      onAfterPrint: () => {
        setPrintingStart(false) 
        
      },
    });
  
  return (
    <div>
      <DownloadComponent headers={header} data={allData} fileName="ItemsReport"/>
     
        <Button
          variant="contained"
          className="mx-2"
          style={{background:"rgb(14, 41, 84)",color:"white"}}
          onClick={handleTablePrint}
        >
          PRINT 
        </Button>
           {/* Reusable PDF Download Button */}
           <DownloadPDFButton
          data={allData}
          headers={header}
          fileName="ItemReport.pdf"
        />
      {/* <ResponsiveContainer ref={tableRef} width="100%" height={300}>
        <BarChart data={formattedData} margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis className='text-xs' dataKey="name">
            <Label value="Item Name" offset={-5} position="insideBottom" className='font-bold text-xs text-black' />
          </XAxis>
          <YAxis label={{ value: 'Sales', angle: -90, position: 'insideLeft' }} className='font-bold text-xs text-black' />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="sales" fill="#60a5fa">
            <LabelList dataKey="sales" position="insideTop" className='font-bold text-xs text-black' />
          </Bar>
        </BarChart>
      </ResponsiveContainer> */}
         <Box  className="h-100 w-100 rounded-lg">
       <TableContainer 
       style={{maxHeight: printingstart ? "":'calc(100vh - 250px)'  ,  maxWidth: 'calc(100vh + 250px)' , overflow:"auto"}} 
       component={Paper}>
        <Table ref={tableRef}>
          <TableHead className="bg-dark-purple">
            <TableRow>
              {/* <TableCell className=" text-white">Quantity</TableCell> */}
              <TableCell className=" text-white">Category Name</TableCell>
              <TableCell className=" text-white">Item Name</TableCell>
              <TableCell className=" text-white">Quantity</TableCell>
              <TableCell className=" text-white">Order Count</TableCell>
              <TableCell className=" text-white">Total Sale</TableCell>
              <TableCell className=" text-white">Store Id</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {allData.map((row, index) => (
              <TableRow key={index}>
                {/* <TableCell>{row.qty}</TableCell> */}
                <TableCell className="capitalize">{row.category}</TableCell>
                <TableCell className="capitalize">{row.item_name}</TableCell>
                <TableCell>{row.qty}</TableCell>
                <TableCell>{row.order_count}</TableCell>
                <TableCell>{row.sales}</TableCell>
                <TableCell>{row.storeId}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
    </div>
  );
}

export default ItemReport;
