import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import Swal from 'sweetalert2';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AssignDelivery({GetOrderByStatus,orderId}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
  const [status, setStatus] = React.useState('');
    const handleChange = (event) => {
        setStatus(event.target.value);
    }

    const handleAssign = async() => {
        try {
            const res = await axios.put(`${BASE_Url}/order/update-status/${storeId}/${saasId}/${orderId}/${status}`);
            console.log("Assigned",res.data);
            if(res.data.status){
                GetOrderByStatus();
                Swal.fire ({
                    icon: 'success',
                    title: 'Assigned',
                    text: res.data.message,
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

  return (
    <div className='flex justify-center'>
      <Button onClick={handleOpen}>Assign Delivery Boy</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Status"
          onChange={handleChange}
        >
          <MenuItem value="Delivered">Delivered</MenuItem>
          <MenuItem value="DELIVERYBOY">DELIVERYBOY</MenuItem>
        </Select>
      </FormControl>
        <Button className='mt-2 mx-2' variant="contained" onClick={handleAssign}>Assign</Button>
        <Button className='mt-2' variant="contained" onClick={handleClose}>Close</Button>
        </Box>
      </Modal>
    </div>
  );
}