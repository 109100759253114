import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Box,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';

const RestaurantTables = ({ data }) => {
    const navigate = useNavigate()
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  return (
    <Box sx={{ width: '100%' }}>
      {data.map((category) => (
        <Accordion key={category.category_id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`category-${category.category_id}-content`}
            id={`category-${category.category_id}-header`}
          >
            <Typography variant="h6">{category.category}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
              <Table stickyHeader aria-label={`${category.category} tables`}>
                <TableHead>
                  <TableRow>
                    <TableCell>Table</TableCell>
                    <TableCell>Status</TableCell>
                    {/* <TableCell>Order</TableCell> */}
                    {/* <TableCell align="right">Amount</TableCell> */}
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {category.table_list.map((table) => (
                    <TableRow key={table.table_id}>
                      <TableCell component="th" scope="row">
                        {table.table_name}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={table.status}
                          color={table.status === 'Empty' ? 'default' : 'primary'}
                          size="small"
                        />
                      </TableCell>
                      {/* <TableCell>{table.order_status || '-'}</TableCell>
                      <TableCell align="right">
                        {table.total_amount ? `$${table.total_amount.toFixed(2)}` : '-'}
                      </TableCell> */}
                      <TableCell align="center">
                        <Button disabled={table?.status !=='Empty'} onClick={()=>navigate(`/${saasId}/${storeId}/${table.table_id}`)} variant='contained' color='primary'>Create Order</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default RestaurantTables;
