import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import axios from 'axios';
import React, { useRef } from 'react'
import { BASE_Url } from '../../URL.js/URL';
import DownloadComponent from '../CSVDownload/DownloadComponent';
import DownloadPDFButton from '../CSVDownload/DownloadPDFButton';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'react-bootstrap';

const CategorySummaryReport = ({Startdate}) => {

    //call api and set state with data
    const [allData, setAllData] = React.useState([]);
    const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_Url}/user-master/category_summary/${saasId}/${storeId}/${Startdate}`)
            if (response.data.status) {
                setAllData(response.data.data && response.data.data.length > 0? response.data.data : []);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    React.useEffect(() => {
        fetchData();
    }, [Startdate])

    const Header=[
        { label: "Category Name", key: "name" },
        // { label: "Orders", key: "orders" },
        // { label: "Items", key: "item_name" },
        { label: "Net Amount", key: "net_sale" },
        { label: "Total Discount", key: "total_discount" },
        { label: "Total Tax", key: "total_tax" },

        { label: "Total Sales", key: "total_sale" },
    ]


        //new update code
        const tableRef = useRef(null); // Ref for the table container
 const [printingstart, setPrintingStart] = React.useState(false);
        const handleTablePrint = useReactToPrint({
          content: () => tableRef.current,
          documentTitle: 'Sales_Report',
          onBeforePrint: () => {
            console.log("pting Sales_Report...");
            setPrintingStart(true);
          },
          onAfterPrint: () => {
            setPrintingStart(false) 
            
          },
        });
      
  return (
    <div>
        <DownloadComponent headers={Header} data={allData} fileName="CategoryReport"/>
        <Button
          variant="contained"
          className="mx-2"
          style={{background:"rgb(14, 41, 84)",color:"white"}}
       color="primary"
          onClick={handleTablePrint}
        >
          PRINT 
        </Button>
           {/* Reusable PDF Download Button */}
           <DownloadPDFButton
          data={allData}
          headers={Header}
          fileName="CategoryReport.pdf"
        />
      <Box  className="h-100 w-100 rounded-lg">
        <TableContainer 
        style={{maxHeight: printingstart ? "":'calc(100vh - 250px)'  ,  maxWidth: 'calc(100vh + 250px)' , overflow:"auto"}} 
        component={Paper}>
      <Table ref={tableRef} className="text-nowrap" aria-label="simple table">
        <TableHead className="bg-dark-purple">
          <TableRow>
            <TableCell className=" text-white">Category Name</TableCell>
            {/* <TableCell className=" text-white">Orders</TableCell> */}
            <TableCell className=" text-white">Quantity</TableCell>
            {/* <TableCell className=" text-white">Items</TableCell> */}
            <TableCell className=" text-white">Net Amount</TableCell>
            <TableCell className=" text-white">Total Discount</TableCell>
            <TableCell className=" text-white">Total Tax</TableCell>
            <TableCell className=" text-white">Total Sales</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allData?.map((ele,index) => (
            <TableRow key={index}>
              <TableCell>{ele.name}</TableCell>
              {/* <TableCell>{ele?.orders}</TableCell> */}
              <TableCell>{ele?.qty}</TableCell>
              {/* <TableCell>{ele.item_name}</TableCell> */}
              <TableCell>{ele.net_sale}</TableCell>
              <TableCell>{ele.total_discount}</TableCell>
              <TableCell>{ele.total_tax}</TableCell>
              <TableCell>{ele.total_sale}</TableCell>
              {/* <TableCell>{ele.sum(amount)}</TableCell> */}
              {/* <TableCell>{ele.tax_total}</TableCell>
              <TableCell>{ele.discount}</TableCell> */}
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box></div>
  )
}

export default CategorySummaryReport