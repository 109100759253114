import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";
function AddStore() {
  const [formData, setFormData] = useState({
    user_id: "",
    store_id: "",
    saas_id: "",
    store_name: "",
    geo_code: "",
    online: true,
    rating: "",
    cuisines: ["Italian", "Chinese", "Mexican"],
    leadref_id: "",
    store_format: "Retail",
    city: "",
    state: "",
    country: "",
    address: "",
    taxable: true,
    gst_code: "",
    tnc: "",
    hsn_code: "",
    store_type: "Restaurant",
    status: "active",
    exclusive_tax: "0",
    delivery_charges: "",
    minimum_order_value: "",
    inclusive_tax: "0",
    store_logo: "",
    bank_account: "INR",
    bank_ifsc: "",
    bank_branch: "",
    payment_qr_code: "",
    receipt_format: "1",
    email: "",
    phone_no: "",
    check_inventory: "yes",
    store_address1: "",
    store_address2: "",
    header_line1: "",
    header_line2: "",
    footer_line1: "",
    key_id: "",
    key_secret: "",
    commission:false,
    whatsApp:false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_Url}/storeMaster/add-store`, formData);
      console.log("API Response:", response.data);
      const { saasId, storeId } = response.data.data;

      if (saasId && storeId) {
        setFormData({ ...formData, saasId, storeId });

        // Call saveLogo and saveQrCode APIs with the retrieved saas_id and store_id
        await saveLogo(storeId);
        await saveQrCode(saasId, storeId);
      }

          Swal.fire({
                title: response.data.message,
                icon: "success",
                timer: 2000,
              });
    //   alert("Store added successfully!");
    } catch (error) {
      console.error("Error adding store:", error);
        Swal.fire({
                title: error.data.message,
                icon: "error",
                timer: 2000,
              });
    //   alert("Failed to add store!");
    }
  };


    // Save logo to the server
    const [logoFile, setLogoFile] = useState("Select logo");
      // Handle logo file input change
      const handleLogoChange = (event) => {
        setLogoFile(event.target.files[0]);
      };
    
      // Save logo to the server
      const saveLogo = async (saasId) => {
        if (!logoFile) {
          alert("Please select a logo file.");
          return;
        }
    
        const logoFormData = new FormData();
        logoFormData.append("file", logoFile);
    
        try {
          const response = await axios.post(
            `${BASE_Url}/storeMaster/save-store-logo/${saasId}`,
            logoFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setFormData({ ...formData, store_logo: response.data.logoUrl });
        //   alert("Logo uploaded successfully!");
        } catch (error) {
          console.error("Error uploading logo:", error);
          // alert("Failed to upload logo.");
        }
      };


      const [qrCodeFile, setQrCodeFile] = useState(null);

        // Handle QR code file input change
  const handleQrCodeChange = (event) => {
    setQrCodeFile(event.target.files[0]);
  };


   // Save QR code to the server
   const saveQrCode = async (saasId, storeId) => {
    if (!qrCodeFile) {
      alert("Please select a QR code file.");
      return;
    }

    const qrCodeFormData = new FormData();
    qrCodeFormData.append("file", qrCodeFile);

    try {
      const response = await axios.post(
        `${BASE_Url}/storeMaster/save-qrcode/${saasId}/${storeId}`,
        qrCodeFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFormData({ ...formData, payment_qr_code: response.data.qrCodeUrl });
      // alert("QR code uploaded successfully!");
    } catch (error) {
      console.error("Error uploading QR code:", error);
      // alert("Failed to upload QR code.");
    }
  };





  const [saasList, setSaasList] = useState([]);  // State to store the fetched SaaS list

  // Fetch SaaS list on component mount
  useEffect(() => {
    const fetchSaasList = async () => {
      try {
        const response = await axios.get(`${BASE_Url}/saas-master/get-all`);
        if (response.data.status) {
          setSaasList(response.data.data);  // Set the fetched SaaS data
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch SaaS list.',
          });
        }
      } catch (error) {
        console.error('Error fetching SaaS list:', error);
      
      }
    };

    fetchSaasList();
  }, []);
  return (
    <Box
    style={{height:'calc(100vh - 150px)' , overflowY:"auto"}}
      sx={{
        maxWidth: 800,
        margin: "auto",
        padding: 3,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Add Store
      </Typography>
      <form  onSubmit={handleSubmit}>
        <Grid container spacing={2}>
        
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Store ID"
              name="store_id"
              value={formData.store_id}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth required>
              <InputLabel id="saasId-label">SaaS ID</InputLabel>
              <Select
                labelId="saasId-label"
                name="saas_id"
              value={formData.saas_id}
              onChange={handleChange}
                label="SaaS ID"
              >
                {saasList.map((saas) => (
                  <MenuItem key={saas.saasId} value={saas.saasId}>
                    {saas.saasName} ({saas.saasId})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={4}>
            <TextField
              fullWidth
              label="Saas ID"
              name="saas_id"
              value={formData.saas_id}
              onChange={handleChange}
              required
            />
          </Grid> */}
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Store Name"
              name="store_name"
              value={formData.store_name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="City"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="State"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="GST Code"
              name="gst_code"
              value={formData.gst_code}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              type="email"
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phone_no"
              value={formData.phone_no}
              onChange={handleChange}
              required
            />
          </Grid>
             {/* File Input for Store Logo */}
             <Grid item xs={4}>
            <TextField
              fullWidth
              type="file"
              inputProps={{ accept: "image/*" }}
              label="Store Logo"
              onChange={handleLogoChange}
              InputLabelProps={{
                shrink: true,  // Moves label to top when file is selected or input is focused
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type="file"
              inputProps={{ accept: "image/*" }}
              label="Payment QR Code"
              onChange={handleQrCodeChange}
              InputLabelProps={{
                shrink: true,  // Moves label to top when file is selected or input is focused
              }}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <TextField
              fullWidth
              label="Cuisines"
              name="cuisines"
              value={formData.cuisines}
              onChange={handleChange}
              required
            />
          </Grid> */}
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Hsn Code"
              name="hsn_code"
              value={formData.hsn_code}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="TNC"
              name="tnc"
              value={formData.tnc}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Delivery Charges"
              name="delivery_charges"
              value={formData.delivery_charges}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Minimum Order Value"
              name="minimum_order_value"
              value={formData.minimum_order_value}
              onChange={handleChange}
              required
            />
          </Grid>
          {/* <Grid item xs={4}>
            <TextField
              fullWidth
              label="Bank Account"
              name="bank_account"
              value={formData.bank_account}
              onChange={handleChange}
              required
            />
          </Grid> */}
          {/* <Grid item xs={4}>
            <TextField
              fullWidth
              label="IFSC Code"
              name="bank_ifsc"
              value={formData.bank_ifsc}
              onChange={handleChange}
              required
            />
          </Grid> */}
          {/* <Grid item xs={4}>
            <TextField
              fullWidth
              label="Bank Branch"
              name="bank_branch"
              value={formData.bank_branch}
              onChange={handleChange}
              required
            />
          </Grid> */}
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Header line1"
              name="header_line1"
              value={formData.header_line1}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Header line2"
              name="header_line2"
              value={formData.header_line2}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Footer line1"
              name="footer_line1"
              value={formData.footer_line1}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Footer line2"
              name="footer_line2"
              value={formData.footer_line2}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Store Address1"
              name="store_address1"
              value={formData.store_address1}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Store Address2"
              name="store_address2"
              value={formData.store_address2}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default AddStore;
