import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BASE_Url } from "../URL.js/URL";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Storeuser = () => {
  const [allData, setAllData] = useState([]);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const GetStorUser = async () => {
    try {
      const response = await axios.get(
        `${BASE_Url}/user-master/get-users/${saasId}/${storeId}`
      );
      setAllData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetStorUser();
  }, []);
  const handlePermissionClick = (user) => {
    setSelectedUser(user); // Set the selected user in state
    navigate(`/PermissionManagement/${user.userId}`, { state: { user } }); // Navigate with state
  };
  return (
    <div>
      <TableContainer
        component={Paper}
        style={{
          maxHeight: "calc(100vh - 180px)",
          overflowY: "auto",
        }}
      >
        <Table>
          <TableHead className="bg-dark-purple">
            <TableRow>
              {/* <TableCell className=" text-white">Quantity</TableCell> */}
              <TableCell className=" text-white">Name</TableCell>
              <TableCell className=" text-white">Role</TableCell>
              <TableCell className=" text-white">SaasId</TableCell>
              {/* <TableCell className=" text-white">Order Count</TableCell> */}
              {/* <TableCell className=" text-white">Total Sale</TableCell> */}
              <TableCell className=" text-white">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allData.map((row, index) => (
              <TableRow key={index}>
                {/* <TableCell>{row.qty}</TableCell> */}
                <TableCell>{row.userName}</TableCell>
                <TableCell>{row.userType}</TableCell>
                <TableCell>{row.saasId}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    className="text-white bg-dark-purple fw-bold mx-2"
                    style={{ textTransform: "none" }}
                    onClick={() => handlePermissionClick(row)}
                  >
                    Permission
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Storeuser;
