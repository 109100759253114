import React, { useEffect, useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { BsArrowLeft } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import DailyStore_reports from "./DailyStore_reports";
import Reports from "./Reports";
import ItemReport from "./ItemReport";
import OrderReport from "./OrderReport";
import Staffperformance_reports from "./Staffperformance_reports";
import Inventory_reports from "./Inventory_reports";
import CRM_reports from "./CRM_reports";
import Allinvoiced from "./Allinvoiced";
import { styled } from '@mui/material/styles';
import { 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  Box, 
  Paper 
} from '@mui/material';
import CustomerList from "./CustomerList";
import Npsdashbooard from "./Npsdashbooard";
import PaymentReport from "./PaymentReport";
import CategoryReport from "./CategoryReport";
import DueAmountReport from "./DueAmountReport";
import KotReport from "./KotReport";
import BahikhataReport from "./BahikhataReport";
import ExpeseReport from "./ExpeseReport";
import ItemDetailReport from "./ItemDetailReport";
import PendingForSettle from "./PendingForSettle";
import NetSalesReport from "./NetSalesReport";
import CashProfitLoss from "./CashProfitLoss";
import CategorySummaryReport from "./CategorySummaryReport";
import { BASE_Url } from "../../URL.js/URL";
import axios from "axios";
import { Tab, Tabs } from "react-bootstrap";
import DailySalesbySaasId from "./DailySalesbySaasId";
import CategoryReportsbySaasId from "./CategoryReportsbySaasId";
import ItemReportbySaasId from "./ItemReportbySaasId";
import HourlySales from "./HourlySales";
const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  position: 'relative', // Ensure the ::before pseudo-element is positioned relative to the list item
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&::before': {
    display: selected ? 'block' : 'none',
    content: '""',
    width: 4,
    height: '100%',
    backgroundColor: selected ? theme.palette.success.main : theme.palette.success.light,
    position: 'absolute', // Position the line absolutely
    left: 0, // Align it to the left side
  },
  backgroundColor: selected ? theme.palette.action.selected : 'transparent',
}));
const Reports_names = () => {
  const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("StoreLevel");
  const [selectedOption, setSelectedOption] = useState("total");
  const [formDate, setFormDate] = useState(new Date().toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleFormDateChange = (event) => {
    setFormDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };
  //make a condition to check user_type is Admin then return true else false
  const jsonString = localStorage.getItem("USER_DATA");
  const parsedData = JSON.parse(jsonString || '{}'); // Fallback to an empty object if null
  const { userType } = parsedData;
  const isAdmin =  userType =="ADMIN"; 

  const reportNames = [
    { name: "All Sales Invoiced", value: "total" ,isActive: true },
    { name: "Daily Sales Reports", value: "daily" ,isActive: isAdmin},
    { name: "Order Related Reports", value: "order" ,isActive: isAdmin},
    { name: "Not Settled Order", value: "PendingOrder" ,isActive: true},
    { name: "Item Reports (Top Selling)", value: "item" ,isActive: isAdmin},
    { name: "Item Reports", value: "ItemDetail" ,isActive: true},
    { name: "Net Sales Report", value: "NetSales" ,isActive: isAdmin},
    { name: "Profit And Loss", value: "profitLose" ,isActive: isAdmin},
    { name: "Hourly Sales", value: "HourlySales" ,isActive: isAdmin},
    { name: "Table Performance Reports", value: "table" ,isActive: isAdmin},
    { name: "Payment Reports", value: "Payment" ,isActive: isAdmin},
    { name: "Due Amount Reports", value: "DueAmount" ,isActive: isAdmin},
    { name: "KOT Reports", value: "KOTReport" ,isActive: isAdmin},
    { name: "Category Reports", value: "Category" ,isActive: true},
    { name: "Category Summary Reports", value: "CategorySummary" ,isActive: true},

    { name: "Staff Performance Reports", value: "staff" ,isActive: isAdmin},
    { name: "Inventory Reports", value: "inventory" ,isActive: isAdmin},
    { name: "Customer List", value: "customer" ,isActive: isAdmin},
    { name: "NPS Dashboard", value: "nps" ,isActive: isAdmin},
    // { name: "Expense", value: "Expense" ,isActive: isAdmin},
    // { name: "Bahikhata", value: "Bahikhata" ,isActive: isAdmin},
    // { name: "CRM Reports", value: "crm" }
  ]

  const SaasreportNames = [
    { name: "Daily Sales Reports", value: "DailySales" ,isActive: isAdmin},
    { name: "Item Reports", value: "ItemReportbysaas" ,isActive: isAdmin},
    { name: "Category Reports", value: "CategoryReports" ,isActive: isAdmin},


  ]

  // const reports = [
  //   'Sales Report',
  //   'Todays Report',
  //   'Items Report',
  //   'Payment Report',
  //   'Order Report',
  //   'Category-wise Report',
  //   'All Sales Invoiced'
  // ];

  //Create Function to hide todate input on selected option
  const hideToDate = (option) => {
    if(option === "HourlySales" || option === "ItemReportbysaas" || option === "CategorySummary" || option === "total" || option === "order"|| option === "customer" || option === "nps" || option ==='Category'|| option==='ItemDetail' ){
      return false;
    }
    return true;
  }
  
  const hidebothdates = (option) => {
    if(  option === "nps"|| option === "customer" || option === "inventory" || option ==="PendingOrder" ){
      return false;
    }
    return true;
  }

 const { storeId, saasId } = JSON.parse(localStorage.getItem("USER_DATA"));
  const [loading, setLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const fetchLabels = async () => {
    try {
      const response = await axios.get(
        `${BASE_Url}/lables/get-All/${saasId}/${storeId}`
      );
      if (response.data.status) {
        // Save the labels in the state
        setLabels(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching labels:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("firstlabels",labels)
    fetchLabels();
  }, []);
  const getLocalizedTitle = (menuTitle) => {
    const label = labels.find((label) => label.baseDesc === menuTitle);
    return label ? label.localDesc : null;
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', bgcolor: 'grey.100' }}>
   <Paper 
  elevation={3} 
  sx={{ 
    width: 280, 
    maxHeight: 'calc(100vh - 110px)', // Adjust to your desired max height based on viewport height
    maxWidth: 'calc(100vh - 400px)',  // Ensure the form's width doesn't exceed the available space
    overflowY: 'auto', 
  }}
><Tabs
        defaultActiveKey="StoreLevel"
        id="uncontrolled-tab-example"
        className="mb-3  fw-bold"
        fill
        style={{
          background: "#FFF",
          boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        }}
        onSelect={(key) => {
          setActiveTab(key);
        }}
      >
        <Tab eventKey="StoreLevel" title="StoreLevel">
  <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
    <Typography variant="h6" component="h2">
      Reports
    </Typography>
  </Box>
  <List disablePadding>
    {reportNames.filter((item) => item.isActive).map((report) => (
      <>
      
      <StyledListItem
        key={report.value}
        selected={selectedOption === report.value}
        onClick={() => setSelectedOption(report.value)}
        button
        >
        <ListItemText primary={report.name} />
        {getLocalizedTitle(report.name) && (
                      <span className="text-sm text-gray-400 ml-2">
                        ({getLocalizedTitle(report.name)})
                      </span>
                    )}
      </StyledListItem>
        </>
    ))}
  </List>
 </Tab>
 {isAdmin &&<Tab eventKey="SaasLevel" title="SaasLevel">
 <List disablePadding>
    {SaasreportNames.filter((item) => item.isActive).map((report) => (
      <>
      
      <StyledListItem
        key={report.value}
        selected={selectedOption === report.value}
        onClick={() => setSelectedOption(report.value)}
        button
        >
        <ListItemText primary={report.name} />
        {getLocalizedTitle(report.name) && (
                      <span className="text-sm text-gray-400 ml-2">
                        ({getLocalizedTitle(report.name)})
                      </span>
                    )}
      </StyledListItem>
        </>
    ))}
  </List>
        </Tab>}
      </Tabs>
</Paper>


            <Box className="gap-3" sx={{ flexGrow: 1, p: 3 }}>
                <>
              {hidebothdates(selectedOption) &&<Box className="gap-2 flex ">
            <Grid item xs={12} sm={3}>
              <Form.Group controlId="formDate">
                <Form.Control
                  type="date"
                  className="font-bold form-control"
                  style={{ width: "100%" }}
                  onChange={handleFormDateChange}
                  value={formDate}
                />
              </Form.Group>
            </Grid>
           {hideToDate(selectedOption) && <Grid item xs={12} sm={3}>
              <Form.Group controlId="toDate">
                <Form.Control
                  type="date"
                  className="font-bold form-control"
                  style={{ width: "100%" }}
                  onChange={handleToDateChange}
                  value={toDate}
                />
              </Form.Group>
            </Grid>}
              </Box>}
          </>
        
            {selectedOption === "total" && <Allinvoiced formDate={formDate} toDate={toDate}/>}
            {selectedOption === "daily" && <DailyStore_reports formDate={formDate} toDate={toDate} />}
            {selectedOption === "table" && <Reports formDate={formDate} toDate={toDate} />}
            {selectedOption === "item" && <ItemReport formDate={formDate} toDate={toDate} />}
            {selectedOption === "PendingOrder" && <PendingForSettle />}
            {selectedOption === "ItemDetail" && <ItemDetailReport StartDate={formDate}  />}
            {selectedOption === "NetSales" && <NetSalesReport  start={formDate} todate={toDate} />}
            {selectedOption === "order" && <OrderReport formDate={formDate} toDate={toDate} />}
            {selectedOption === "staff" && <Staffperformance_reports formDate={formDate} toDate={toDate} />}
            {selectedOption === "inventory" && <Inventory_reports formDate={formDate} toDate={toDate} />}
            {selectedOption === "customer" && <CustomerList formDate={formDate} toDate={toDate} />}
            {selectedOption === "nps" && <Npsdashbooard />}
            {selectedOption === "Payment" && <PaymentReport  startdate={formDate} endDate={toDate} />}
            {selectedOption === "Category" && <CategoryReport  Startdate={formDate} toDate={toDate} />}
            {selectedOption === "CategorySummary" && <CategorySummaryReport  Startdate={formDate} toDate={toDate} />}

            {selectedOption === "DueAmount" && <DueAmountReport  Startdate={formDate} toDate={toDate} />}
            {selectedOption === "KOTReport" && <KotReport  start={formDate} todate={toDate} />}
            {selectedOption === "profitLose" && <CashProfitLoss  start={formDate} todate={toDate} />}

            {selectedOption === "DailySales" && <DailySalesbySaasId  formDate={formDate} toDate={toDate} />}
            {selectedOption === "CategoryReports" && <CategoryReportsbySaasId   Startdate={formDate} toDate={toDate} />}
            {selectedOption === "ItemReportbysaas" && <ItemReportbySaasId   formDate={formDate} toDate={toDate} />}
            {selectedOption === "HourlySales" && <HourlySales   Startdate={formDate}  />}

        
            </Box>
          {/* </div> */}
</Box>
  
  );
};

export default Reports_names;
