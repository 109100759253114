import moment from "moment";
import React, { useEffect } from "react";
import { PrintProvider, Print, NoPrint } from "react-easy-print";

function ReceiptModal({DeliveryCharges,totalDiscount,customer,products,optionTick, orderType, payment_mode,invoiceNo,tableNo,orderaddress,tablecategory}) {
  // Static data to replace Redux store data
  console.log("customer", customer)
  useEffect(() => {
  console.log("optionTick",optionTick)
  }, [optionTick])

  //total tax from products array
  const totalTax = products.reduce(
    (sum, item) => sum + item.tax ,
    0
  );
  
  const { storeName,userId,userName } = JSON.parse(localStorage.getItem("USER_DATA"));
  
  console.log(products)
  const totalamount = products.reduce((sum, item) => 
    sum + (Number(item.item_price) * Number(item.item_qty)), 0) + DeliveryCharges;
  const totalitemDis = products.reduce(
    (sum, item) => sum + item.discount * item.item_qty,
    0
  );
  const TotalGross = parseInt(totalamount) + parseInt(totalitemDis);
  const totalProductQty = products.reduce(
    (total, item) => total + parseInt(item.item_qty),
    0
  );
  const {
    store_logo,
    exclusiveTax,
    saasId,
    haderLine1,
    haderLine2,
    haderLine3,
    haderLine4,
    futterLine1,
    futterLine2,
    futterLine3,
    futterLine4,
    futterLine5,
    futterLine6,
    futterLine7,
    futterLine8,
    futterLine9,
    futterLine10,
    address,
    paymentQRCode,
  } = JSON.parse(localStorage.getItem("STORE_DATA"));
  

  const styles = {
    fontFamily: "'Roboto Mono', monospace",
    fontSize: "medium",
    fontWeight: "bold",
  };

  const customLineStyle = {
    borderBottom: "3px dashed black",
    width: "100%",
    display: "inline-block",
  };
  const table = {
    border: "1px solid black",
    margin: "8px 0",
  };
  let lastCategory = null;
  const receiptContent = (
    <div className="px-3" style={styles}>
      {/* <NoPrint>
      <div className="flex justify-center items-center mt-2">
      <img src={store_logo} width={200} height={200} alt="Logo not Print"/>
      </div>
      </NoPrint> */}
      <p
        style={{ fontSize: "1.6rem" }}
        className="d-flex  justify-content-center fw-bold m-0"
      >
        {storeName}
      </p>
      {store_logo &&<div className="flex justify-center items-center mt-2">
        <img src={store_logo} width={100} height={100} alt="Store Logo" />
      </div>
}

      {/* <div className="flex justify-center items-center">
      <span
        style={{fontSize: "14px"}}
        className="text-center m-0 w-80"
      >
        {address}
      </span>

      </div> */}
      {haderLine1&&<p
        style={{ fontSize: "1.4rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {haderLine1}
      </p>}
      {haderLine2&&<p
        style={{ fontSize: "1.25rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {" "}
        {haderLine2}
      </p>}
      {haderLine3&&<p
        style={{ fontSize: "1.25rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {haderLine3}
      </p>}
      {haderLine4&&<p
        style={{ fontSize: "1.25rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {haderLine4}
      </p>}
         
    
      {/* <div className="container mt-3" style={{ fontSize: "1.2rem" }}>
        {customer?.name && (
          <div className="col">
            <div className="col  fw-bold">
              <p className="fw-bold">Customer Name: {customer?.name}</p>
            </div>
            <div className="col  fw-bold">
              <p className="fw-bold">
                Customer Mobile Number: {customer?.mobile_number}
              </p>
            </div>
          </div>
        )}

      </div> */}
      {customer?.mobile_number && (
        <>
        <div className="fw-bold " style={customLineStyle}></div>
        <div className="d-flex justify-content-between fw-bold text-nowrap m-0">
          {/* <p> Name: {customer?.name}</p> */}
          <p style={{fontSize: "14px"}} className="m-0"> Customer Number: {customer?.mobile_number}</p>

        </div>
        </>
      )}
      <div className="fw-bold" style={customLineStyle}></div>
      <div className="d-flex justify-content-between fw-bold text-nowrap">
          <p style={{fontSize: "14px"}}>Date: {moment(Date.now()).format("DD-MMM-YYYY h:mm:ss a")}</p>
          <p style={{fontSize: "14px"}}>{orderType}: {tableNo} {tablecategory &&`(${tablecategory})`}</p>

        </div>
        <div className={`d-flex justify-content-${saasId !=="12" ?"between":"center" } fw-bold text-nowrap`}>
      {saasId !=="12"&& <p style={{fontSize: "14px"}} className="m-0">Cashier: {userName}</p>}
      <p  style={{fontSize: "14px"}} className="d-flex justify-center m-0">{saasId !=="12" ?"Bill No:":"" } {invoiceNo}</p>

        </div>
      <div className="fw-bold" style={customLineStyle}></div>

      <table className="w-100  mx-md-auto" style={{ fontSize: "1.2rem" }}>
        <thead>
          <tr className="border-dark" style={{ borderBottomWidth: "2px" }}>
            <th style={{fontSize: "14px"}}>Item</th>
            <th style={{fontSize: "14px"}} className="text-center">Qty</th>
            <th style={{fontSize: "14px"}} className="text-center">Rate</th>
            <th style={{fontSize: "14px"}} className="text-center">Value</th>
          </tr>
        </thead>
        <tbody>
          {products &&
            products.map((el) => {
              const showCategory = el?.category !== lastCategory;
           if (showCategory) {
             lastCategory = el?.category; // Update the last displayed category
           }
              return (
                <>
                  {" "}
                  {el.item_qty > 0 && (
                    <>
                      <tr >
                        <td  style={{fontSize: "14px"}} >
                        {showCategory && <span>{el?.category}<br /></span>}
                          -{el?.item_name}
                        </td>
                        <td className="px-2 text-center" style={{fontSize: "14px"}}>
                          {el.item_qty}
                        </td>
                        <td className="px-2 text-center" style={{fontSize: "14px"}}>{el.item_price}</td>
                        <td className="px-2 text-center" style={{fontSize: "14px"}} >
                          {el.item_price * el.item_qty}
                        </td>
                      </tr>
                      {/* <tr className="fw-bold">
                        <td
                          colSpan={el.discount === 0 ? "4" : "2"}
                          className="m-0"
                          style={{ fontSize: "1rem" }}
                        >
                          {el.item_name.slice(0, 25)}
                        </td>
                        {el.discount > 0 && (
                          <>
                            <td>
                              Disc {el?.Discountper && el.Discountper}
                              {el?.Discountper && "%"}
                            </td>
                            <td>{el.discount * el.productQty}</td>{" "}
                          </>
                        )}
                      </tr> */}
                    </>
                  )}
                </>
              );
            })}
        </tbody>
      </table>

      <div className="fw-bold" style={customLineStyle}></div>

      <div className="container" style={{ fontSize: "1.25rem" }}>
        <div className="d-flex justify-content-between fw-bold text-nowrap">
          <p style={{fontSize: "14px"}} className="m-0">Qty : {totalProductQty}</p>
          {/* <p className="m-0"></p> */}
          <div>
          {DeliveryCharges >0 &&<p style={{fontSize: "14px"}} className="m-0">Deliver Charges : {DeliveryCharges}</p>}
          <p style={{fontSize: "18px"}} className="m-0">Grand Total : {totalamount}</p>
          </div>
          {/* <p className="m-0"></p> */}

        </div>
        
        {totalDiscount > 0&&<>
           <div className="d-flex justify-content-between fw-bold text-nowrap">
          <p></p>
          <p></p>
          <p style={{fontSize: "14px"}} className="m-0">Discount : {totalDiscount}</p>
          {/* <p style={{fontSize: "14px"}}></p> */}
        </div>
        <div className="flex justify-end">
          <p style={{fontSize: "14px"}} className="m-0">Net Total : {totalamount - totalDiscount}</p>
        </div>
        </>
        
        }
        {orderType=="Online" &&
         <div className="container" style={{ fontSize: "1.25rem" }}>
      <div className="d-flex justify-content-between fw-bold text-nowrap">
          <p style={{fontSize: "14px"}}>Payment Method</p>
          <p style={{fontSize: "14px"}}>{payment_mode}</p>
        </div>
        </div>}
        {totalTax>0 && <div className="flex justify-end fw-bold text-nowrap">
          
   
          <p style={{fontSize: "14px"}} className="m-0">Total Tax({exclusiveTax>0? "Exclusive":"Inclusive"}): {totalTax}</p>
     

        </div>}
      </div>
      
      
        {/* <NoPrint>
     
      <div className="fw-bold mt-2" style={customLineStyle}></div>
      <div className="container mb-1 mt-1" style={{ fontSize: "1.2rem" }}>
        {optionTick &&
          optionTick.map((item) => {
            return (
              <div className="row fw-bold" key={item.option}>
                  <div className="col text-nowrap">
                    <p>
                      {item.name}
                    </p>
                  </div>
                  <div className="col text-nowrap">
                    <p>{item.option}</p>
                  </div>
                <div className="col">
                  <p className="float-end">  {item.amount}</p>
                </div>
              </div>
            );
          })}
      </div>
      </NoPrint> */}
      <div className="fw-bold" style={customLineStyle}></div>

      {customer?.address_1&&
      <div className="container" style={{ fontSize: "1.25rem" }}>
      <div className="flex flex-col items-center justify-center fw-bold text-wrap mx-2">
        <p style={{fontSize: "14px"}} className="m-0">Delivery Address</p>
        <p style={{fontSize: "14px"}} className="m-0">{customer?.address_1}</p>
      </div>
      </div>

      }

      {orderaddress &&
         <div className="container" style={{ fontSize: "1.25rem" }}>
        <div className="flex flex-col items-center justify-center fw-bold text-wrap mx-2">
          <p style={{fontSize: "14px"}} className="m-0">Delivery Address</p>
          <p style={{fontSize: "14px"}} className="m-0">{orderaddress}</p>
        </div>
        </div>}
        
      
      <div className="container py-2 fw-bold" style={{ fontSize: "1.1rem" }}>
        <div className="row">
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine1}{" "}
          </p>
          {futterLine2?.lenght>0&&<p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine2}
          </p>}
          {futterLine3?.lenght>0&&<p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine3}
          </p>}
          {futterLine4?.lenght>0&&<p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine4}{" "}
          </p>}
          {futterLine5?.lenght>0&&<p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine5}
          </p>}
          {futterLine6?.lenght>0&&<p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine6}
          </p>}
          {futterLine7?.lenght>0&&<p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine7}
          </p>}
          {futterLine8?.lenght>0&&<p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine8}
          </p>}
          {futterLine9?.lenght>0&&<p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine9}
          </p>}
          {futterLine10?.lenght>0&&<p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine10}
          </p>}
        </div>
      </div>
      {paymentQRCode &&<div className="flex items-center justify-center">
        <img src={paymentQRCode} alt="invoiceLogo" style={{ width: "200px" }} />
      </div>}
      {/* <hr className="mb-5" style={{ color: "black" }} /> */}

      {/* {newCredit?.creditNote_id && (
        <div>
          <div
            className="container "
            style={{ fontSize: "1.25rem", marginTop: "5rem" }}
          >
            <p
              style={{ fontSize: "1.6rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {storeName}
            </p>
            <p
              style={{ fontSize: "1.4rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine1}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine2}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine3}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine4}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              REISSUED CREDIT NOTE
            </p>
            <div className="row  fw-bold">
              <div className="col">
                <div className="d-flex justify-content-center">
                
                </div>
              </div>

              <div className="fw-bold">********************************</div>
            </div>
            <div className="d-flex justify-content-between">
              <p>Amount</p>
              <span>{newCredit.credit_note_amount}</span>
            </div>
          </div>
          <div className="container" style={{ fontSize: "1.2rem" }}>
            <div className="col">
              <div className="col p-2 fw-bold">
                <p className="fw-bold">Authorised by : </p>
                <p className="fw-bold">Accounts : </p>
              </div>
            </div>
          </div>

          <div className="container" style={{ fontSize: "1.2rem" }}>
            <div className="col">
              <div className="col p-2 fw-bold">
                <p className="fw-bold">Not valid after 180 Days</p>

                <p className="fw-bold">
                  Valid upto: {moment(newCredit.EXP_DATE).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );

  return (
    <PrintProvider>
      <Print>
        <div style={styles}>{receiptContent}</div>
      </Print>
    </PrintProvider>
  );
}

export default ReceiptModal;
