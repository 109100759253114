import { Button, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { BASE_Url } from '../../URL.js/URL';

const FlatDiscount = ({handleClose,fetchDiscountData}) => {
   
    const [formData, setFormData] = useState({
        storeId: '',
        saasId: '',
        discount: '',
        startdate: '',
        enddate: ''
    });
    useEffect(() => {
        const { storeId, saasId } = JSON.parse(
            localStorage.getItem("STORE_DATA"))
        setFormData((prevFormData) => ({
            ...prevFormData,
            storeId: storeId || '',
            saasId: saasId || ''
        }));
    }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'discount' ? Number(value) : value
        });
    };

    const handleSubmit =async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${BASE_Url}/item/add-flat-discount`,formData)
            if(res.data.status){
                fetchDiscountData()
                handleClose()
                Swal.fire({
                    title:"Discount Created",
                    icon:"success",
                    timer:2000
                })
            }else{
                Swal.fire({
                    title:"Something wrong",
                    icon:'error',
                    timer:2000

                })
            }
            
        } catch (error) {
            Swal.fire({
                title:"Please Try Again",
                icon:"error",
            })
            console.log(error)
        }
        // Add your form submission logic here
    };

    return (
        <div className='flex justify-center'>

        <form  onSubmit={handleSubmit}>
            {/* <div>
                <TextField
                label="Store ID"
                variant="outlined"
                name="storeId"
                    value={formData.storeId}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
            </div>
            <div>
                <TextField
                    label="SaaS ID"
                    variant="outlined"
                    name="sassId"
                    value={formData.sassId}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
            </div> */}
            <div>
                <TextField
                    label="Discount"
                    variant="outlined"
                    type="number"
                    name="discount"
                    value={formData.discount}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
            </div>
            <div>
                <TextField
                    label="Start Date"
                    variant="outlined"
                    type="date"
                    name="startdate"
                    value={formData.startdate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <div>
                <TextField
                    label="End Date"
                    variant="outlined"
                    type="date"
                    name="enddate"
                    value={formData.enddate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <Button type="submit" className='mx-2' variant="contained" color="primary">
                Add Flat Discount
            </Button>
            <Button type="button" onClick={handleClose} variant="contained" color="primary">
                Close
            </Button>
        </form>
                </div>
    );
};

export default FlatDiscount;