import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import axios from "axios";
import { list } from "@material-tailwind/react";
import { Edit } from "@mui/icons-material";


const TableComponent = ({state, title, addButton, list,addRole, loading, addUrl, editUrl, headers,

}) => {
  useEffect(() => {
    
    console.log("list", state)
    
    
  }, [state])
  
 
  const navigate = useNavigate()
  return (
    <div>
      <Button className="text-black text-9xl " sx={{ display: "block", mt: 2 }}>
        {title}
      </Button>
      <FormControl sx={{ m: 1, width: "800px" }}>
     
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {headers?.map(el => (
                <th scope="col" className="px-6 py-3">
                  {el.headerName}
                </th>
              ))}

            </tr>
          </thead>
          <tbody>
            {
              loading ? <div>Loading...</div> : list?.map(el => (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {el?.staff_id||el?.role_id}
                  </th>
                  <td className="px-6 py-4">{el?.staff_name || el?.role_name}</td>
                  <td className="px-6 py-4">{el?.gender||el?.security_id}</td>
                  <td className="px-6 py-4">{el?.date_of_joining||el?.function_name}</td>
                  <td className="px-6 py-4">{el?.salary||el?.allowed_or_not}</td>
                  {state==="User Master"?  <td className="px-6 py-4">{el?.incentive_percentage}</td>
               : <td className="px-6 py-4">
               <a
                 onClick={() => navigate(`${addRole}?roleId=${el?.role_id}&saasId=${el?.saas_id}&&storeId=${el?.store_id}`)}

                 className=" cursor-pointer text-decoratione-none font-medium text-blue-600 dark:text-blue-500 hover:underline"
               >
                 Edit

               </a>
             </td>}
             {state==="User Master" && <td className="px-6 py-4">
                    <a
                      onClick={() => navigate(`${editUrl}?staffId=${el?.staff_id}&saasId=${el?.saas_id}&&storeId=${el?.store_id}`)}

                      className="mx-2 font-medium text-decoratione-none cursor-pointer text-blue-600 dark:text-blue-500 hover:underline"
                    >
                         <Edit style={{ color: 'green' }} />

                    </a>
                    {/* <Button
  variant="contained"
  size="small"
  className="text-white bg-dark-purple fw-bold mx-2"
  style={{ textTransform: "none" }}
  onClick={() => navigate(`/PermissionManagement/${el?.staff_id}`)}
>
   Permission
</Button> */}
                  </td>}
                </tr>

              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
