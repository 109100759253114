import React, { useState } from 'react';
import { Button } from '@mui/material';
import { PasswordUpdateModal } from './PasswordUpdateModal';
const UpdatePassword = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
    <Button color="secondary" onClick={() => setIsModalOpen(true)}>
            Update Password
    </Button>
    <PasswordUpdateModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}

export default UpdatePassword