import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_Url } from '../../../URL.js/URL';

const CreateUom = ({ isOpen, toggle, fetchgetUomList }) => {
  const [uomName, setUomName] = useState('');
  const [loading, setLoading] = useState(false);
  const { saasId } = JSON.parse(localStorage.getItem("STORE_DATA")); // Retrieve saasId from localStorage

  const handleUomSave = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
      `${BASE_Url}/uom/add`,
        { unit: uomName, saasId },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data.status) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'UOM added successfully!',
        });
        fetchgetUomList(); // Refresh the list of UOMs
        toggle(); // Close the modal
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message || 'Failed to add UOM.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'An error occurred. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="fw-bold">
        Add UOM
      </ModalHeader>
      <ModalBody>
        <TextField
          label="UOM Name"
          value={uomName}
          onChange={(e) => setUomName(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary"
          onClick={toggle}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            width: 225,
            height: 45,
          }}
          color="primary"
          onClick={handleUomSave}
          disabled={loading}
        >
          {loading ? 'Adding...' : 'Add UOM'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateUom;
