import React, { useState } from 'react'
import { Delete, Edit } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import DataTable from 'react-data-table-component';
import AddStore from './AddStore';
import AddStoreModal from './AddStoreModal';
import { BASE_Url } from '../../URL.js/URL';
import axios from 'axios';
import { useEffect } from 'react';
import UpdateStore from './UpdateStore';

const GetAllStores = () => {
const [store, setStore] = useState([]);

const storeData = JSON.parse(localStorage.getItem('STORE_DATA'));
const { saasId, storeId } = storeData;

//create state for open modal 
const [open, setOpen] = useState(false)
const [page, setpage] = useState(1)
const [limit , setLimit] = useState(10)
const [totalRows, settotalRows]= useState(0)

const [show, setShow] = useState(false)
const [selecdData, setSelecedData] = useState({})

const handleEditClick = (data)=>{
  try {
    setSelecedData(data)
    setShow(true)
  } catch (error) {
    console.log(error)
  }
}

//handle open modal
const handleOpen = () => {
    setOpen(true)
}
  
 const handleDelete = async (saas,store)=>{
  try {
    const res = await axios.delete(`${BASE_Url}/storeMaster/delete-store/${saas}/${store}`)
  } catch (error) {
    console.log(error)
  }
 }
  
  const handlePageChange = (page) => {
    setpage(page);
  };
 const getAllStores = async(page,limit)=>{
  try {
    const res= await axios.get(`${BASE_Url}/storeMaster/get-all-store/${page}/${limit}`)
    if(res.data.status){
      setStore(res.data.data.content)
      console.log(res.data)
      settotalRows(res.data.data.totalElements)
    }
  } catch (error) {
    console.log(error)
  }
 }
 
 useEffect(() => {
  getAllStores(page,limit)
 }, [page,limit])
 
  
const columns = [
    {
      name: 'Store Id',
      selector: (row) => row.storeId,
      sortable: true,
    },
    {
      name: 'SaaS Id',
      selector: (row) => row.saasId,
      sortable: true,
    },

    {
      name: 'Store Name',
      selector: (row) => row.storeName,
      sortable: true,
    },
    {
      name: 'Address',
      selector: (row) => `${row.address}`,
      sortable: false,
    },
    // {
    //   name: 'Store Logo',
    //   selector: (row) => `${row.store_logo}`,
    //   sortable: false,
    //   cell: (row) => (
    //     <img src={row.store_logo} alt={row.store_name} style={{ width: '50px', height: '50px' }} />
    //     ),
    // },
    {
      name: 'Actions',
      selector: (row) => row.actions,
      center: true,
      cell: (row) => (
        <>
          <IconButton aria-label="edit" 
           onClick={() => handleEditClick(row)}
           >
            <Edit style={{ color: 'green' }} />
          </IconButton>
          {/* <IconButton aria-label="delete" 
          onClick={() => handleDelete(row.saas_id,row.store_id)}
          >
            <Delete style={{ color: '#C81D20' }} />
          </IconButton> */}
        </>
      ),
    },
  ];

  return (
    <>
    <div>
       <div className="flex justify-between items-center px-3">
            <h1 className="text-2xl">Create Store</h1>
            <Button
              className="fw-bold bg-dark-purple text-white"
              variant="contained"
              style={{ background: 'rgb(14, 41, 84)' }}
                onClick={handleOpen}
            >
              Create Store
            </Button>
          </div>
          <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl mb-4 fw-bold">Stores Directory</h2>
          <div style={{
          maxHeight: "calc(100vh - 250px)",
          overflowY: "auto",
        }}>
            <DataTable
              columns={columns}
              data={store}
              pagination
              responsive
              striped
              highlightOnHover
              pointerOnHover
              noHeader
              paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
              onChangeRowsPerPage={(newLimit) => setLimit(newLimit)}
              paginationRowsPerPageOptions={[5,10, 20, 30, 40, 50]}
              />
          </div>
        </div>
    </div>
    <UpdateStore show={show} handleClose={()=>setShow(false)} selecdData={selecdData}/>
    <AddStoreModal show={open} handleClose={() => setOpen(false)} handleSave={() => setOpen(false)} />
              </>
  )
}

export default GetAllStores