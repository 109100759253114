import React from "react";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import chola from "../../images/chola.jpg";
import Navbar from "../Opt/Navbar";
import Product from "../Opt/Product";
import Cart from "../Opt/Cart";
import { AiOutlineClose, AiOutlineProfile, AiOutlineShoppingCart } from "react-icons/ai";
import { BsBucketFill } from "react-icons/bs";
import { IoReceiptSharp } from "react-icons/io5";
import { BASE_Url } from "../../URL.js/URL";
import { RxCross2 } from "react-icons/rx";
import { FiMenu } from "react-icons/fi";
import { Badge, BottomNavigation, BottomNavigationAction ,Box, Modal } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { getFilePlugin } from '@react-pdf-viewer/get-file';

// Your render function

import PreviewIcon from '@mui/icons-material/Preview';
import {
  Search as SearchIcon,
  Menu as MenuIcon,
  ShoppingCart as CartIcon,
  Receipt as OrdersIcon,
  ArrowLeft,
} from '@mui/icons-material'
import Swal from "sweetalert2";
import Header from "../Opt/Header";
import { FaArrowLeft } from "react-icons/fa";
import LoginModal from "../Opt/LoginModal";
function Beverages() {
  const params = useParams();
  const { table_id, id, saas_id, store_id } = params;
  const [menu, setMenu] = useState("");
  const [open, setOpen] = useState("");
  const [category, setCategory] = useState("");
  const [updatecart, setUpdateCart] = useState(true);
  const [cartData, setCartData] = useState(null);
  const [count, setCount] = useState(0);
  const [catgoryName, setCatgorynaem] = useState();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [PdfFile, setPDFFile] = useState("");
  const [viewPdf, setViewPdf] = useState(false);
  const [totalsum, setTotalSum] = useState(0)
  const getFilePluginInstance = getFilePlugin();
const { DownloadButton } = getFilePluginInstance;
  //search api intigreation
  const [recommandItem, setRecommandItem] = useState("");
  useEffect(() => {
    if (search) {
      const apiUrl = `${BASE_Url}/search/get-result/80001/8/${search}`;
      axios
        .get(apiUrl)
        .then((response) => {
          if (response.status === 200) {
            console.log("new Api", response);
            const data = response.data.data;
            console.log(" search", data);
            // Assuming you want to display the data for the fourth item (index 3)
            // setItemData(data.data.item_list[3]);
            setRecommandItem(data);
            // setSearch(data);
          } else {
            console.log("Failed to fetch data from the API.");
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } else {
      CatgoryData();
    }
  }, [search]);

  const CatgoryData = () => {
    const api = `${BASE_Url}/item/view-menu/${id}/${saas_id}`;
    axios
      .get(api)
      .then((response) => {
        if (response.status === 200) {
          console.log(response, "from fetchdata");
          const data1 = response.data.data.item_list;
          console.log(" menu", response.data.data.category_name);
          setCatgorynaem(response.data.data.category_name);
          setRecommandItem(data1);
        } else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    CatgoryData();
  }, [params]);
  console.log(params);
  useEffect(() => {
    const t1 = JSON.parse(localStorage.getItem("my-cart"));
    let finalSum = 0;
    let itemTotalPrice;
    t1?.map((item) => {
      itemTotalPrice = item.item_newprice
        ? item.item_newprice
        : item.item_price * item.item_qty;
      finalSum += itemTotalPrice;
      setTotalSum(finalSum);
    });
    setCartData(t1);
    setCount(t1?.length);
    console.log(t1);
  }, [updatecart]);
    
  // this is resciete function 
  const myorders = () => {
    const data= JSON.parse(localStorage.getItem("my-order")) 
    console.log("this is order_id",data?.order_id)
      if (data?.order_id) {
       
        axios.get(
          `${BASE_Url}/order/get-invoice-for-order/${data.order_id}/${saas_id}/${store_id}`
        ).then((res)=>{
          // console.log("invoice", res.data.data.invoice );
          setPDFFile(res?.data?.data?.invoice);
          console.log("pdf file",PdfFile)
          // setQr(res.data?.data?.qr_file_name); 
          if (res?.data?.data?.invoice) {
            setViewPdf(true);
            localStorage.removeItem('my-order')
          }else{
            Swal.fire(
              "Please Wait",
              `Your Invoice not Generated Now`,
              "info"
            );
          }
        })
      }else{
        Swal.fire(
          "Please Place New Order",
          `Need to Place Order`,
          "info"
        ); 
      }
    
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  height: 575,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MyTotalbill= () =>{
  const data = JSON.parse(localStorage.getItem("my-order")) 
  console.log('total bill',data?.order_id )
  if(data?.order_id){
    axios
    .get(
      `${BASE_Url}/order/total-bill-amount/${data?.order_id}`
    )
    .then((res) => {
      if (res.status === 200) {
        console.log("recommended DATA", res.data.data.total_bill_amount);
        Swal.fire(`Your Total Amount: Rs.${res.data.data.total_bill_amount}`)
      }
    })
    .catch((err) => console.log(err));

  }else{
    Swal.fire(
      "Please Place New Order",
      `Need to Place Order`,
      "info"
    ); 
  }
}
const [activeIcon, setActiveIcon] = useState(null);
const [isPhoneNumberSaved, setIsPhoneNumberSaved] = useState(false);

// Check if phone number is saved in localStorage
useEffect(() => {
  const customerPhoneNumber = localStorage.getItem("phone_number");
  setIsPhoneNumberSaved(customerPhoneNumber); // Update state based on existence of phone_number
}, []); // Empty dependency array to run only on mount
const jsonStoreString = localStorage.getItem("Store_data");
const parsedstoreData = JSON.parse(jsonStoreString || "{}"); // Fallback to an empty object if null
const { qsr } = parsedstoreData;
const RType = qsr;


// table name
const [tableName, setTableName] = useState('');
const getTablename = async ()=>{
 try {
   const response = await axios.get(
     `${BASE_Url}/tableview/get-tables-by-tableid/${table_id}`
   );
   const data = response?.data?.data;
   if(data){
     setTableName(data.tableName)
   }
 } catch (error) {
   console.log(error);
 }
}

 useEffect(() => {
   getTablename()
 }, [saas_id, store_id]);
  return (
    <div>
      <Header/>
      <div className="flex items-center justify-center flex-col " style={{
        borderBottom: '2px solid #B2B2B2',
    }}>
      <div className="flex items-start w-[370px] text-[#797979]">
        <div onClick={()=>{navigate(`/${saas_id}/${store_id}/${table_id}`)}} className=" p-2 rounded-lg mr-2">
          <FaArrowLeft className="w-5 h-5 " />
        </div>
        <span className="text-lg font-semibold p-1">Back To Home</span>
      </div>
      <Navbar setRecommandItem={setRecommandItem} saas_id={saas_id} store_id={store_id} table_id={table_id} />

      </div>
      {show && (
<>
         { isPhoneNumberSaved || !RType? ( <Cart
          show={show}
          setShow={setShow}
          updatecart={updatecart}
          cartData={cartData}
          setCartData={setCartData}
          setCount={setCount}
          table_id={table_id}
          isPhoneNumberSaved={isPhoneNumberSaved}
        />
      ) : (
        <LoginModal 
        setIsPhoneNumberSaved={setIsPhoneNumberSaved}
        show={show}
        setShow={setShow} /> // Show LoginModal if phone number is not saved in localStorage
      )}
     </> )}
       <Modal
            open={viewPdf}
            // onClose={}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setViewPdf((state) => !state);
                  // navigate("/");
                }}
              >
                <AiOutlineClose size={20} />
              </div>

              {PdfFile && (
                <div style={{ height: "400px" }}>
                  <div
            style={{
                alignItems: 'center',
                backgroundColor: '#eeeeee',
                borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                padding: '4px',
                justifyContent:"center"
            }}
        >
            <DownloadButton  />
        </div>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={`${BASE_Url}/transaction/pdf/${PdfFile}`}
                      plugins={[getFilePluginInstance]} 
                    />
                  </Worker>
                  
                </div>
              )}
              <div
              className="gap-3"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button className="bg-dark-subtle mt-10 p-2" onClick={()=>setViewPdf(false)}>
                 Close
                </button>
                <button className="bg-dark-purple text-white mt-10 p-2" onClick={()=>navigate(`/feedback/${saas_id}/${store_id}/${table_id}`)}>
                 Give FeedBack
                </button>
              
              </div>
            </Box>
          </Modal>
      <li className="flex">
        <div className=" flex flex-1 flex-col">
        
         {recommandItem&& <Product
            catgoryName={catgoryName}
            recommandItem={recommandItem}
            updatecart={updatecart}
            setUpdatecart={setUpdateCart}
            tableName={tableName}
          />}
        </div>
      </li>


     {tableName !== "Menu" && (<>
      <footer
            style={{
              width: "100%",
              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
              // borderRadius: "0.75rem",
              padding: "10px",
              backgroundColor: "white",
              height:"80px"
            }}
            className="
            container
            d-flex
            justify-between
            text-white
             fixed
             bottom-0"
          >
            <BottomNavigation
        showLabels
        style={{width: "100%" }}
        // value={value}
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
      >
        <BottomNavigationAction onClick={()=>navigate(`/${saas_id}/${store_id}/${table_id}`)} label="Menu" icon={<MenuIcon />} />
        <BottomNavigationAction onClick={() => setShow(true)} label="Cart" icon={
        <Badge badgeContent={cartData?.length} color="success">
          <CartIcon className="dark-white"/>
        </Badge>
           } />
        <BottomNavigationAction onClick={()=>{navigate(`/Order-status/${saas_id}/${store_id}/${table_id}`)}} label="Orders" icon={<OrdersIcon />} />
        {/* <BottomNavigationAction onClick={MyTotalbill}label="Orders" icon={<BsBucketFill />} /> */}
        <BottomNavigationAction onClick={()=>{myorders()}} label="MyBill" icon={<PreviewIcon />} />
      </BottomNavigation>
            {/* {cartData?.length > 0 ? (
              <>
                <div className="d-flex flex-column text-[#0009]">
                <div className=" css-de1c49">
                  <AiOutlineShoppingCart
                    style={{ height: "25px", fontSize: "26px" }}
                    onClick={() => setShow(true)}
                  />
                  <span className="fw-bold mx-1 " style={{fontFamily:"sans-serif"}}> ( { count } )</span>
                </div>
                  <span className="fw-bold" style={{fontFamily:"sans-serif"}}>RS. {totalsum}</span>

                </div>

                <span style={{fontFamily:"sans-serif"}} className="fw-bold cursor-pointer text-[#0009]" onClick={() => setShow(true)}>
                  View Cart
                </span>
              </>
            ) : (
              <>
              </>
             
            )} */}
          </footer> </>)}
    </div>
  );
}

export default Beverages;
