import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import FlatDiscount from './FlatDiscount';
import { Edit } from '@mui/icons-material';

const GetFlatDiscount = () => {
    const [discountData, setDiscountData] = useState([]);
    const [open, setOpen] = useState(false);
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

    const fetchDiscountData = async () => {
        try {
            const response = await axios.get(`${BASE_Url}/item/get-flat-discount/${saasId}/${storeId}`);
            setDiscountData(response.data?.data || []);
        } catch (error) {
            console.error('Error fetching discount data:', error);
        }
    };
    useEffect(() => {

        fetchDiscountData();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Box
                className="mb-2"
                p={2}
                borderRadius={2}
                border="1px solid #e0e0e0"
                boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                width="100%"
                height="fit-content"
                bgcolor="white"
            >
                <div className="flex justify-between items-center">
                    <h1 className="text-2xl">Create Supplier Directory</h1>
                    <Button
                        className="fw-bold"
                        style={{ background: 'rgb(14, 41, 84)' }}
                        variant="contained"
                        color="primary"
                        onClick={handleClickOpen}
                    >
                        Create
                    </Button>
                </div>
            </Box>
            <Box className="h-100 w-100 rounded-lg">
                <TableContainer component={Paper}>
                    <Table className="text-nowrap" aria-label="simple table">
                        <TableHead className="bg-dark-purple">
                            <TableRow>
                                <TableCell className="text-white">Start Date</TableCell>
                                <TableCell className="text-white">End Date</TableCell>
                                <TableCell className="text-white">Discount Per</TableCell>
                                <TableCell className="text-white">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                       
                <TableRow >
                    <TableCell>{discountData.startdate}</TableCell>
                    <TableCell>{discountData.enddate}</TableCell>
                    <TableCell>{discountData.discount}</TableCell>
                    <TableCell><Edit className='text-dark-purple cursor-pointer' onClick={handleClickOpen}/></TableCell>
                   
                </TableRow>
            
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create Discount</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To create a new discount, please enter the details here.
                    </DialogContentText>
                   <FlatDiscount fetchDiscountData={fetchDiscountData}  handleClose={handleClose}/>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Cancel</Button> */}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default GetFlatDiscount;