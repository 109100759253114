import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { BASE_Url } from '../../URL.js/URL'
import DownloadComponent from '../CSVDownload/DownloadComponent'
import DownloadPDFButton from '../CSVDownload/DownloadPDFButton'
import { useReactToPrint } from 'react-to-print'
import Settlementmodal from '../Settlementmodal/Settlementmodal'

const DueAmountReport = ({Startdate, toDate}) => {

    const [allData, setAllData] = React.useState([])
    const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
    const [modal, setModal]= useState(false)
    const [selectInvoice, setSelectedInvoice] = useState("")
    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_Url}/user-master/get-dueAmountReport/${saasId}/${storeId}/${Startdate}/${toDate}`)
            if (response.data.status) {
                setAllData(response.data.data && response.data.data.length > 0? response.data.data : []);
            }
        } catch (error) {
         console.log(error)   
        }
    }

    useEffect(() => {
        fetchData()
    }, [Startdate, toDate])

    const headers = [
        { label: "Business Date", key: "business_date" },
        { label: "Invoice", key: "invoice_no" },
        { label: "Total", key: "total_sum" },
        { label: "Customer Name", key: "customer_name" },
        { label: "Customer mobile", key: "mobile" },
    ]


    //new update code
    const tableRef = useRef(null); // Ref for the table container

    const handleTablePrint = useReactToPrint({
      content: () => tableRef.current,
      documentTitle: 'Sales_Report',
      onBeforePrint: () => console.log("Printing Sales Table..."),
    });
  
  return (
    <div>
        <DownloadComponent headers={headers} data={allData} fileName="DueAmountReport" />
            
 
        <Button
          variant="contained"
          className="mx-2 "
          style={{background:"rgb(14, 41, 84)",color:"white"}}
          onClick={handleTablePrint}
        >
          PRINT 
        </Button>
           {/* Reusable PDF Download Button */}
           <DownloadPDFButton
          data={allData}
          headers={headers}
          fileName="DueAmountReport.pdf"
        />
        
         <Box ref={tableRef} className="h-100 w-100 rounded-lg">
    <TableContainer component={Paper}>
      <Table className="text-nowrap" aria-label="simple table">
        <TableHead className="bg-dark-purple">
          <TableRow>
            <TableCell className=" text-white">Date</TableCell>
            <TableCell className=" text-white">Invoice No.</TableCell>
            <TableCell className=" text-white">Total Amount</TableCell>
            <TableCell className=" text-white">Customer Name</TableCell>
            <TableCell className=" text-white">Customer Mobile</TableCell>
            <TableCell className=" text-white">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allData?.map((ele,index) => (
            <TableRow key={index}>
              <TableCell>{ele.business_date}</TableCell>
              <TableCell>{ele.invoice_no}</TableCell>
              <TableCell>{ele.total_sum}</TableCell>
              <TableCell>{ele.customer_name}</TableCell>
              <TableCell>{ele.mobile}</TableCell>
              <TableCell>
              <Button
          variant="contained"
          className="mx-2 "
          style={{background:"rgb(14, 41, 84)",color:"white"}}
          onClick={()=>{setModal(true);
            setSelectedInvoice(ele)
          }}
        >
          Clear Payment
        </Button>
              </TableCell>
              {/* <TableCell>{ele.sum(amount)}</TableCell> */}
              {/* <TableCell>{ele.tax_total}</TableCell>
              <TableCell>{ele.discount}</TableCell> */}
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
  <Settlementmodal getTables={fetchData} invoicemodal={modal} invoicemodalClose={setModal} inviceAmount={selectInvoice.total_sum} invoiceNo={selectInvoice.invoice_no} />
    </div>
  )
}

export default DueAmountReport