import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useRef } from 'react'
import { BASE_Url } from '../../URL.js/URL';
import moment from 'moment';
import DownloadComponent from '../CSVDownload/DownloadComponent';
import DownloadPDFButton from '../CSVDownload/DownloadPDFButton';
import { useReactToPrint } from 'react-to-print';

const KotReport = ({start, todate}) => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
    // fetch data from API
    const [allData, setAllData] = React.useState([])

    const fetchdata = async () => {
        try {
            const response = await axios.get(`${BASE_Url}/kot/get-report/${saasId}/${storeId}/${start}/${todate}`)
            if (response.data.status) {
                setAllData(response.data.data && response.data.data.length > 0? response.data.data : []);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
      fetchdata();
    }, [start, todate])
    
  
    const headers = [
      { label: "KOT Number", key: "kotNo" },
      { label: "Order ID", key: "orderId" },
      { label: "Item Name", key: "itemName" },
      { label: "Item Qty", key: "itemQty" },
      { label: "Item Price", key: "itemPrice" },
      { label: "Table Name", key: "tableName" },
      { label: "Status", key: "status" },
      { label: "Invoice Number", key: "invoiceNo" },
      { label: "Printed At", key: "orderDate" },
   
  ]

     //new update code
     const tableRef = useRef(null); // Ref for the table container
    const [printingstart, setPrintingStart] = React.useState(false);
     const handleTablePrint = useReactToPrint({
      content: () => tableRef.current,
      documentTitle: 'Kot_Report',
      onBeforePrint: () => {
        console.log("pting Kot Report...");
        setPrintingStart(true);
      },
      onAfterPrint: () => {
        setPrintingStart(false) 
        
      },
    });
  return (
    <div>
 <DownloadComponent headers={headers} data={allData} fileName="KotReport" />
            
 
            <Button
              variant="contained"
              className="mx-2"
              style={{background:"rgb(14, 41, 84)",color:"white"}}
              onClick={()=>{
                handleTablePrint();
              }}
            >
              PRINT 
            </Button>
               {/* Reusable PDF Download Button */}
               <DownloadPDFButton
              data={allData}
              headers={headers}
              fileName="KotReport.pdf"
            />

         <Box  className="h-100 w-100 rounded-lg">
    <TableContainer 
    style={{maxHeight: printingstart ? "":'calc(100vh - 250px)'  , maxWidth: 'calc(100vh + 250px)' , overflow:"auto"}} 
    component={Paper}>
      <Table ref={tableRef} className="text-nowrap" aria-label="simple table">
        <TableHead className="bg-dark-purple">
          <TableRow>
            <TableCell className=" text-white">KOT Number</TableCell>
            <TableCell className=" text-white">Order ID</TableCell>
            <TableCell className=" text-white">Item Name</TableCell>
            <TableCell className=" text-white">Item Qty</TableCell>
            <TableCell className=" text-white">Item Price</TableCell>
            <TableCell className=" text-white">Table Name</TableCell>
            <TableCell className=" text-white">Invoice Number</TableCell>
            <TableCell className=" text-white">Cashier ID</TableCell>
            <TableCell className=" text-white">Printed At</TableCell>
            <TableCell className=" text-white">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allData?.map((ele,index) => (
            <TableRow key={index}>
              <TableCell>{ele.kotNo}</TableCell>
              <TableCell>{ele.orderId}</TableCell>
              <TableCell>{ele.itemName}</TableCell>
              <TableCell>{ele.itemQty}</TableCell>
              <TableCell>{ele.itemPrice}</TableCell>
              <TableCell>{ele.tableName}</TableCell>
              <TableCell>{ele.invoiceNo}</TableCell>
              <TableCell>{ele.cashierId}</TableCell>
              <TableCell>{ele.endTransaction}</TableCell>
              <TableCell>{ele.status}</TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
    </div>
  )
}

export default KotReport