import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { BASE_Url } from '../../URL.js/URL';
import moment from 'moment';
import DownloadComponent from '../CSVDownload/DownloadComponent';
import DownloadPDFButton from '../CSVDownload/DownloadPDFButton';
import { useReactToPrint } from 'react-to-print';

const NetSalesReport = ({ start, todate }) => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
    const [allData, setAllData] = React.useState([]);
    const tableRef = useRef(null); // Ref for the table container
    const [printingStart, setPrintingStart] = React.useState(false);

    // Fetch data from API
    const fetchdata = async () => {
        try {
            const response = await axios.get(`${BASE_Url}/user-master/net-sales-report/${saasId}/${storeId}/${start}/${todate}`);
            if (response.data.status) {
                setAllData(response.data.data && response.data.data.length > 0 ? response.data.data : []);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchdata();
    }, [start, todate]);

    // CSV Headers
    const headers = [
        { label: "Date", key: "date" },
        { label: "Net Amount", key: "netAmount" },
        { label: "Gross Amount", key: "grossAmount" },
        // { label: "Total Payment Amount", key: "total_payment_amount" },
        { label: "Total Due Amount", key: "total_due_amount" },
    ];

    const handleTablePrint = useReactToPrint({
        content: () => tableRef.current,
        documentTitle: 'NetSales_Report',
        onBeforePrint: () => {
            setPrintingStart(true);
        },
        onAfterPrint: () => {
            setPrintingStart(false);
        },
    });

    return (
        <div>
            <DownloadComponent headers={headers} data={allData} fileName="NetSalesReport" />
            <Button
                variant="contained"
                className="mx-2"
                style={{ background: "rgb(14, 41, 84)", color: "white" }}
                onClick={handleTablePrint}
            >
                PRINT
            </Button>
            <DownloadPDFButton
                data={allData}
                headers={headers}
                fileName="NetSalesReport.pdf"
            />

            <Box className="h-100 w-100 rounded-lg">
                <TableContainer
                    style={{ maxHeight: printingStart ? "" : 'calc(100vh - 250px)', overflow: "auto" }}
                    component={Paper}
                >
                    <Table ref={tableRef} className="text-nowrap" aria-label="simple table">
                        <TableHead className="bg-dark-purple">
                            <TableRow>
                                <TableCell className="text-white">Date</TableCell>
                                <TableCell className="text-white">Net Amount</TableCell>
                                <TableCell className="text-white">Gross Amount</TableCell>
                                {/* <TableCell className="text-white">Total Payment Amount</TableCell> */}
                                <TableCell className="text-white">Total Due Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allData.map((ele, index) => (
                                <TableRow key={index}>
                                    <TableCell>{ele.date}</TableCell>
                                    <TableCell>{ele.netAmount}</TableCell>
                                    <TableCell>{ele.grossAmount}</TableCell>
                                    {/* <TableCell>{ele.total_payment_amount ?? "N/A"}</TableCell> */}
                                    <TableCell>{ele.total_due_amount}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default NetSalesReport;
