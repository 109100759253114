import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { use } from 'react'
import DataTable from 'react-data-table-component'
import { BASE_Url } from '../../URL.js/URL'
import axios from 'axios'
import Swal from 'sweetalert2'
import AddUserModal from './AddUserModal'
import EditUserModal from './EditUserModal'

const GetAllUser = () => {
    const [user, setUser] = useState([])
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [open , setOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editUserData, setEditUserData] = useState(null);
    
    const handleEdit = (user)=>{
        setEditModalOpen(true)
        setEditUserData(user)
    }

    //create function for get user by page number
    async function getUser(pageNumber) {
        const apiUrl = `${BASE_Url}/user-master/get-users/${pageNumber}`
        try {
            const response = await axios.get(apiUrl)
            console.log('API response:', response.data)
            setUser(response.data.data)
            setTotalRows(response.data.count) // Assuming the API returns total rows.
        } catch (error) {
            console.log('API request failed:', error)
        }
    }
   
    //create function for handle page change
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        getUser(page);
    }, [ page]);

    //delete and edit function will be here
    const handleDelete = async (id) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            if (result.isConfirmed) {
                const apiUrl = `${BASE_Url}/user-master/delete-user/${id}`;
                const response = await axios.delete(apiUrl);
                Swal.fire('Deleted!', 'User has been deleted.', 'success');
                getUser(1); // Refresh the list after deletion
            }
        } catch (error) {
            console.log('API request failed:', error);
        }
    }

    
   
    const columns = [
        {
            name: 'User Name',
            selector: (row) => row.user_name,
            sortable: true,
        },
        {
            name: 'Store Name',
            selector: (row) => row.store_name,
            sortable: true,
        },
        {
            name: 'City',
            selector: (row) => row.city,
            sortable: true,
        },
        {
            name: 'State',
            selector: (row) => row.state,
            sortable: true,
        },
        {
            name: 'Country',
            selector: (row) => row.country,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: (row) => row.created_at,
            sortable: true,
        },
        {
            name: 'Expiration',
            selector: (row) => row.expiration,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleEdit(row)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleDelete(row.user_id)}
                    >
                        Delete
                    </Button>
                </div>
            ),
        },
    ]

  return (
    <div>
        <div className="flex justify-between items-center">
            <h1 className="text-2xl">Create User</h1>
            <Button
              className="fw-bold bg-dark-purple text-white"
              variant="contained"
              style={{ background: 'rgb(14, 41, 84)' }}
            //   color="primary"
                onClick={()=>setOpen(true)}
            >
              Create Users
            </Button>
          </div>
          <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl mb-4 fw-bold">User Directory</h2>
          <div style={{ maxHeight: 'calc(100vh - 250px)', overflow: 'auto' }}>
            <DataTable
              columns={columns}
              data={user}
              pagination
              responsive
              striped
              highlightOnHover
              pointerOnHover
              noHeader
              paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
              />
          </div>
        </div>
        <AddUserModal show={open} handleClose={()=>setOpen(false)}   />
        <EditUserModal show={editModalOpen} handleClose={()=>setEditModalOpen(false)} userData={editUserData} />
    </div>
  )
}

export default GetAllUser