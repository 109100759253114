import React, { useEffect, useState } from "react";
import TableComponent from "../Components/TableComponent.js";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserList } from "../redux/slices/userManagement.js";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography, Select, MenuItem, Button } from "@mui/material";
import { Tab, Tabs } from "react-bootstrap";
import Storeuser from "./Storeuser.js";

const tableKey = [
  { headerName: "Staff Id", headerKey: "" },
  { headerName: "Staff Name", headerKey: "" },
  { headerName: "Gender", headerKey: "" },
  { headerName: "Date Of Joining", headerKey: "" },
  { headerName: "Salary(Monthly)", headerKey: "" },
  { headerName: "Incentive", headerKey: "" },
  { headerName: "Action", headerKey: "" },

];

const roleKey = [
  { headerName: "Role Id", headerKey: "" },
  { headerName: "Role Name", headerKey: "" },
  { headerName: "Security Id", headerKey: "" },
  { headerName: "Function Name", headerKey: "" },
  { headerName: "Allowed/Not-Allowed", headerKey: "" },
  { headerName: "Action", headerKey: "" },

];

function Usermanagement() {
  const [state, setState] = useState("User Master");
  const [activeTab, setActiveTab] = useState("AllUser");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, list } = useSelector((state) => state.userManagement);

  useEffect(() => {
    dispatch(getAllUserList(state));
  }, [state, dispatch]);

  const scrollableContainerStyle = {
    maxHeight: '400px', // Adjust the height as needed
    overflowY: 'auto',
  };

  return (
    <Box sx={{ height: "100%",  p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Users Staff Management
      </Typography>
      <Box mt={3}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Select
              fullWidth
              value={state}
              onChange={(e) => setState(e.target.value)}
              variant="outlined"
            >
              <MenuItem value="User Master">User Master</MenuItem>
              <MenuItem value="Role Master">Role Master</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm="auto">
            {state === "User Master" ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/AddStaff")}
              >
                Add Staff
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/AddRole")}
              >
                Add Role
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Tabs
         activeKey={activeTab}
         onSelect={(key) => setActiveTab(key)}
      id="uncontrolled-tab-example"
      className="mb-3  fw-bold"
      fill
      style={{background: '#FFF',
        boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"
        }}
        // onSelect={(key)=>{
        //   setActivetab(key)
        // }}
        
    >
      <Tab eventKey ="AllUser" title="All User">
       <Storeuser/>
      </Tab>
      <Tab eventKey ="Staff" title="All Staff">
      <Box style={scrollableContainerStyle}>
        <TableComponent
          state={state}
          addButton={"Add Staff"}
          addUrl={"/AddStaff"}
          editUrl={"/EditStaff"}
          roleUrl={"/RoleMaster"}
          addRole={"/AddRole"}
          list={list}
          loading={loading}
          headers={state === "User Master" ? tableKey : roleKey}
        />
      </Box>
      </Tab>
    </Tabs>
      
    </Box>
  );
}

export default Usermanagement;
