import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, useMediaQuery, Snackbar } from '@mui/material';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import Swal from 'sweetalert2';

const CreateDeliveryBoy = ({fetchDeliveryBoys}) => {
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [formData, setFormData] = useState({
        aadhaar_number: '',
        name: '',
        mobile_number: '',
        address: '',
        city: '',
        pincode: '',
        store_id: '',
        saas_id: '',
        password: ''
    });
    const { storeId, saasId } = JSON.parse(
        localStorage.getItem("STORE_DATA"))
        useEffect(() => {
            setFormData((prevData) => ({
                ...prevData,
                store_id: storeId,
                saas_id: saasId
            }));
        }, [storeId, saasId]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validation
        if (!formData.aadhaar_number || !formData.name || !formData.mobile_number || !formData.address || !formData.city || !formData.pincode || !formData.password) {
        // alert("Please fill in all the required fields.");
        setSnackbarMessage("Please fill in all the required fields.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
            return;
        }
        const form = new FormData()
        Object.keys(formData).forEach(key => {
            form.append(key, formData[key]);
        });
        try {
            const response = await axios.post(`${BASE_Url}/delivery/create-delivery-user`,form );
            console.log(response.data);
            if(response.data.status){
                fetchDeliveryBoys()
                handleClose();
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Delivery boy created successfully!',
                    confirmButtonText: 'OK'
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message || 'Failed to create delivery boy.',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error?.message || 'An error occurred while creating the delivery boy.',
                confirmButtonText: 'OK'
            });
        }finally{
            handleClose();
            setFormData({
                aadhaar_number: '',
                name: '',
                mobile_number: '',
                address: '',
                city: '',
                pincode: '',
                store_id: storeId,
                saas_id: saasId,
                password: ''
            });
        }
    };

    const isLargeScreen = useMediaQuery('(min-width:600px)');

    return (
        <>
        <div>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Create Delivery Boy
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ ...style, width: isLargeScreen ? 600 : 400 }}>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Aadhaar Number"
                            name="aadhaar_number"
                            value={formData.aadhaar_number}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Mobile Number"
                            name="mobile_number"
                            value={formData.mobile_number}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="City"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Pincode"
                            name="pincode"
                            value={formData.pincode}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Password"
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Submit
                        </Button>
                    </form>
                </Box>
            </Modal>
           
        </div>
        <Snackbar
  open={snackbarOpen}
  autoHideDuration={2000}
  onClose={()=>setSnackbarOpen(false)}
  message={snackbarMessage}
//   action={action}
/>
        </>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default CreateDeliveryBoy;