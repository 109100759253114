import React, { useEffect, useState } from 'react'
import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    Paper,
    IconButton,
    styled,
    Modal,
    Box,
    Typography,
    Grid,
    Alert,
  } from '@mui/material';
  import CloseIcon from '@mui/icons-material/Close';
  import CreditCardIcon from '@mui/icons-material/CreditCard';
  import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
  import { SiPaytm } from "react-icons/si";
  import { GiCash } from "react-icons/gi";
import { BASE_Url } from '../../URL.js/URL';
import axios from 'axios';
import Swal from 'sweetalert2';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const Invoicestyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const PaymentMethodButton = styled(Button)(({ theme, selected }) => ({
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    border: `1px solid ${selected ? theme.palette.primary.main : theme.palette.divider}`,
    backgroundColor: selected ? theme.palette.primary.light : 'transparent',
    '&:hover': {
      backgroundColor: selected ? theme.palette.primary.light : theme.palette.action.hover,
    },
  }));
  
  const paymentMethods = [
    // { name: 'Paytm', icon: '/placeholder.svg?height=24&width=24' },
    // { name: 'GooglePay', icon: '/placeholder.svg?height=24&width=24' },
    { name: 'Cash', icon: <AttachMoneyIcon /> },
    { name: 'Card', icon: <CreditCardIcon /> },
    { name: "PayTm", icon: <SiPaytm  /> },
    { name: " Due Amount",  icon: <AccessTimeIcon   /> },
    { name: "Other",  icon: <GiCash  /> },
    // { name: 'BhimPay', icon: '/placeholder.svg?height=24&width=24' },
    // { name: 'Knet', icon: '/placeholder.svg?height=24&width=24' },
    // { name: 'Online Paid', icon: '/placeholder.svg?height=24&width=24' },
    // { name: 'SBI', icon: '/placeholder.svg?height=24&width=24' },
    // { name: 'PhonePe', icon: '/placeholder.svg?height=24&width=24' },
  ];
const Settlementmodal = ({invoicemodal,invoicemodalClose,invoiceNo,inviceAmount,getTables}) => {
    const { saasId, storeId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const [selectedMethod, setSelectedMethod] = useState('Cash');
  const [splitPayment, setSplitPayment] = useState(false);
  const [paymentReference, setPaymentReference] = useState('');
  const [tipAmount, setTipAmount] = useState('');
  const [CustomerAmount, setCustomerAmount] = useState('');
  const [Changedue, setChangedue] = useState("");
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethods] = useState([])
  const [alertshow, setAlertShow] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  //Function to add payment type and amount in array of objects
    //calculate total amount selected in array
    const totalSelectedAmount = paymentMethod.reduce((total, item) => total + parseFloat(item.amount), 0);
    const dueamount = inviceAmount - totalSelectedAmount;
  const addPayment = (tender, amount) => {
    //if method already exists, replace the existing amount
    //if inviceAmount is greater than the amount then return error message
    //if tender is null then show error message
    console.log(tender)
    if (tender == "" || amount <= 0) {
      setAlertShow(true);
      setAlertmessage("Please select payment method & amount not be zero");
      setTimeout(() => {
          setAlertShow(false);
          setAlertmessage("");
      }, 2000);
      return;
    }
    
    if (amount > dueamount) {
      setAlertShow(true);
      setAlertmessage("Amount should not exceed the due amount");
      setTimeout(() => {
          setAlertShow(false);
          setAlertmessage("");
      }, 2000);
      return;
    }
    const existingIndex = paymentMethod.findIndex((item) => item.tender === tender);
    if (existingIndex > -1) {
      paymentMethod[existingIndex].amount = amount;
    } else {
      //else add new payment type and amount
      setPaymentMethods([...paymentMethod, { tender, amount }]);
    }
    setAmount('');
    setSelectedMethod('');
  };

  const AddChangeDue = ()=>{
    const dueAmount =  CustomerAmount - inviceAmount;
    setChangedue(dueAmount);
  }
  
  
  //api call for update payment method by invoice number
  const updatePaymentMethod = async () => {
    try {
      //api call for update payment method by invoice number
      //if due amount is greater than 0 then return error message
      if(dueamount > 0) { 
        setAlertShow(true);
        setAlertmessage("Due amount should be zero to update payment method");
        setTimeout(() => {
            setAlertShow(false);
            setAlertmessage("");
        }, 2000);
        return;
      }
      //add change due filed in payment method array only for only case
      paymentMethod[0].changeDue = Changedue;

      const apiUrl = `${BASE_Url}/transaction/change-tender/${invoiceNo}/${saasId}/${storeId}`;
      const response = await axios.put(apiUrl, paymentMethod );
      console.log(response);
      if(response.data.status) {
          //if success then close the modal and update the invoice details
          CloseModal();
          getTables()
          setChangedue("")
          setCustomerAmount("")
        Swal.fire({
            title: response.data.message,
            icon:'success',
            timer: 1000,
            showConfirmButton: false,
          
        })
      }else{
        setAlertShow(true);
        setAlertmessage(response.data.message);
        setTimeout(() => {
            setAlertShow(false);
            setAlertmessage("");
        }, 3000);
        return;
      }
      //updateInvoiceDetails();
    } catch (error) {
      console.error(`Error updating payment method for invoice ${invoiceNo}:`, error);
    }
  };
  
  const CloseModal = () => {
    invoicemodalClose();
    //clear payment method array and amount
    setPaymentMethods([]);
    setSelectedMethod('Cash');
    setAmount('');
  };

//   console.log(paymentMethod)

// const handleInvoiceCancel = async () => {
//   // Display confirmation dialog
//   const result = await Swal.fire({
//     title: 'Are you sure?',
//     text: 'Do you want to cancel this invoice?',
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonColor: '#d33',
//     cancelButtonColor: '#3085d6',
//     confirmButtonText: 'Yes, cancel it!',
//     cancelButtonText: 'No, keep it',
//   });

//   if (result.isConfirmed) {
//     try {
//       // Hit the API with invoiceNum and status
//       const status = 'Cancelled'; // Assuming status is "Cancelled"
//       const response = await axios.post(
//         `${BASE_Url}/transaction/change-status/${invoiceNo}/{status}`
//       );

//       if (response.status === 200) {
//         Swal.fire('Cancelled!', 'The invoice has been cancelled.', 'success');
//       } else {
//         Swal.fire('Error!', 'Failed to cancel the invoice.', 'error');
//       }
//     } catch (error) {
//       Swal.fire('Error!', 'An error occurred while cancelling the invoice.', 'error');
//     }
//   }
// };
const [open, setOpen] = useState(false);

const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

const handleConfirmCancel = async () => {
  try {
    // Hit the API with invoiceNo and status
    const status = 'CANCELED'; // Assuming status is "Cancelled"
    const response = await axios.put(
      `${BASE_Url}/transaction/change-status/${invoiceNo}/${status}`
    );

    if (response.status === 200) {
      CloseModal()
        Swal.fire('Cancelled!', 'The invoice has been cancelled.', 'success');
    } else {
      alert('Failed to cancel the invoice.');
    }
  } catch (error) {
    alert('An error occurred while cancelling the invoice.');
  } finally {
    handleClose();
  }
};
  return (
    <><Modal
    open={invoicemodal}
    onClose={CloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={Invoicestyle}>
    <Paper elevation={3} sx={{ p: 3, maxWidth: 700, mx: 'auto' }}>
   
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
    <Button
      variant="contained"
      color="error"
      startIcon={<CancelIcon />}
      onClick={handleOpen}
    >
      Cancel Invoice
    </Button>
    <IconButton onClick={()=>CloseModal()}>
      <CloseIcon />
    </IconButton>
  </Box>
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
    <Typography variant="h6">Choose Payment Mode for Bill Number : {invoiceNo}</Typography>
    {/* <IconButton onClick={()=>CloseModal()}>
      <CloseIcon />
    </IconButton> */}
  </Box>

  <Grid container spacing={2} mb={3}>
    {paymentMethods.map((method) => (
      <Grid item xs={4} key={method.name}>
        <PaymentMethodButton
          fullWidth
          variant="outlined"
          selected={selectedMethod === method.name}
          onClick={() => setSelectedMethod(method.name)}
          startIcon={
            typeof method.icon === 'string' ? (
              <Box component="img" src={method.icon} alt={method.name} sx={{ width: 24, height: 24 }} />
            ) : (
              method.icon
            )
          }
        >
          {method.name}
        </PaymentMethodButton>
      </Grid>
    ))}
  </Grid>

  <Typography variant="subtitle1" fontWeight="bold" mb={2}>
    Grand Total: {inviceAmount}
  </Typography>
  {paymentMethod.length>0 && paymentMethod.map((item)=>{return(<Typography variant="subtitle1" fontWeight="bold" mb={2}>
    {item.tender}: {item.amount}
  </Typography>)})}

  <Box display="flex" mb={2}>
    <TextField
      fullWidth
      label="Enter amount"
      value={amount}
      onChange={(e) => setAmount(e.target.value)}
      sx={{ mr: 2 }}
    />
    <Button onClick={()=>addPayment(selectedMethod, amount)} variant="contained" color="primary">
      Add
    </Button>
  </Box>
  {!Changedue &&<Box display="flex" mb={2}>
    <TextField
      fullWidth
      label="Enter Customer Amount"
      value={CustomerAmount}
      onChange={(e) => setCustomerAmount(e.target.value)}
      sx={{ mr: 2 }}
    />
    <Button onClick={()=>AddChangeDue()} variant="contained" color="primary">
      Confirm
    </Button>
  </Box>}
    {alertshow && <Alert severity="error">{alertmessage}</Alert>}
  <Box display="flex " className="justify-around" >

  <Typography variant="body1" mb={2}>
    Remaining Amount: {inviceAmount -totalSelectedAmount}
  </Typography>
  <Typography variant="body1" mb={2}>
    Change Due: {Changedue}
  </Typography>
  </Box>

  <Box display="flex" justifyContent="flex-end" gap={2}>
    <Button onClick={()=>CloseModal()} variant="contained" color="inherit">
      Close
    </Button>
    {/* <Button variant="contained" color="primary">
      Print & Settle Bill
    </Button> */}
    <Button onClick={()=>{updatePaymentMethod()}} variant="contained" color="success">
      Save & Settle Bill
    </Button>
  </Box>
</Paper>
    </Box>
  </Modal>
  
  
  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Confirm Cancellation
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            Are you sure you want to cancel this invoice?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              No
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleConfirmCancel}
            >
              Yes, Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
  
  
  
  </>
  )
}

export default Settlementmodal