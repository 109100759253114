import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import AddStore from './AddStore';


const AddStoreModal = ({ show, handleClose, handleSave }) => {
    const [storeName, setStoreName] = useState('');
    const [storeAddress, setStoreAddress] = useState('');

    const onSave = () => {
        handleSave({ storeName, storeAddress });
        setStoreName('');
        setStoreAddress('');
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>Add New Store</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <AddStore/>
            </Modal.Body>
        </Modal>
    );
};

export default AddStoreModal;