import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, MenuItem, Grid, Card, CardContent, Typography, FormControl, InputLabel, Select } from '@mui/material';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import Swal from "sweetalert2";
function AddUser() {
  const today = new Date();
  const oneYearLater = new Date();
  oneYearLater.setFullYear(today.getFullYear() + 1);


  const generateRegisterId = () => {
    const randomPart = Math.random().toString(36).substring(2, 4).toUpperCase(); // Generate 2 random alphanumeric characters
    return `reg${randomPart}`;
  };

  const [formData, setFormData] = useState({
    user_name: '',
    password: '',
    store_name: '',
    user_type: '',
    store_id: '',
    saas_id: '',
    register_id: generateRegisterId(),
    city: '',
    baar_code: '',
    category_req: 'electronics',
    state: '',
    special_price: '',
    category_name: 'Retail',
    base_currency: 'INR',
    country: '',
    status: true,
    created_at: today.toISOString().slice(0, 19),
    expiration: oneYearLater.toISOString().slice(0, 19),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_Url}/user-master/add-user`, formData);
      console.log("API Response:", response.data);
      if (response.status === 200) {
        console.log('User added successfully:', response.data);
        
                  Swal.fire({
                        title: 'User added successfully!',
                        icon: "success",
                        timer: 2000,
                      });
        // alert('User added successfully!');
      } else {
        console.error('Failed to add user:', response);
        // alert('Failed to add user');
        Swal.fire({
            title: 'Failed to add user!',
            icon: "error",
            timer: 2000,
          });
      }
    } catch (error) {
      console.error('Error:', error);
   Swal.fire({
                title: error.data.message,
                icon: "error",
                timer: 2000,
              });
    }
  };



  const [saasList, setSaasList] = useState([]);  // State to store the fetched SaaS list

  // Fetch SaaS list on component mount
  useEffect(() => {
    const fetchSaasList = async () => {
      try {
        const response = await axios.get(`${BASE_Url}/saas-master/get-all`);
        if (response.data.status) {
          setSaasList(response.data.data);  // Set the fetched SaaS data
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch SaaS list.',
          });
        }
      } catch (error) {
        console.error('Error fetching SaaS list:', error);
      
      }
    };

    fetchSaasList();
  }, []);
  return (
    <Box   style={{height:'calc(100vh - 150px)' , overflowY:"auto"}}
    sx={{
      maxWidth: 1000,
      margin: "auto",
      padding: 3,
    //   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    //   borderRadius: 2,
    }}>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Add User
          </Typography>
          <form  onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Username */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Username"
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* Password */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  type="password"
                  fullWidth
                />
              </Grid>

              {/* Store Name */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Store Name"
                  name="store_name"
                  value={formData.store_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* User Type */}
           {/* User Type */}
<Grid item xs={12} sm={6} md={4}>
  <TextField
    select
    label="User Type"
    name="user_type"
    value={formData.user_type}
    onChange={handleChange}
    fullWidth
  >
    <MenuItem value="ADMIN">Admin</MenuItem>
    <MenuItem value="SUPERUSER">Super User</MenuItem>
    <MenuItem value="CASHIER">Cashier</MenuItem>
    <MenuItem value="CAPTAIN">Captain</MenuItem>
  </TextField>
</Grid>


              {/* Store ID */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Store ID"
                  name="store_id"
                  value={formData.store_id}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* SaaS ID */}
              {/* <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="SaaS ID"
                  name="saas_id"
                  value={formData.saas_id}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid> */}
              <Grid item xs={12} md={4}>
            <FormControl fullWidth required>
              <InputLabel id="saasId-label">SaaS ID</InputLabel>
              <Select
                labelId="saasId-label"
                name="saas_id"
                  value={formData.saas_id}
                  onChange={handleChange}
                label="SaaS ID"
              >
                {saasList.map((saas) => (
                  <MenuItem key={saas.saasId} value={saas.saasId}>
                    {saas.saasName} ({saas.saasId})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
              {/* Register ID */}
              {/* <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Register ID"
                  name="register_id"
                  value={formData.register_id}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid> */}

              {/* City */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* Bar Code */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Bar Code"
                  name="baar_code"
                  value={formData.baar_code}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* Category Request */}
              {/* <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Category Request"
                  name="category_req"
                  value={formData.category_req}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid> */}

              {/* State */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* Special Price */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Special Price"
                  name="special_price"
                  value={formData.special_price}
                  onChange={handleChange}
                  type="number"
                  fullWidth
                />
              </Grid>

              {/* Category Name */}
              {/* <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Category Name"
                  name="category_name"
                  value={formData.category_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid> */}

         

              {/* Country */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

       
             <Grid item xs={12}>
                       <Button
                         type="submit"
                         variant="contained"
                         color="primary"
                         fullWidth
                        //  onClick={handleSubmit}
                       >
                         Add User
                       </Button>
                       </Grid>
            
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}

export default AddUser;
