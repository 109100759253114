import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Grid, Typography, Paper,  } from "@mui/material";
import { Button, Col, Row, Table } from "reactstrap";
import { InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LOYALTY_URL } from "../URL.js/URL";
import MemberEnrollModal from "./LoylityEnroll/MemberEnrollModal";

const CRM = () => {
  const [users, setUsers] = useState([]);
  const [mobileValue, setMobileValue] = useState("");
  const {saasId} = localStorage.getItem("STORE_DATA")
  const navigate = useNavigate()
  function goToCustomer(row){
    navigate("/CustomerOverview", { state: { userData: row } });
    
  }
  function goToMemberEnrollment(){
    navigate("/MemberEnrollment")
    
  }
  
  // const {client_id } = JSON.parse(localStorage.getItem("User_data"));
  const handleSubmit = (event) => {
    event.preventDefault();
    // setRecords(users.filter((f) => f.mobile_number.includes(e.target.value)));
    if (mobileValue) {
      

      axios
        .post(
          `${LOYALTY_URL}/loyalty/customer-details`,
          {
            base_currency: users.find(user => user.mobile_number === mobileValue)?.base_currency,
            mobile_number: mobileValue,
           
            client_id: "8",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("SEARCHED VALUE", res.data);
          setUsers([res.data]);
        })
        .catch((err) => {
          console.log("SEARCHED VALUE", err);
        });
    } else {
      setUsers(users);
    }
  };

  const getAllUsers = () => {
    axios
      .get(`${LOYALTY_URL}/loyalty/get-all-customers/${saasId}`)
      .then((res) => {
        const { data } = res.data;
        console.log("CUSTOMERS ALL DATA", data);
        setUsers(data);
        // setUsers(data);
      })
      .catch((err) => {
        console.log("ERROR ALL CUSTOMERS", err);
      });
  };


  useEffect(() => {
    getAllUsers();
  
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setMobileValue(value);

    // Trigger API request when input is cleared or becomes empty
    if (!value || value.length === 5) {
      getAllUsers();
    }
  };





 


  const customStyles = {
    rows: {
      style: {
        // minHeight: '72px', // override the row height
        // border: "1px solid var(--secondary2) !important",
        border: "1px solid var(--secondary2) !important",
        marginBottom: "5px",
        borderRadius: "5px",
      },
    },
    headCells: {
      style: {
        backgroundColor: "var(--secondary3)",
        fontWeight: "bold",
        fontSize: "14px",
        textTransform: "uppercase",
        marginBottom: "10px",
        padding: "15px 20px",
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
      },
    },
    cells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for data cells
        // paddingRight: '8px',
        // border: "1px solid var(--secondary2)",
        // borderBottom: "1px solid var(--secondary2)"
      },
    },
  };
  const [memberEnroll, setMemberEnroll] = useState(false);
  return (
    <>
    <Box mt={4} p={2} style={{ height: "100vh", overflowY: "auto"}}>
      {/* <Typography variant="h6" fontWeight="medium" mb={1} className="font-['inter']" >
        CRM
      </Typography> */}
      
    <div className='container'>
        <Row className='m-1'>
        <Col xs={12} sm={1}>
 <Typography variant="h5" fontWeight="medium" className="font-['inter']" >
        Loyalty
      </Typography>
        </Col>
          <Col xs={12} sm={8}>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Search Customer by Mobile"
                aria-label="Search"
                aria-describedby="basic-addon2"
                 required
                      value={mobileValue}
                      onChange={handleInputChange}
              />
              <Button onClick={handleSubmit}  style={{width: '77px',
  height: '40px',
  flexShrink: 0,
  background: 'rgb(14, 41, 84)',}}><FaSearch /> </Button>

            </InputGroup>
          </Col>
          <Col xs={12} sm={3}>
            <Button className='text-nowrap' style={{  borderRadius: '10px',
  background: 'var(--Primary-1, rgb(14, 41, 84))',
  boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)',
  display: 'inline-flex',
  padding: '9px 37px 9px 38px',
  justifyContent: 'center',
  alignItems: 'center',}} onClick={() => {
    setMemberEnroll(true);
  }}>Member Enrollment</Button>
          </Col>
        </Row>

       


      </div>

      <Grid container spacing={4} style={{ marginBottom: "40%" }}>
      {users?.map((ele) => (
        <Grid key={ele.loyalty_id} item xs={12} sm={4}>
          <Paper elevation={3} className="p-4">
            <Grid container spacing={2}>
              <Grid item>
                <AccountCircleIcon style={{ fontSize: "60px", color: "gray" }} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h6" fontWeight="bold">
                 {ele.customer_name}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body2">Mobile Number : {ele.mobile_number}</Typography>  </Grid>
                
                </Grid>
                <Grid container spacing={1}>
              
                  <Grid item xs={12}>
                    <Typography variant="body2">Address:  {ele.address_line1} </Typography>
                 </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Typography variant="body2">Loyalty Points</Typography>
                <Typography variant="h6" fontWeight="medium">
              {ele.balance_amount}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
  ))}
        {/* <Grid item xs={12} sm={6}>
          <Paper elevation={3} className="p-4">
            <Grid container spacing={2}>
              <Grid item>
                <AccountCircleIcon style={{ fontSize: "60px", color: "gray" }} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h6" fontWeight="bold">
                  Lavanya Singh
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2">Mobile Number</Typography>
                    <Typography variant="body2">124546647</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Address</Typography>
                    <Typography variant="body2">
                      123-A, Street Name, Rajendra Nagar, New Delhi
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Typography variant="body2">Loyalty Points</Typography>
                <Typography variant="h6" fontWeight="medium">
                  500
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
      </Grid>
    </Box>
    <MemberEnrollModal   show={memberEnroll}
        onHide={() => setMemberEnroll(false)}/>
    </>
  );
};

export default CRM;
