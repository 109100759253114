import { Box, TextField, Button, Typography, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import BarcodeIcon from '@mui/icons-material/ViewWeek'
import barcode from "../../src/assets/image 34.png";
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReceiptModal from '../Components/ReceiptModal';
import axios from 'axios';
import { BASE_Url } from '../URL.js/URL';
import { useReactToPrint } from 'react-to-print';
import BillReceiptsaasid12 from '../Components/ReceiptFor12/BillReceiptsaasid12';
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
export default function SelfOprate() {
    const naviagte=useNavigate()
    const [order, Setorder]=useState("")
    const { storeId, saasId, bankAccount } = JSON.parse(
      localStorage.getItem("STORE_DATA")
    );
  const dispatch = useDispatch();
  const [openCustomer, setOpenCustomer] = useState(false);
  const [PdfFile, setPDFFile] = useState("");
  const [tableId, setTableId] = useState("");
  const [tablecategory, setTableCategory] = useState("");
  const [orderaddress, setorderAddress] = useState("");
  const [tableNo, setTableNo] = useState("");
  const [orderType, setOrderType] = useState("");
  const [pay_method, setPayMethod] = useState("");
  const [products, setProducts] = useState([]);
  const [optionTickSum, setOptionTickSum] = useState(0);
  const [optionTick, setOptionTick] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState("");
  const [handleShowReceipt, setHandleShowReceipt] = useState(false);
  const [viewPdf, setViewPdf] = useState(false);
  const [qr, setQr] = useState("");
  const [open, setOpen] = useState(false);
  const [orderData, setData] = useState("");
  const [DeliveryCharges,setDeliveryCharges] = useState(0);
  const [OnlineCutomerNumber, setOnlineCustomerNumber] = useState("");
  const params = useParams();
    const [totalDiscount, setTotalDiscount] = useState(0);
      const customer = useSelector((state) => {
        return state.selectOrders.linkCustomerdata;
      });
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const printRef = useRef(null);
  const jsonString = localStorage.getItem("USER_DATA");
  const parsedData = JSON.parse(jsonString || '{}'); // Fallback to an empty object if null
  const { userType } = parsedData;

  const self =  userType =="SELFOPERATOR";
    const getData = () => {
      axios
        .get(`${BASE_Url}/order/view-order-details/${order}/false`)
        .then((res) => {
          if (res.status === 200) {
            console.log("SAVE BIll", res);
            const arr = res.data?.data?.item_detail_list;
            arr.map((el) => {
              el["discount"] = 0;
              // el["tax"] = 0;
              el["status"] = 0;
              // el["tax_rate"] = 0;
              el["hsn_code"] = 0;
              el["tax_code"] = 0;
              // el["item_qty"] = 0;
            });
            console.log(arr);
            setProducts(arr);
            setDeliveryCharges(res.data.data.delivery_Charges);
            setOnlineCustomerNumber(res.data.data.customer_number)
            setorderAddress(res.data.data.address);
            setTableCategory(res.data.data.tableCategory);
            setTableId(res.data.data.table_id);
            setOrderType(res.data.data.order_type);
            setPayMethod(res.data.data.payment_mode);
            setTableNo(res.data.data.table_number);
          }
          setViewPdf(true);
        }).catch((error) => {
          console.log(error);
        });
    };
    useEffect(() => {
      getData();
      console.log("object", optionTick);
    }, [order]);




    
      const componentRef = useRef();
      const focusClose = useRef();
      const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforePrint: () => {
          // Add any logic you need before printing
          console.log("printing Start");
        },
        onAfterPrint: () => {
          // Add any logic you need after printing
          setViewPdf(false);
          if (self) {
            naviagte("/SelfCheckout");
          } else {
            naviagte("/Tables");
          }
          setOptionTick("");
          setDeliveryCharges("");
          // dispatch(removeLinkCustomer())
          console.log("printing End");
        },
      });



      const header = {
        container: {
          fontFamily: "Courier New, monospace",
          fontSize: "small",
        },
        text: {
          fontFamily: "Courier New, monospace",
          fontSize: "small",
        },
      };
  return (
    <>
    <Box 
      sx={{ 
        maxWidth: 1200, 
        mx: 'auto', 
        p: 4,
        bgcolor: '#fff'
      }}
    >
      {/* Search Section */}
      <Box sx={{ display: 'flex', gap: 1, mb: 6 }}>
        <TextField
          fullWidth
          size="small"
          onChange={(e) => {
            Setorder(e.target.value);
          }}
          placeholder="Enter Barcode"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '4px',
              bgcolor: '#fff'
            }
          }}
        />
        <Button
       onClick={handlePrint}
          variant="contained"
          sx={{
            minWidth: '40px',
            px: 2,
            bgcolor: '#1a237e',
            '&:hover': {
              bgcolor: '#0d47a1'
            }
          }}
        >
          <SearchIcon />
        </Button>
      </Box>

      {/* Barcode Section */}
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          variant="h5"
          component="h1"
          sx={{
            mb: 4,
            fontWeight: 'normal',
            color: '#000'
          }}
        >
      Welcome To Self Chekout. Plz Scan Your Barcode Or Enter Code
        </Typography>

        <Paper
          elevation={0}
          sx={{
            width: 'fit-content',
            mx: 'auto',
            p: 3,
            border: '1px solid #e0e0e0',
            borderRadius: 1,
            bgcolor: '#fff'
          }}
        >
          {/* Barcode Icon Pattern */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            width: 800,
            height: 100,
          }}>
           
                <div className="d-flex align-items-center justify-content-center my-4">
                  <img
                    height="120px"
                    width="230px"
                    src={barcode}
                    alt="barcode"
                  />
                </div>
          </Box>
        </Paper>
      </Box>
    </Box>

    <Modal
              show={viewPdf}
              onHide={() => setViewPdf(false)}
              className="p-0"
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              {/* <Modal.Header className="container" closeButton>
                 <Modal.Title>Receipt Genrate</Modal.Title>
               </Modal.Header> */}
              <Box>
                <div
                  className="-mx-2 mt-1"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setViewPdf((state) => !state);
                    // dispatch(RemoveCart());
                    // navigate("/");
                  }}
                >
                  <AiOutlineClose size={20} />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="secondary"
                    style={{ background: "gray" }}
                    className=" text-white mt-10 mx-2 p-2"
                    onClick={() => {
                      setViewPdf(false);
                      // dispatch(RemoveCart());
                      // dispatch(HandelLinkCustomer([]));
                      // dispatch(RemoveInovice());
                      // navigate("/");
                    }}
                    ref={focusClose}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    style={{ background: "blue" }}
                    autoFocus
                    className=" text-white mt-10 p-2"
                    onClick={handlePrint}
                  >
                    PRINT
                  </Button>
                  {/* <button className="bg-dark-subtle mt-10 p-2" onClick={openCashDrawer}>
                 Open CashDrawer
                </button> */}
                </div>
                <div
                  className="container"
                  style={header.container}
                  ref={componentRef}
                >
                  {saasId == "12" ? (
                    <BillReceiptsaasid12
                      totalDiscount={totalDiscount}
                      customer={customer}
                      tableNo={tableNo}
                      invoiceNo={invoiceNo}
                      payment_mode={pay_method}
                      orderType={orderType}
                      optionTick={optionTick}
                      products={products}
                    />
                  ) : (
                    <ReceiptModal
                    DeliveryCharges={DeliveryCharges}
                    tablecategory={tablecategory}
                      orderaddress={orderaddress}
                      totalDiscount={totalDiscount}
                      customer={customer}
                      tableNo={tableNo}
                      invoiceNo={invoiceNo}
                      payment_mode={pay_method}
                      orderType={orderType}
                      optionTick={optionTick}
                      products={products}
                    />
                  )}
                </div>

                {/* <ModalFooter>
              
            </ModalFooter> */}
              </Box>
            </Modal>

    </>
  )
}

