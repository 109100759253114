import React, { useState } from 'react';
import { TextField, Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import Swal (SweetAlert2)
import { BASE_Url } from '../../URL.js/URL';

function AddSaas() {
  const [formData, setFormData] = useState({
    saasId: '',
    saasName: '',
    currency: 'Rupees',
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_Url}/saas-master/add-saas`, formData);

      // Show success alert using Swal
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'SaaS added successfully!',
        showConfirmButton: false,
        timer: 1500,
      });

      console.log('API response:', response.data);
      // Optionally, reset the form or redirect user
      setFormData({
        saasId: '',
        saasName: '',
        currency: '',
      });
    } catch (error) {
      // Show error alert using Swal
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add SaaS. Please try again.',
      });

      console.error('Error submitting the form:', error);
    }
  };

  return (
    <div>
      <Typography variant="h6" mb={2}>
        Add SaaS
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              label="SaaS ID"
              name="saasId"
              value={formData.saasId}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label="SaaS Name"
              name="saasName"
              value={formData.saasName}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>

          {/* <Grid item xs={12} md={4}>
            <TextField
              label="Currency"
              name="currency"
              value={formData.currency}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid> */}

          <Grid item xs={4}>
            <Button type="submit" variant="contained" className='mt-2' color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default AddSaas;
