import React, { useState, useEffect } from "react";
import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";
import { Web_Url } from "../URL.js/URL";

const NotificationComponent = ({ onNewNotification ,setNotifications,notifications}) => {
 
  const { storeId } = JSON.parse(localStorage.getItem("STORE_DATA"));

  useEffect(() => {
    const socket = new SockJS(`${Web_Url}`);
    const client = new Client({
      webSocketFactory: () => socket,
      reconnectDelay: 5000,
      debug: (str) => console.log(str),
      onConnect: () => {
        console.log("Connected to WebSocket");
      

        // Subscribe to store-specific topic
        // client.subscribe(`/topic/order/${storeId}`, (message) => {
        //   const notification = message.body;
        //   setNotifications((prev) => [...prev, notification]);
        //   onNewNotification(); 
        // });

        // Subscribe to master topic
        client.subscribe(`/topic/order/master/${storeId}`, (message) => {
          const notification = message.body;
          setNotifications((prev) => [...prev, notification]);
          onNewNotification(); // Notify parent of new notification
        });
      },
      onStompError: (frame) => {
        console.error("STOMP Error", frame.headers["message"], frame.body);
      },
    });

    client.activate();

    return () => {
      if (client) {
        client.deactivate();
        console.log("Disconnected from WebSocket");
      }
    };
  }, [storeId, onNewNotification]);

  return null; // No UI needed as notifications are handled in CustomNavbar
};

export default NotificationComponent;
