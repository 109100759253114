import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import chola from "../../images/chola.jpg";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { BsTrash3 } from "react-icons/bs";
import { Button } from "@mui/material";
import { FaMinus, FaPlus, FaRupeeSign, FaTrashAlt } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import Barcode from "react-barcode";
const Cart = ({
  show,
  setShow,
  cartData,
  setCartData,
  setCount,
  table_id,
  catgoryName,
  isPhoneNumberSaved
}) => {
  const userData = localStorage.getItem("USER_DATA") ?? "{}";
  const parsedData = JSON.parse(userData); // Parse the string into an object
  const { userId } = parsedData; // Destructure userId
  // const {bankAccount} = JSON.parse(localStorage.getItem('STORE_DATA'))
  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const [orderId, setOrderId] = useState(null);

  const handleClose = () => {
    setShow(false);
    setShowBarcodeModal(false); // Close the barcode modal as well
  };
  // const handleClose = () => {
  //   setShow(false);
  // };
 
  const updateCartInLocalStorage = (updatedCart) => {
    localStorage.setItem("my-cart", JSON.stringify(updatedCart));
  };

  const { saas_id, store_id } = useParams();

  const increment = (item) => {
    const q = item.item_qty + 1;
    item.item_qty = q;
    const newP = item.item_price * q;
    item.new_price = newP;

    cartData.map((item) => {
      item.item_newprice = item.item_price * item.item_qty;
    });
    setCartData([...cartData]);
    updateCartInLocalStorage(cartData);
  };

  //decriment function
  const decrement = (item) => {
    if (item.item_qty === 1) {
      item.item_qty = 1;
      item.item_price = item.item_price;
    } else {
      const q = item.item_qty - 1;
      item.item_qty = q;
      const newP = item.item_price * q;
      item.new_price = newP;
    }
    cartData.map((item) => {
      item.item_newprice = item.item_price * item.item_qty;
    });
    setCartData([...cartData]);
    updateCartInLocalStorage(cartData);
  };

  const   ConsfirmOrder = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    try {
    if (cartData.length > 0) {
      axios
        .post(`${BASE_Url}/order/create`, {
          order_date: currentDate,
          user_id:userId,
          saas_id: saas_id,
          table_id: table_id,
          store_id: store_id,
          status: "preparing",
          order_type: "Dine in",
          order_details: cartData,
          add_customer: [],
          approval_status: "Accept",
        })
        .then((res) => {
          console.log("SAVE CREATE ORDER", res);
          if (res.data.status) {
            console.log("this is my order", res.data.data);
            localStorage.setItem("my-order", JSON.stringify(res.data.data));
            localStorage.removeItem("my-cart");
            Swal.fire("Good job", `Your Order is confirmed `, "success");
            setCartData([]);
            setCount(0);
            handleClose();
          }else{
            Swal.fire({
              icon: "error",
              title: res?.data?.message.toUpperCase(),
              text:"Please ask Waiter for confirm order ",
              showConfirmButton: false,
              timer: 1500,
            })
          }
        });
    } else {
      Swal.fire("Not Allow", `please add some item in cart `, "error");
    }
  } catch (error) {
     console.log(error) 
     Swal.fire("Error", "Something went wrong", "error");
  }
  };

  //Check Store Type
  const jsonStoreString = localStorage.getItem("Store_data");
  const parsedstoreData = JSON.parse(jsonStoreString || "{}"); // Fallback to an empty object if null
  const { qsr } = parsedstoreData;
  const RType = qsr;


  const confirmOrder = () => {
    if (!window.Razorpay) {
      Swal.fire("Error", "Payment SDK is not loaded. Please try again.", "error");
      return;
    }
    const currentDate = new Date().toISOString().split("T")[0];
    if (cartData.length > 0) {
      const options = {
        key: "rzp_test_USk6kNFvt2WXOE", // Replace with your Razorpay API key
        amount: calculateTotal * 100, // Amount in paise
        currency: "INR",
        name: "Your Store Name",
        description: "Order Payment",
        image: "/path/to/logo.png", // Optional
        handler: (response) => {
          // On successful payment
          axios
            .post(`${BASE_Url}/order/create`, {
              order_date: currentDate,
              user_id: userId,
              saas_id: saas_id,
              table_id:RType? null:table_id,
              table_name:RType? "QSR":"",
              store_id: store_id,
              status: "preparing",
              order_type: RType?"QSR":"Dine in",
              order_details: cartData,
              add_customer: [],
              approval_status: "Accept",
              payment_id: response.razorpay_payment_id,
            })
            .then((res) => {
              if (res.data.status) {
                localStorage.setItem("my-order", JSON.stringify(res.data.data));
                handleSaveTransaction(false, "Dine",res.data.data.order_id )
                localStorage.removeItem("my-cart");
                Swal.fire("Good job", "Your Order is confirmed", "success");
                setCartData([]);
                setCount(0);
                setOrderId(res.data.data.order_id); // Get the order_id from the response
                setShowBarcodeModal(true);
              
              } else {
                Swal.fire({
                  icon: "error",
                  title: res?.data?.message.toUpperCase(),
                  text: "Please ask Waiter for confirm order",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            });
        },
        prefill: {
          name: parsedData.name,
          email: parsedData.email,
          contact: parsedData.phone,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      Swal.fire("Not Allow", "Please add some items to the cart", "error");
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleDeleteCartItem = (item) => {
    const getData = JSON.parse(localStorage.getItem("my-cart"));
    console.log(getData);
    if (getData) {
      if (getData?.length > 0) {
        if (getData?.length > 1) {
          const updateCart = getData.filter((el) =>
            el.item_id
              ? el.item_id !== item.item_id
              : el.productId !== item.productId
          );

          localStorage.setItem("my-cart", JSON.stringify(updateCart));
          setCartData(updateCart);
          // dispatch(handlecartCount(updateCart?.length));
        } else {
          localStorage.removeItem("my-cart");
          setCartData([]);
          // dispatch(handlecartCount(0));
        }
      }
    }
  };
  const calculateTotal =  cartData && cartData?.reduce((total, item) => total + item.item_price * item.item_qty, 0)
  
 
  const handleDownloadBarcode = () => {
    const svgElement = document.getElementById("barcode");
    
    if (!svgElement) {
      console.error("Barcode element not found");
      return;
    }
  
    // Serialize the SVG element
    const svgData = new XMLSerializer().serializeToString(svgElement);
  
    // Convert SVG to base64
    const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    const svgUrl = URL.createObjectURL(svgBlob);
  
    // Create a temporary link element
    const downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = "barcode.svg";
    
    // Trigger download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  
    // Revoke the object URL after download
    URL.revokeObjectURL(svgUrl);
  };
  

  // nano invoice
  const handleSaveTransaction = async (isDigital,isWhatsapp,orderId) => {

    const { storeId, saasId } = JSON.parse(localStorage.getItem("Store_data"));
    // const { registerId ,userName } = JSON.parse(localStorage.getItem("USER_DATA"));
    const formatDateTime = (date) => {
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
    };

    const currentDateTime = formatDateTime(new Date());
    // console.log(handleTenderAmount());

   
      try {
        const res = await axios.post(`${BASE_Url}/transaction/nano-invoice`, {
          register_id: null,
          delivery_Charges:null,
          store_id: storeId,
          saas_id: saasId,
          discount: "",
          customer_id: null,
          table_id: RType? null:table_id,
          order_id: orderId,
          cashier_id: null,
          trans_start_time: currentDateTime,
          trans_end_time: currentDateTime,
          tender:{Online:calculateTotal?.toFixed(2)},
          cart_items: cartData,
          customerName:"ALi",
          customerNumber: isPhoneNumberSaved,
        });

        if (res.status === 200) {
      
       
     
          
        }
      } catch (error) {
    
        console.error("Transaction saving error", error);
      }
    
    }
  
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>My Basket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {cartData?.length> 0 ? <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow">
          {cartData.map((item) => (
          <div key={item.id} className="mb-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <img
                src={`${BASE_Url}/item/get-image/${item.item_id}`}
                // alt={item.name}
                className="w-10 h-10 rounded-full mr-3"
              />
              <span>{item.item_name}</span>
            </div>
            <span className="font-semibold">&#8377;{item?.item_newprice ? item?.item_newprice : item?.item_price}</span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center border rounded-md">
              <button
                onClick={() => decrement(item)}
                className="p-2 text-gray-600 hover:text-gray-800"
              >
                <FaMinus className="w-4 h-4" />
              </button>
              <span className="px-4 py-2 text-center w-12"> 
              {item.item_qty}
              </span>
              <button
                onClick={() => increment(item)}
                className="p-2 text-gray-600 hover:text-gray-800"
              >
                <FaPlus className="w-4 h-4" />
              </button>
            </div>
            <button onClick={() => handleDeleteCartItem(item)} className="text-red-500 hover:text-red-700">
              <FaTrashAlt className="w-5 h-5" />
            </button>
          </div>
        </div>
    ))}
  
      <div className="border-t pt-4">
        <h3 className="font-semibold mb-2">Order Summary</h3>
        <div className="flex justify-between mb-2">
          <span>Subtotal</span>
          <span>Rs {calculateTotal?.toFixed(2)}</span>
        </div>
        <div className="flex justify-between font-semibold">
          <span>Amount to pay</span>
          <span>Rs {calculateTotal?.toFixed(2)}</span>
        </div>
      </div>
      <button  onClick={RType?confirmOrder:ConsfirmOrder} className="w-full bg-green-500 text-white py-3 rounded-md mt-6 hover:bg-green-600 transition-colors">
        Confirm Order
      </button>
      </div>:<>
      <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow text-center">
        <FaCartShopping className="w-16 h-16 mx-auto text-gray-400 mb-4" />
        <h2 className="text-xl font-semibold mb-2">Your cart is empty</h2>
        <p className="text-gray-600 mb-4">Add some items to your cart to get started!</p>
        <button onClick={handleClose} className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition-colors">
          Browse Menu
        </button>
      </div>
      </>}
       
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-around w-100">
            <Button
              className="bg-secondary text-white"
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              className="text-nowrap"
              type="button"
              style={{
                backgroundColor: "rgb(169, 10, 10)",
                border: "none",
                color: "white",
                fontWeight: "bold",
              }}
              // id="pop112"
              onClick={() => {
                localStorage.removeItem("my-cart");
                setCartData([]);
                handleClose();
              }}
            >
              Remove All
            </Button>
         
          </div>
        </Modal.Footer>
      </Modal>

        {/* Barcode Modal */}
        <Modal show={showBarcodeModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Barcode</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="flex justify-center">
          <Barcode id="barcode" width={2} value={orderId} />
          </div>
          <Button
            variant="contained"
            color="primary"
            className="mt-4"
            onClick={handleDownloadBarcode}
          >
            Download Barcode
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>



  );
};

export default Cart;
