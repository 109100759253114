import React, { useEffect, useState } from 'react'
// import Modal from 'react-modal';
import Box from "@mui/material/Box";
import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from 'react-icons/ai';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import { Container } from 'reactstrap';
import { Grid, MenuItem, TextField } from '@mui/material';
const Itemupdate = ({addUpdateItemModalIsOpen,setAddUpdateItemModalIsOpen,row,categories}) => {
    console.log("this is modal",row)
    const toggle = () => setAddUpdateItemModalIsOpen(!addUpdateItemModalIsOpen);
    const [Itemname, setItemname]= useState('')

    const [localItemName, setlocalItemName]= useState('')
    const [price, setPrice]= useState('')
    const [tax, setTax]= useState(0)
    const [image, setImage]= useState('')
    const [selectedCategory, setSelectedCategory] = useState('');
    const style = {
      position: "absolute",
      // display: "flex",
      // alignItems: "center",
      // justifyContent: "center",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 800,
      height: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };
    const VisuallyHiddenInput = styled('input')({
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
      bottom: 0,
      left: 0,
      whiteSpace: 'nowrap',
      width: 1,
    });

    const body = {
      item_name : Itemname , 
      localItemName:localItemName,
       price : price ,
       discount: 0,
       taxRate: tax,
       status: "active",
       category_details: [{ category_id: selectedCategory }]
    }

    const handelUpdate = async(e)=>{ 
      e.preventDefault()
      try {
        const data = await axios.put(`${BASE_Url}/item/update-item/${row.item_id}`,body)
        // console.log("this is iteam id",row.item_id)
        if(image){
          const formData = new FormData();
          formData.append("file", image);
          const data = await axios.post(`${BASE_Url}/item/save-image/${row.item_id}`,formData);
        }

        setAddUpdateItemModalIsOpen(false)
        setImage("")
      } catch (error) {
        console.log("error accure",error)
        
      }

    }
    useEffect(() => {
      if(row){
      setItemname(row?.item_name)
      setlocalItemName(row?.localItemName)
      setPrice(row?.item_price)
      setTax(row?.tax_rate)
      setSelectedCategory(row?.category_id)
      }
    }, [row])
    
  return (
    <Modal
    show={addUpdateItemModalIsOpen}

    onHide={() =>   setAddUpdateItemModalIsOpen(false)}
    // fullscreen={true}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    
    <Modal.Header className="container" closeButton>
                  <Modal.Title>Item Update</Modal.Title>
                </Modal.Header>
                <Container>
   
      <div
        style={{
          width: "100%",
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "20px",
          cursor: "pointer",
          position: "relative",
        }}
    
      >
       

       <form className='my-3' style={{ width: "100%" }} onSubmit={handelUpdate}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Item Name"
            variant="outlined"
            value={Itemname}
            onChange={(e) => setItemname(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Item Arabic Name"
            variant="outlined"
            value={localItemName}
            onChange={(e) => setlocalItemName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Price"
            variant="outlined"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="TAX"
            variant="outlined"
            value={tax}
            onChange={(e) => setTax(e.target.value)}
          />
        </Grid>
           {/* Dropdown for categories */}
    <Grid item xs={12} sm={6}>
      <TextField
        select
        required
        fullWidth
        label="Category"
        variant="outlined"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        {categories.map((category) => (
          <MenuItem key={category.id} value={category.category_id}>
            {category.category_name}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            component="label"
            fullWidth
            // style={{ marginTop: '1rem' }}
          >
            Upload Image
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                console.log("evfdcvfvfvc", e.target.files[0].name);
                setImage(e.target.files[0]);
              }}
            />
          </Button>
        </Grid>

 
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            style={{ background: "#0E2954", color: "white" }}
            type="submit"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
      </div>
      </Container>
  
  </Modal>
  )
}

export default Itemupdate