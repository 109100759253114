import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid, Button } from '@mui/material';
import { BsArrowLeft } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { BASE_Url } from "../../URL.js/URL";
import DownloadComponent from "../CSVDownload/DownloadComponent";
import DownloadPDFButton from "../CSVDownload/DownloadPDFButton";
import { useReactToPrint } from "react-to-print";
const DailyStoreReports = ({formDate,toDate}) => {
  const [allData, setAllData] = useState([]);
  const [total_value, setTotal_value] = useState(0);
  const navigate = useNavigate();
  // const [selectedOption, setSelectedOption] = useState(new Date().toISOString().split('T')[0]); 

  // const handleSelectChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  const {storeId, saasId}= JSON.parse(localStorage.getItem('STORE_DATA'));
  useEffect(() => {
    axios.get(`${BASE_Url}/user-master/overall_performance/${saasId}/${storeId}/${formDate}/${toDate}`)
      .then((res) => {
        setAllData(res?.data?.data || []);
        setTotal_value(res.data?.total_value)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [formDate,toDate]);

  const header=[
    { label: "Date", key: "date" },
    { label: "Store Id", key: "store_id" },
    { label: "Total Invoice", key: "no_invoice" },
    { label: "Total Qty", key: "qty" },
    { label: "Total Net Sale", key: "net_profit" },
    { label: "Total Discount", key: "discount" },
    { label: "Total Tax", key: "tax" },
    { label: "Store Id", key: "store_id" },
]
   //new update code
   const tableRef = useRef(null); // Ref for the table container

   const handleTablePrint = useReactToPrint({
     content: () => tableRef.current,
     documentTitle: 'Sales_Report',
     onBeforePrint: () => console.log("Printing Sales Table..."),
   });
  return (
    <>
        <div  >
          <DownloadComponent headers={header} data={allData} fileName="DailySales_Report"/>
          <Button
              variant="contained"
              className="mx-2"
              style={{background:"rgb(14, 41, 84)",color:"white"}}
              onClick={handleTablePrint}
            >
              PRINT 
            </Button>
               {/* Reusable PDF Download Button */}
               <DownloadPDFButton
              data={allData}
              headers={header}
              fileName="DailySales_Report.pdf"
            />
          {total_value > 0 &&<div className="flex justify-between">
            <p className="text-xl font-bold">
              Total Sales: {total_value}
            </p>
          </div>}
        <div className="flex flex-wrap"
  style={{  height: "66vh", marginBottom: "40%", overflowY: "auto", overflowX:"auto" }}
    >
      <Box ref={tableRef} className="h-100 w-100 rounded-lg">
        <TableContainer component={Paper}>
          <Table className="text-nowrap" aria-label="simple table">
            <TableHead className="bg-dark-purple">
              <TableRow>
                <TableCell className=" text-white">Date</TableCell>
                <TableCell className=" text-white">Total Qty</TableCell>
               <TableCell className=" text-white">Total No of Invoice</TableCell>
               <TableCell className=" text-white">Total Net Sale</TableCell>
               <TableCell className=" text-white">Total Discount</TableCell>
               <TableCell className=" text-white">Total Tax</TableCell>
                <TableCell className=" text-white">Store Id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allData?.map((ele) => (
                <TableRow key={ele?.date}>
                  <TableCell>{ele?.date}</TableCell>
                  <TableCell>{ele?.qty}</TableCell>
                  <TableCell>{ele?.no_invoice}</TableCell>
                  <TableCell>{ele?.net_profit}</TableCell>
                  <TableCell>{ele?.discount}</TableCell>
                  <TableCell>{ele?.tax}</TableCell>
                  <TableCell>{ele?.store_id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      </div>   </div>
    </>
  );
}

export default DailyStoreReports;
