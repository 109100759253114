import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";



const ProtectedRoute = ({ index, page }) => {
    const { permission, isLoding, role } = useAuth();
    const isAuthenticated = JSON.parse(localStorage.getItem("Token"));
    const { userType } = JSON.parse(localStorage.getItem("USER_DATA")) || {};
  
    // Check permission function
    const checkPermission = (index, page) => {
      return (
        userType === "ADMIN" ||
        (userType === "CASHIER" &&
          permission[index]?.name === page &&
          permission[index]?.status === true)
      );
    };
  
    // Render logic
    if (isLoding) {
      return <div>Loading...</div>; // Optional: Add a spinner or loader
    }
  
    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }
  
    if (checkPermission(index, page)) {
      return <Outlet />;
    }
  
    return <Navigate to="/ErrorRestricted" />;
  };
  
  export default ProtectedRoute;
  
