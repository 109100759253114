import React, { useState } from "react";
import { Tabs, Tab, Box, Typography, Button, TextField, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import AddSaas from "./AddSaas";
import AddUser from "./AddUser";
import GetAllStores from "./GetAllStores";
import GetAllUser from "./GetAllUser";
import GetDeliveryBoy from "./GetDeliveryBoy";

function OpenSetting() {
  const [activeTab, setActiveTab] = useState("SaasMaster");
  const [formData, setFormData] = useState({
    party_name: "",
    payment_type: "",
    payment_date: new Date(),
    payment_mode: "",
    amount: "",
    payment_notes: "",
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, party_name: selectedOption.value });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form Submitted:", formData);
  };

  return (
    <Box
    style={{
      Height: "calc(100vh - 180px)",
      overflowY: "auto",
    }}
      sx={{
        background: "#FFF",
        boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        p: 2,
        borderRadius: "8px",
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab value="SaasMaster" label="Saas Master" />
        <Tab value="StoreMaster" label="Store Master" />
        <Tab value="UserMaster" label="User Master" />
        <Tab value="DeliveryBoy" label="Delivery Boy" />
      </Tabs>

      <Box mt={2}>
        {activeTab === "SaasMaster" && (
     <AddSaas />
        )}

        {activeTab === "StoreMaster" && 
          // <AddStore />
          <GetAllStores />
          }
        {activeTab === "UserMaster" &&   
        // <AddUser />
        <GetAllUser />
        }
        {activeTab === "DeliveryBoy" &&   
        <GetDeliveryBoy/>
        }
      </Box>
    </Box>
  );
}

export default OpenSetting;
