import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_Url } from "../URL.js/URL";
import axios from "axios";

// Create AuthContext
const AuthContext = createContext();

// Custom hook for consuming AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider component
export const AuthProvider = ({ children }) => {
    const userData = JSON.parse(localStorage.getItem("USER_DATA"));
    const userId = userData ? userData.userId : null;
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const isAuthenticated = JSON.parse(localStorage.getItem("Token"));
  const [role, setRole] = useState();
  const [permission, setPermission] = useState([]);
  const [isLoding, setIsLoading] = useState(true);
  const Getpermission = async () => {
    setIsLoading(true); // Set loading to true before fetching
    try {
      const response = await axios.get(`${BASE_Url}/page-permission/getpage-permission-user/${userId}`);
      if (response.data.status) {
        setPermission(response.data.data.permission);
        setRole(response.data.data.userRole);
      } else {
        logout();
      }
    } catch (error) {
      console.error('Failed to fetch permission', error);
      setPermission([]);
      logout();
    } finally {
      setIsLoading(false); // Ensure loading is set to false after completion
    }
  };
  
  useEffect(() => {
    if (isAuthenticated) {
      Getpermission();
    } else {
      setPermission([]);
      setIsLoading(false);
    }
  }, [isAuthenticated]);
  
  // Login function
  const login = (username, password) => {
    // Simulated login logic
    if (username === "admin" && password === "password") {
      setUser({ username });
      navigate("/dashboard");
    } else {
      alert("Invalid credentials");
    }
  };

  // Logout function
  const logout = () => {
    setUser(null);
    navigate("/login");
  };

  const value = {
    user,
    login,
    logout,
    role,
    Getpermission,
    isLoding,
    permission,
    isAuthenticated: !!user,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
