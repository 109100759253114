import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";

const EditUserModal = ({ show, handleClose, userData }) => {
  console.log("UserData ", userData);
  const [formData, setFormData] = useState(userData);

  useEffect(() => {
    setFormData(userData);
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
  try {
    const response = await axios.put(`${BASE_Url}/user-master/update-user/${userData.user_id}`, formData);
    console.log(response.data);
    if(response.data.status){
        Swal.fire({
            title:'User Updated Successful',
            icon:"success"
        })
        handleClose();
    }
    
  } catch (error) {
    console.log(error)
  }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formUserName">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              type="text"
              name="user_name"
              value={formData?.user_name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formData?.password}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formStoreName">
            <Form.Label>Store Name</Form.Label>
            <Form.Control
              type="text"
              name="store_name"
              value={formData?.store_name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formUserType">
            <Form.Label>User Type</Form.Label>
            <Form.Control
              as="select"
              name="user_type"
              value={formData?.user_type}
              onChange={handleChange}
            >
              <option value="">Select User Type</option>
              <option value="ADMIN">Admin</option>
              <option value="CASHIER">Cashier</option>
              <option value="CAPTAIN">Captain</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formStoreId">
            <Form.Label>Store ID</Form.Label>
            <Form.Control
              type="text"
              name="store_id"
              value={formData?.store_id}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formSaasId">
            <Form.Label>SaaS ID</Form.Label>
            <Form.Control
              type="text"
              name="saas_id"
              value={formData?.saas_id}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formRegisterId">
            <Form.Label>Register ID</Form.Label>
            <Form.Control
              type="text"
              name="register_id"
              value={formData?.register_id}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formData?.city}
              onChange={handleChange}
            />
          </Form.Group>
          {/* <Form.Group controlId="formBaarCode">
            <Form.Label>Baar Code</Form.Label>
            <Form.Control
              type="text"
              name="baar_code"
              value={formData?.baar_code}
              onChange={handleChange}
            />
          </Form.Group> */}
          {/* <Form.Group controlId="formCategoryReq">
            <Form.Label>Category Req</Form.Label>
            <Form.Control
              type="text"
              name="category_req"
              value={formData?.category_req}
              onChange={handleChange}
            />
          </Form.Group> */}
          <Form.Group controlId="formState">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              name="state"
              value={formData?.state}
              onChange={handleChange}
            />
          </Form.Group>
          {/* <Form.Group controlId="formSpecialPrice">
            <Form.Label>Special Price</Form.Label>
            <Form.Control
              type="text"
              name="special_price"
              value={formData?.special_price}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formCategoryName">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              name="category_name"
              value={formData?.category_name}
              onChange={handleChange}
            />
          </Form.Group> */}
          {/* <Form.Group controlId="formBaseCurrency">
            <Form.Label>Base Currency</Form.Label>
            <Form.Control
              type="text"
              name="base_currency"
              value={formData?.base_currency}
              onChange={handleChange}
            />
          </Form.Group> */}
          <Form.Group controlId="formCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              name="country"
              value={formData?.country}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="flex gap-3" controlId="formStatus">
            <Form.Label>Status</Form.Label>
            <Form.Check
              type="checkbox"
              name="status"
              checked={formData?.status}
              onChange={(e) =>
                setFormData({ ...formData, status: e.target.checked })
              }
            />
          </Form.Group>
          {/* <Form.Group controlId="formCreatedAt">
            <Form.Label>Created At</Form.Label>
            <Form.Control
              type="datetime-local"
              name="created_at"
              value={formData?.created_at}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formExpiration">
            <Form.Label>Expiration</Form.Label>
            <Form.Control
              type="date"
              name="expiration"
              value={formData?.expiration}
              onChange={handleChange}
            />
          </Form.Group> */}
          <Button variant="secondary" type="button" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditUserModal;
