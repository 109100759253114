import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BASE_Url, LOYALTY_URL } from "../URL.js/URL";
// import { BsArrowLeft, BsSearch } from "react-icons/bs";
import Box from "@mui/material/Box";

import Checkbox from "@mui/joy/Checkbox";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { AiOutlineClose } from "react-icons/ai";
import Swal from "sweetalert2";
import { Button, FormControlLabel, Grid, Input } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Container, ModalFooter } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import ReceiptModal from "./ReceiptModal";
import { useReactToPrint } from "react-to-print";
import LinkLoyaltyModal from "./LoylityEnroll/LinkLoyaltyModal";
import MemberEnrollModal from "./LoylityEnroll/MemberEnrollModal";
import LinkCustomerModal from "./LoylityEnroll/LinkCustomerModal";
import { useDispatch, useSelector } from "react-redux";
import LinkCustome from "./LoylityEnroll/LinkCustome";
import { Label } from "@mui/icons-material";
import TableToPrint from "./TableToPrint";
import Receipteforsaasid12 from "./ReceiptFor12/Receipteforsaasid12";
import BillReceiptsaasid12 from "./ReceiptFor12/BillReceiptsaasid12";
import { removeLinkCustomer } from "../redux/slices/selectOrdersSlice";
const FinishOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCustomer, setOpenCustomer] = useState(false);
  const [PdfFile, setPDFFile] = useState("");
  const [tableId, setTableId] = useState("");
  const [tablecategory, setTableCategory] = useState("");
  const [orderaddress, setorderAddress] = useState("");
  const [tableNo, setTableNo] = useState("");
  const [orderType, setOrderType] = useState("");
  const [pay_method, setPayMethod] = useState("");
  const [products, setProducts] = useState([]);
  const [optionTickSum, setOptionTickSum] = useState(0);
  const [optionTick, setOptionTick] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState("");
  const [handleShowReceipt, setHandleShowReceipt] = useState(false);
  const [viewPdf, setViewPdf] = useState(false);
  const [qr, setQr] = useState("");
  const [open, setOpen] = useState(false);
  const [orderData, setData] = useState("");
  const [DeliveryCharges,setDeliveryCharges] = useState("");
  const [OnlineCutomerNumber, setOnlineCustomerNumber] = useState("");
  const [OnlineCustomerData, setOnlincustomerData] = useState({
    mobile_number:""
  })
  const params = useParams();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const printRef = useRef(null);
  // console.log("selecteditem", orderData);
  const style = {
    position: "absolute",
    top: "43%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "85%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const loylity = useSelector((state) => {
    return state.selectOrders.loylityCustomer;
  });
  const customer = useSelector((state) => {
    return state.selectOrders.linkCustomerdata;
  });
  const { storeId, saasId, bankAccount } = JSON.parse(
    localStorage.getItem("STORE_DATA")
  );
  const [discountType, setDiscountType] = useState(null);
  const [discountValue, setDiscountValue] = useState("");
  const [formData, setFormData] = useState({
    discountAmount: "",
    discountPercentage: "",
    netInvoiceAmount: "",
    gstAmount: "",
  });
  //Check Store Type
  const jsonStoreString = localStorage.getItem("STORE_DATA");
  const parsedstoreData = JSON.parse(jsonStoreString || "{}"); // Fallback to an empty object if null
  const { qsr ,exclusiveTax} = parsedstoreData;
  const RType = qsr;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDiscountTypeChange = (type) => {
    setDiscountType(type);
    setDiscountValue("");
  };
  const handleDiscountValueChange = (e) => {
    const value = e.target.value;
    if (discountType === "percentage") {
      const numValue = parseInt(value);
      if (!isNaN(numValue) && numValue >= 1 && numValue <= 100) {
        setDiscountValue(value);
      }
    } else {
      setDiscountValue(value);
    }
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${BASE_Url}/order/view-order-details/${params.order_id}/true`);
      if (res.status === 200) {
        console.log("SAVE BIll", res);
        const arr = res.data?.data?.item_detail_list.map((el) => ({
          ...el,
          discount: 0,
          status: 0,
          hsn_code: 0,
          tax_code: 0,
        }));
        console.log(arr);
        console.log("get discount",res.data)
       
        setProducts(arr);
        setDeliveryCharges(res.data.data.delivery_Charges);
        setOnlineCustomerNumber(res.data.data.customer_number);
        setOnlincustomerData((prevData) => ({
          ...prevData,
          mobile_number: res.data.data.customer_number,
        }));
        setorderAddress(res.data.data.address);
        setTableCategory(res.data.data.tableCategory);
        setTableId(res.data.data.table_id);
        setOrderType(res.data.data.order_type);
        setPayMethod(res.data.data.payment_mode);
        setTableNo(res.data.data.table_number);
        if(res.data.data.flat_discount > 0){
          setDiscountValue(res.data.data.flat_discount)
          setDiscountType("percentage")
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
    console.log("object", optionTick);
  }, [params.order_id]);
  useEffect(() => {
    if(discountValue){
      handleApplyDiscount( discountType, discountValue);
    }
  }, [discountValue]);

  function incres(item) {
    // console.log("ggg",data)

    const updatedItems = [...products];

    const getIndex = updatedItems.findIndex(
      (el) => el.item_id === item.item_id
    );

    if (getIndex !== -1) {
      updatedItems[getIndex] = {
        ...updatedItems[getIndex],
        item_qty: updatedItems[getIndex].item_qty + 1,
      };

      setProducts(updatedItems);
    }
  }

  const calculation = (item) => item.item_price * item.item_qty ;
  
  const totalamount = products.reduce(
    (sum, item) =>
      sum +
      item.item_price * item.item_qty +
      (exclusiveTax > 0 ? item.tax : 0),
    0
  ) + DeliveryCharges;
  console.log("tak on item",products[0]?.tax)
  const totalamountselected = optionTick?.reduce(
    (acc, item) => acc + (parseFloat(item.amount) || 0),
    0
  );

  // const dueblance=totalamount -totalamountselected
  // const [itemamount, setItemamount]=useState(dueblance)

  // useEffect(() => {
  //   setItemamount(dueblance)
  // }, [dueblance])

  // // Function to decrease item quantity
  function decres(data) {
    const updatedItems = [...products];
    const getIndex = updatedItems.findIndex(
      (el) => el.item_id === data.item_id
    );

    if (getIndex !== -1) {
      if (updatedItems[getIndex].item_qty > 1) {
        updatedItems[getIndex] = {
          ...updatedItems[getIndex],
          item_qty: updatedItems[getIndex].item_qty - 1,
        };
        setProducts(updatedItems);
      }
    }
  }

  const handleTenderAmount = () => {
    // console.log("this is option", optionTick);
    if (optionTick?.length > 0) {
      const obj = {};
      optionTick.map((item) => {
        obj[item.name] = item.amount;
      });
      return obj;
      // setSendValues(obj)
    }
    return {};
  };

  const LoyaltiyPayload = () => {
    const Itemdata = products;
    const EditedItem = Itemdata.map((item) =>
      item.discount > 0
        ? {
            ...item,
            product_name: item.name,
            product_quantity: item.productQty,
            product_amount: item.actual_price,
            product_non_sale_amount: "",
            product_sale_amount: item.newPrice,
            product_discount_amount: item.discount,
            qr_sale_flag: true,
          }
        : {
            ...item,
            product_name: item.name,
            product_quantity: item.productQty,
            product_amount: item.actual_price,
            product_non_sale_amount: item.newPrice,
            product_sale_amount: "",
            product_discount_amount: item.discount,
            qr_sale_flag: false,
          }
    );
    return EditedItem;
  };
  const tenderforissue = () => {
    if (optionTick.length > 0) {
      const transformedArray = optionTick.map((item) => ({
        tender_name: item.name,
        tender_value: item.amount,
      }));
      return transformedArray;
    }
    return {};
  };

  //Loyality Issue Api Initigrate
  const HandleLoyaltyIssues = async (invoice_no, saasId, LoyaltyId) => {
    try {
      const EditedData = LoyaltiyPayload();
      console.log(EditedData);
      const currentDate = new Date().toISOString().split("T")[0];
      const data = {
        customer_id: loylity?.customer_id,
        source_channel: "Online",
        cost_centre: "Marketing",
        register_id: "R789",
        total_invoice_amount: totalamount - totalDiscount,
        store_id: "S987",
        business_date: currentDate,
        invoice_no: invoice_no,
        source_app: "WebApp",
        concept_code: "1",
        reference_number: "REF789",
        source_function: "Sales",
        territory_code: "INR",
        transaction_type: "Purchase",
        remarks: "Special notes",
        client_id: loylity?.client_id,
        product: EditedData,
        redeemed_point: 100.0,
        redeemed_concept: "Discount",
        base_currency: "INR",
        status: "Completed",
        country: "INDIA",
        tender: tenderforissue(),
      };

      const response = await axios.post(
        `${LOYALTY_URL}/loyalty/issue/${saasId}/${LoyaltyId}`,
        data
      );

      if (response.data.status) {
        if (optionTick.some((el) => el.value === "loyalty")) {
          const redeemAmountObj = optionTick.find(
            (el) => el.value === "loyalty"
          );
          console.log("redeem amount loyalty", redeemAmountObj.amount);

          const LoyaltyData = {
            redeem_amount: redeemAmountObj?.amount,
            business_date: currentDate, // should be a variable if dynamic
            invoice_number: invoice_no,
            remarks: "",
          };
          const response = await axios.post(
            `${LOYALTY_URL}/loyalty/redeem-points/${LoyaltyId}`,
            LoyaltyData
          );
        }
      }
    } catch (error) {
      console.log("this loyalty issues error", error);
    }
  };

  useEffect(() => {
    // console.log("optionTick", optionTick);
    let sum = 0;
    if (optionTick && optionTick?.length > 0) {
      optionTick.map((item) => {
        sum = sum + Number(item.amount);
      });
    } else {
      sum = 0;
    }
    setOptionTickSum(sum);
  }, [optionTick]);

  const [isloding, setIsLoading] = useState(false);
  const Whatsappmessage =async (customermobaile,filename)=>{

    const data ={
      to:customermobaile,
      saas_id:saasId,
      store_id:storeId,
      url:filename
  }
    try {
      const Response = await axios.post(`${BASE_Url}/whatsapk/multy-bill-msg`,data)
    } catch (error) {
      console.log(error)
    }
  }



  const handleSaveTransaction = async (isDigital,isWhatsapp) => {
    setIsLoading(true);
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const { registerId ,userName } = JSON.parse(localStorage.getItem("USER_DATA"));
    const formatDateTime = (date) => {
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
    };

    const currentDateTime = formatDateTime(new Date());
    console.log(handleTenderAmount());

    if (optionTick.length) {
      try {
        const res = await axios.post(`${BASE_Url}/transaction/nano-invoice`, {
          register_id: registerId,
          delivery_Charges:DeliveryCharges,
          store_id: storeId,
          saas_id: saasId,
          discount: totalDiscount,
          customer_id: customer?.customer_id,
          table_id: tableId,
          order_id: params?.order_id,
          cashier_id: userName,
          trans_start_time: currentDateTime,
          trans_end_time: currentDateTime,
          tender: handleTenderAmount(),
          cart_items: products,
          customerName: customer?.name,
          customerNumber: customer?.mobile_number,
        });

        if (res.status === 200) {
          console.log("object", loylity);

          if (loylity?.loyalty_id) {
            await HandleLoyaltyIssues(
              res.data.data.transaction_id,
              saasId,
              loylity?.loyalty_id
            );
          }

          console.log("SAVE TRANSACTION RESPONSE", res);
          setInvoiceNo(res.data.data.transaction_id);
          setHandleShowReceipt((state) => !state);
          setPDFFile(res.data.data.pdf_file_name);
          setQr(res.data.data.qr_file_name);
          if (isDigital) {
            if (RType) {
              navigate("/Orders");
            } else {
              navigate("/Tables");
            }
            if (isWhatsapp === 'whatsapp') {
              const mobileNumber = customer?.mobile_number || OnlineCutomerNumber;
              if (mobileNumber) {
              Whatsappmessage(mobileNumber, res.data.data.pdf_file_name);
              }
            }
            setOptionTick("");
            setDeliveryCharges("");
            dispatch(removeLinkCustomer())
          }else{
            setViewPdf(true);
            setTimeout(() => {
              handlePrint();
            }, 1000);
          }
          setIsLoading(false);
          
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Transaction saving error", error);
      }
    } else {
      setIsLoading(true);
      Swal.fire("Select Tender Value");
    }
  };

  //HAndel Mobaile View
  const [Toggle, setToggle] = useState(true);
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // console.log('ths is mibile dievice')
      // isMobile.current = true;
      setToggle(false);
    }
    // const toggleBtn = document.getElementById('toggleSidebar');
    // toggleBtn.addEventListener('click', handleToggle);
  }, []);
  // const [optionTick, setOptionTick] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const totalAmountSelected = optionTick.reduce(
    (sum, item) => sum + (parseFloat(item.amount) || 0),
    0
  );
  const dueblance = totalamount - Number(totalDiscount) - totalAmountSelected;
  const [amounts, setAmounts] = useState({
    Cash: "",
    Card: "",
    PayTm: "",
    Other: "",
    loyalty: 0,
  });

  // Calculate total selected amount and due balance
  //calculate due balance
  const [itemamount, setItemamount] = useState(dueblance);

  useEffect(() => {
    if (dueblance >= 0) {
      setItemamount(dueblance);
    } else {
      setItemamount(0);
    }
    console.log("dueblance", dueblance);
  }, [dueblance]);

  const optionArray = [
    { id: 1, name: "Cash", value: "Cash", isActive: true, amount: "" },
  ];

  useEffect(() => {
    const newAmount = totalamount - totalDiscount;

    setOptionTick((prevOptionTick) => {
      const optionExists = prevOptionTick.some(
        (option) => option.id === optionArray[0].id
      );

      if (optionExists) {
        // Update the amount if option already exists
        return prevOptionTick.map((option) =>
          option.id === optionArray[0].id
            ? { ...option, amount: newAmount }
            : option
        );
      } else {
        // Add new option if it doesn't exist
        return [...prevOptionTick, { ...optionArray[0], amount: newAmount }];
      }
    });

    setAmounts((prevAmounts) => ({ ...prevAmounts, Cash: newAmount }));
    console.log(optionTick);
  }, [totalamount, totalDiscount]);

  const handleOptionSelection = (option) => {
    if (optionTick.some((item) => item.value === option.value)) {
      // If the option is already selected, unselect it
      console.log("come in unselect");
      setOptionTick(optionTick.filter((item) => item.value !== option.value));
      setAmounts({
        ...amounts,
        [option.value]: option.value === "loyalty" ? 0 : "",
      });
    } else {
      console.log(option);
      // Otherwise, select the option
      const newOption = {
        ...option,
        amount: option.value === "loyalty" ? loylity.converted_cash : "",
      };
      setOptionTick([...optionTick, newOption]);
      setAmounts({ ...amounts, [option.value]: newOption.amount });
    }
  };

  const handleAmountChange = (option, amount) => {
    const newAmount = parseFloat(amount) || "";
    console.log(option, optionTick);
    // Calculate the new total amount selected including the new amount for this option
    const currentOptionAmount =
      optionTick.find((item) => item.value === option.value)?.amount || 0;
    const newTotalSelected =
      totalAmountSelected - currentOptionAmount + newAmount;

    if (newTotalSelected > totalamount - totalDiscount) {
      Swal.fire("Please enter the correct amount");
      return;
    }

    const updatedOptionTick = optionTick.map((item) =>
      item.value === option.value ? { ...item, amount: newAmount } : item
    );
    console.log(updatedOptionTick);
    setOptionTick(updatedOptionTick);
    setAmounts({ ...amounts, [option.value]: newAmount });
  };
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [customers, setCustomers] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { customer_name: name, mobile_number: mobile, address };
    // console.log("khh",data);
    setCustomers((state) => [...state, data]);
    setName("");
    setAddress("");
    setMobile("");
    setOpenCustomer((state) => !state);
    Swal.fire("Customer Added...");
  };
  const handleMobileChange = (e) => {
    const inputValue = e.target.value;
    const onlyDigits = inputValue.replace(/\D/g, ""); // Remove non-digit characters

    if (onlyDigits.length <= 10) {
      setMobile(onlyDigits);
    }
  };
  const componentRef = useRef();
  const focusClose = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      // Add any logic you need before printing
      console.log("printing Start");
    },
    onAfterPrint: () => {
      // Add any logic you need after printing
      setViewPdf(false);
      if (RType) {
        navigate("/Orders");
      } else {
        navigate("/Tables");
      }
      setOptionTick("");
      setDeliveryCharges("");
      dispatch(removeLinkCustomer())
      console.log("printing End");
    },
  });

  const header = {
    container: {
      fontFamily: "Courier New, monospace",
      fontSize: "small",
    },
    text: {
      fontFamily: "Courier New, monospace",
      fontSize: "small",
    },
  };

  const [memberEnroll, setMemberEnroll] = useState(false);
  const [linkloylity, setlinkloylity] = useState(false);
  // const [customerShow, setCustomerShow] = useState(false);
  const [AddCustomer, setAddCustomer] = useState(false);
  const [linkCustomer, setlinkCustomer] = useState(false);

  const [activeTab, setActiveTab] = useState(orderType);

  useEffect(() => {
    setActiveTab(orderType);
  }, [orderType]);

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  //apply discount on totalamount if discount type is percentage then apply discount on total amount if discount type is amount then apply discount on total amount
  const handleApplyDiscount = ( type, amount) => {
    // if amount is greater than total amount then show error message

    console.log("type", type, "amount", amount);
    if (type === "percentage") {

      const discountAmount = Math.round(totalamount * (amount / 100));
      if (discountAmount > totalamount) {
        Swal.fire("Please enter a valid discount amount");
        return;
      }
      const newTotalAmount = totalamount - discountAmount;
      // setItemamount(newTotalAmount);
      console.log("Total Discount", discountAmount , totalamount)
      setTotalDiscount(discountAmount);
    } else if (type === "amount") {
      if (amount > totalamount) {
        Swal.fire("Please enter a valid discount amount");
        return;
      }
      const newTotalAmount = totalamount - amount;
      // setItemamount(newTotalAmount);
      setTotalDiscount(amount);
    }
  };

  const getDataByorder = async () => {
    try {
      const response = await axios.get(
        `${BASE_Url}/order/view-order-details/${params.order_id}`
      );
      if (response.status) {
        console.log("this is response", response);
        setData(response.data.data);
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        {/* <h5>Billing</h5> */}
        <Grid item xs={12} sm={12}>
          <div
            className="bg-white  rounded md:max-h-[600px] max-h-[500px]"
            style={{
              overflowY: "auto",
              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
              borderRadius: "0.75rem",
              fontFamily: "DM sans-serif",

              border: "0 2px 12px rgba(36, 36, 39, .12)",
            }}
          >
            <div className=" sticky-top">
              <div className="bg-white flex flex-row items-center justify-between ">
                <Button
                  style={{
                    background: activeTab === "Dine in" ? "#0E2954" : "#CECECE",
                  }}
                  className="py-3 w-1/3 text-white"
                  // onClick={() => handleTabClick('Dine-In')}
                >
                  Dine-In
                </Button>
                <Button
                  style={{
                    background:
                      activeTab === "Delivery" ? "#0E2954" : "#CECECE",
                  }}
                  className="py-3 w-1/3 text-white"
                  // onClick={() => handleTabClick('Delivery')}
                >
                  Delivery
                </Button>
                <Button
                  style={{
                    background: activeTab === "Pickup" ? "#0E2954" : "#CECECE",
                  }}
                  className="py-3 w-1/3 text-white"
                  // onClick={() => handleTabClick('PickUp')}
                >
                  PickUp
                </Button>
              </div>
            </div>

            <div style={{ height: "160px", overflowY: "auto" }}>
              <table className="table-auto w-full text-left  whitespace-no-wrap">
                <thead className="border ">
                  <tr>
                    {Toggle && (
                      <th
                        scope="col"
                        className="fw-bold px-4 py-2  text-center"
                      >
                        Item
                      </th>
                    )}

                    <th
                      scope="col"
                      className="fw-bold px-4 py-2 pl-5 pr-5 text-center text-nowrap"
                    >
                      Item Name
                    </th>
                    <th
                      scope="col"
                      className="fw-bold px-4 py-2 pl-5 pr-5 text-center"
                    >
                      Qty
                    </th>
                    <th scope="col" className="fw-bold py-2 pl-5 text-center">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products.length > 0 &&
                    products.map((el) => (
                      <tr className="border-b-2 border-zinc-200">
                        {Toggle && (
                          <td className="px-4 flex justify-around ">
                            {" "}
                            <img
                              className="h-full w-full object-cover object-center "
                              style={{
                                width: "50px",
                                height: "40px",
                                borderRadius: "8px",
                              }}
                              src={`${BASE_Url}/item/get-image/${el.item_id}`}
                            />
                          </td>
                        )}
                        <td className=" text-center fs-5 fw-bold">
                          {el.item_name}
                          <span>   {el?.localItemName} </span>
                        </td>

                        <td className="px-10">
                          <div className="flex flex-row items-center justify-center">
                            {/* <button
       className=" border-2 fs-5 fw-bold text-center"
       style={{
         width: "30px",
         height: "30px",

         background: "white",
         color: "black",
         flexShrink: 0,
         borderTopLeftRadius: "10px", // Round the top-left corner
         borderBottomLeftRadius: "10px",
         // boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25) inset",
       }}
                              onClick={() => decres(el)}
                              // onClick={() => incres(el)}
                            >
                              -
                            </button> */}
                            <span className="px-2">{el.item_qty}</span>
                            {/* <button
                              className=" border-2 fs-5 fw-bold text-center"
                              style={{
                                width: "30px",
                                height: "30px",

                                background: "white",
                                color: "black",
                                flexShrink: 0,
                                borderTopRightRadius: "10px", // Round the top-left corner
                                borderBottomRightRadius: "10px",
                              }}
                              onClick={() => incres(el)}
                            >
                              +
                            </button> */}
                          </div>
                        </td>

                        <td className="px-4 py-3 fs-5 fw-bold ml-4 text-center">
                          {bankAccount} {calculation(el)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <form className="max-w-full mx-auto p-2">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                {/* Discount Type */}
                <div className="flex flex-col md:flex-row items-center gap-4">
                  <label className="w-full md:w-1/2 text-left md:text-right pr-2">
                    Discount Type:
                  </label>
                  <div className="flex gap-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={discountType === "percentage"}
                          onChange={() =>
                            handleDiscountTypeChange("percentage")
                          }
                        />
                      }
                      label="Percentage"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={discountType === "amount"}
                          onChange={() => handleDiscountTypeChange("amount")}
                        />
                      }
                      label="Amount"
                    />
                  </div>
                </div>

                {/* Net Invoice Amount */}
                {/* <div className="flex flex-col md:flex-row items-center gap-4">
      <label className="w-full md:w-1/2 text-left md:text-right pr-2">Net Invoice Amount:</label>
      <input
        type="number"
        name="netInvoiceAmount"
        value={formData.netInvoiceAmount}
        onChange={handleChange}
        className="border p-2 rounded w-full md:w-1/3"
      />
    </div> */}

                {/* Discount Value */}
                <div className="flex flex-col md:flex-row items-center gap-4">
                  <label className="w-full md:w-1/2 text-left md:text-right pr-2">
                    Discount Value:
                  </label>
                  <input
                    type="number"
                    value={discountValue}
                    onChange={handleDiscountValueChange}
                    placeholder={
                      discountType === "percentage"
                        ? "Enter 1-100"
                        : "Enter amount"
                    }
                    disabled={!discountType}
                    min={discountType === "percentage" ? 1 : undefined}
                    max={discountType === "percentage" ? 100 : undefined}
                    className="border p-2 rounded w-full md:w-1/3"
                  />
                  <div className="flex justify-center">
                    <button
                      onClick={(e) => {
                        handleApplyDiscount( discountType, discountValue);
                      }}
                      type="button"
                      className="bg-dark-purple text-white px-4 py-2 rounded hover:bg-blue-900"
                    >
                      Apply
                    </button>
                  </div>
                </div>

                {/* GST Amount */}
                {/* <div className="flex flex-col md:flex-row items-center gap-4">
      <label className="w-full md:w-1/2 text-left md:text-right pr-2">GST Amount:</label>
      <input
        type="number"
        name="gstAmount"
        value={formData.gstAmount}
        onChange={handleChange}
        className="border p-2 rounded w-full md:w-1/3"
      />
    </div> */}
              </div>
            </form>
            <hr className="m-0" />
            {/* ----------------------------------------- */}
            <footer
              style={{
                width: "100%",
                // borderTop: "2px solid gray",
                // padding: "10px",
                // backgroundColor: "rgb(245 158 11)",
                // height:"80px"
              }}
              className="border sticky"
            >
              <div className="flex flex-row items-center justify-center gap-11 text-xl my-1">
                {/* <button style={{ background: "#BC362D" }}  className="semibold text-white py-2 px-4 rounded md:mt-0">
                Split
              </button> */}
                {/* <button
       className="btn  rounded"
       style={{fontFamily: "Inter",
       fontStyle: "normal",
       fontWeight: "700",
       lineHeight: "normal" }}
       onClick={() => setOpenCustomer((state) => !state)}
     >
       <PersonAddIcon /> 
       <span> Add Customer </span>
     </button> */}
                <Button
                  style={{ background: "rgb(14, 41, 84)" }}
                  onClick={() => setAddCustomer((state) => !state)}
                  className="justify-center flex rounded text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 p-2"
                >
                  Add Customer
                </Button>
                <span className="fw-bold mx-1">Due :{itemamount}</span>
                <div className="fw-bold p-2 rounded text-center ">
                  Total :
                  {totalDiscount > 0
                    ? totalamount - totalDiscount
                    : totalamount}
                  {/* {(
                  Math.round(
                    products
                      .map((data) => data.item_price * data.item_qty)
                      .reduce((total, value) => total + value, 0) * 100
                  ) / 100
                ).toFixed(2)} */}
                </div>
                {totalDiscount > 0 && (
                  <span className="fw-bold mx-1">
                    Discount :{totalDiscount}
                  </span>
                )}

                {/* <div className="calculated_amount-container">
                  {optionTick && optionTick?.length > 0 && (
                    <>
                      {optionTick.map((item) => {
                        return (
                          <>
                          
                            <div style={{ fontSize: "20px" }}>
                            </div>
                          </>
                        );
                      })}
                    </>
                  )}
                </div> */}
              </div>
              <hr className="mx-5 m-0" />

              <div className="flex flex-wrap flex-row items-center justify-center gap-8">
                {optionArray
                  .filter((el) => el.isActive)
                  .map((option) => {
                    const isSelected = optionTick.some(
                      (item) => item.value === option.value
                    );
                    return (
                      <div
                        key={option.id}
                        className="flex flex-row items-center justify-center my-2 mx-1 gap-2"
                      >
                        <input
                          style={{
                            width: "25px",
                            height: "25px",
                            flexShrink: 0,
                          }}
                          checked={isSelected}
                          id={option.name}
                          className="mr-2"
                          type="checkbox"
                          onChange={() => handleOptionSelection(option)}
                          disabled={!isSelected && itemamount <= 0} // Disable if not selected and itemamount is zero or less
                        />
                        {isSelected && option.value !== "loyalty" ? (
                          <>
                            <label
                              className="fw-bold text-black"
                              htmlFor={option.value}
                            >
                              {option.name}
                            </label>
                            <input
                              style={{ width: "100px" }}
                              type="number"
                              autoFocus
                              className="p-2 rounded border-3"
                              placeholder="Amount"
                              value={amounts[option.value]}
                              onChange={(e) =>
                                handleAmountChange(option, e.target.value)
                              }
                              min="0"
                            />
                          </>
                        ) : (
                          <label
                            className="fw-bold text-black"
                            htmlFor={option.value}
                          >
                            {option.name}
                          </label>
                        )}
                        {isSelected && option.value === "loyalty" && (
                          <span className="mx-1">{amounts[option.value]}</span>
                        )}
                      </div>
                    );
                  })}

                {/* <div className="my-2 mx-3">
        <span className="fw-bold mx-1">Due: {itemamount}</span>
      </div> */}
               {  ( orderType === "Delivery"&&DeliveryCharges > 0) && (
                  <div className="flex flex-row items-center justify-center gap-2">
                    <span className="fw-bold mx-1">Delivery Charges:</span>
                    <span className="fw-bold mx-1">{DeliveryCharges}</span>
                    </div>)}
               {  orderType === "Dine in"&& (
                  <div className="flex flex-row items-center justify-center gap-2">
                   <input
                    onChange={(e)=>{setDeliveryCharges(Number(e.target.value))}}
                    type="number"
                    placeholder="Extra/Dilvery Charges"
                    className="border p-2 rounded w-full"
                  />
                    </div>
                  )}
              </div>
              <div>
                <hr className="mx-5 m-0" />
              </div>
              <div className="flex  p-2 items-center justify-center ">
                <Button
                  style={{ background: "rgb(14, 41, 84)" }}
                  onClick={() => {
                    setMemberEnroll(true);
                  }}
                  className="text-nowrap mx-1 justify-center flex rounded text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 p-2"
                >
                  Member Enroll
                </Button>
                <Button
                  style={{ background: "rgb(14, 41, 84)" }}
                  onClick={() => {
                    setlinkloylity(true);
                  }}
                  className=" text-nowrap justify-center flex rounded text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 p-2"
                >
                  Link Loyalty
                </Button>

                <Button
                  style={{ background: "rgb(14, 41, 84)" }}
                  onClick={() => {
                    setlinkCustomer(true);
                  }}
                  className="text-nowrap mx-1 justify-center flex rounded text-xs    text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 "
                >
                  Link Customer
                </Button>
              </div>
              <hr className="mx-5 m-0" />
              <div className="flex  p-2 items-center justify-center ">
                {products && products.length > 0 ? (
                  <div className="width-full flex flex-row items-center justify-center">
                    {/* <button
                    type="button"
                    className="text-nowrap text-white mx-2 py-2 px-4 rounded md:mt-0"
                    style={{background: "rgb(14, 41, 84)"}}
                    onClick={() => {
                      getDataByorder()}}
                  >
                    Kot & Print
                  </button> */}
                    {isloding ? (
                      ""
                    ) : (
                      <>
                      <Button
                        disabled={!itemamount <= 0}
                        type="button"
                        autoFocus={true}
                        className="text-nowrap text-white py-2 mx-2 px-4 rounded md:mt-0"
                        style={{
                          background: "rgb(14, 41, 84)",
                          opacity: !itemamount <= 0 ? 0.5 : 1,
                        }}
                        onClick={() => handleSaveTransaction(false, "Dine")}
                        >
                         Print
                      </Button>
                      <Button
                        disabled={!itemamount <= 0}
                        type="button"
                        // autoFocus={true}
                        className="text-nowrap text-white py-2 mx-2 px-4 rounded md:mt-0"
                        style={{
                          background: "rgb(14, 41, 84)",
                          opacity: !itemamount <= 0 ? 0.5 : 1,
                        }}
                        onClick={() => handleSaveTransaction(true, "Digital")}
                        >
                        Digital Receipt 
                        </Button>
                        <Button
                        disabled={!itemamount <= 0 || (orderType === "Dine in" && !customer?.mobile_number)}
                        type="button"
                        className="text-nowrap text-white py-2 mx-2 px-4 rounded md:mt-0"
                        style={{
                          background: "rgb(14, 41, 84)",
                          opacity: !itemamount <= 0 || (orderType === "Dine in" && !customer?.mobile_number) ? 0.5 : 1,
                        }}
                        onClick={() => handleSaveTransaction(true ,"whatsapp")}
                        >
                        Share to Whatsapp 
                        </Button>

                        </>
                        )}
                        </div>
                      ) : (
                        ""
                      )}
                      </div>
                      {/* -- */}
            </footer>

            {/* --PDF MODAL-- */}

            <Modal
              show={viewPdf}
              onHide={() => setViewPdf(false)}
              className="p-0"
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              {/* <Modal.Header className="container" closeButton>
                 <Modal.Title>Receipt Genrate</Modal.Title>
               </Modal.Header> */}
              <Box>
                <div
                  className="-mx-2 mt-1"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setViewPdf((state) => !state);
                    // dispatch(RemoveCart());
                    // navigate("/");
                  }}
                >
                  <AiOutlineClose size={20} />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="secondary"
                    style={{ background: "gray" }}
                    className=" text-white mt-10 mx-2 p-2"
                    onClick={() => {
                      setViewPdf(false);
                      // dispatch(RemoveCart());
                      // dispatch(HandelLinkCustomer([]));
                      // dispatch(RemoveInovice());
                      // navigate("/");
                    }}
                    ref={focusClose}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    style={{ background: "blue" }}
                    autoFocus
                    className=" text-white mt-10 p-2"
                    onClick={handlePrint}
                  >
                    PRINT
                  </Button>
                  {/* <button className="bg-dark-subtle mt-10 p-2" onClick={openCashDrawer}>
                 Open CashDrawer
                </button> */}
                </div>
                <div
                  className="container"
                  style={header.container}
                  ref={componentRef}
                >
                  {saasId == "122" ? (
                    <BillReceiptsaasid12
                    DeliveryCharges={DeliveryCharges}
                    tablecategory={tablecategory}
                      orderaddress={orderaddress}
                      totalDiscount={totalDiscount}
                      customer={ orderType=="Delivery"? OnlineCustomerData:customer}
                      tableNo={tableNo}
                      invoiceNo={invoiceNo}
                      payment_mode={pay_method}
                      orderType={orderType}
                      optionTick={optionTick}
                      products={products}
                    />
                  ) : (
                    <ReceiptModal
                    DeliveryCharges={DeliveryCharges}
                    tablecategory={tablecategory}
                      orderaddress={orderaddress}
                      totalDiscount={totalDiscount}
                      customer={ orderType=="Delivery"? OnlineCustomerData:customer}
                      tableNo={tableNo}
                      invoiceNo={invoiceNo}
                      payment_mode={pay_method}
                      orderType={orderType}
                      optionTick={optionTick}
                      products={products}
                    />
                  )}
                </div>

                {/* <ModalFooter>
              
            </ModalFooter> */}
              </Box>
            </Modal>

            {/* --PDF MODAL-- */}
          </div>
        </Grid>
      </Grid>

      {orderData && (
        <TableToPrint
          setOpen={setOpen}
          orderData={orderData}
          open={open}
          handlePrint={handlePrint}
          printRef={printRef}
        />
      )}
      <MemberEnrollModal
        show={memberEnroll}
        onHide={() => setMemberEnroll(false)}
      />
      <LinkLoyaltyModal
        show={linkloylity}
        onHide={() => setlinkloylity(false)}
      />

      {/* Add Customer Modal 👇*/}
      <LinkCustomerModal open={AddCustomer} setAddCustomer={setAddCustomer} />
      {/* Link Customer Modal 👇 */}
      <LinkCustome open={linkCustomer} onClose={() => setlinkCustomer(false)} />
    </>
  );
};

export default FinishOrder;
