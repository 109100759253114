import React, { useEffect, useState } from "react";
import { Modal, Button as BootstrapButton } from "react-bootstrap";
import {
  TextField,
  Button as MUIButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";

import Swal from "sweetalert2";
import { BASE_Url } from "../../../URL.js/URL";

const UpdateFinishedOrder = ({ show, onHide, getAllData, selectedRow }) => {
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [Receivedqty, setReceivedqty] = useState("");
  const [cost, setCost] = useState("");
  const [unit, setUnit] = useState("");

  useEffect(() => {
    // Autofill the fields when the modal is shown and selectedRow is available
    if (selectedRow) {
      setName(selectedRow.itemName);
      setQuantity(selectedRow.openingqty);
      setReceivedqty(selectedRow.received_qty);
      setCost(selectedRow.cost);
      setUnit(selectedRow.unit);
    }
  }, [selectedRow, show]);
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const handleUpdateMaterialDetail = () => {


    if ( quantity) {
      // Make API request to update the inventory item
      const apiUrl = `${BASE_Url}/item/add-item-inventory/${selectedRow.itemId}`;

      const requestBody = {
        opening_qty:quantity || "0", 
       received_quantity: Receivedqty || "0", // Use existing or default
           // Use existing or default
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(apiUrl, requestBody, { headers })
        .then((res) => {
          Swal.fire({
            title: "Item Update Successful",
            icon: "success",
            timer: 1000,
          });
          getAllData(1);
          onHide();
          // Clear the fields after submission
          setName("");
          setQuantity("");
          setCost("");
          setUnit("");
        })
        .catch((err) => {
          console.error("API Error:", err);
        });
    } else {
      Swal.fire({
        title: "Please fill all fields",
        icon: "warning",
      });
    }
  };


  
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Raw Material Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
 
        <InputLabel id="unit-select-label">Opening Quantity</InputLabel>
        <TextField
          type="text"
          value={quantity}
          placeholder="Opening Quantity"
          onChange={(e) => setQuantity(e.target.value)}
          className="form-control mb-3"
          fullWidth
        />
        <InputLabel id="unit-select-label">Received Quantity </InputLabel>
        <TextField
          type="text"
          value={Receivedqty}
          placeholder="Received Quantity"
          onChange={(e) => setReceivedqty(e.target.value)}
          className="form-control mb-3"
          fullWidth
        />
      
       


      </Modal.Body>
      <Modal.Footer>
        <MUIButton className="mb-1" fullWidth variant="contained"  onClick={onHide}>
          Cancel
        </MUIButton>
        <MUIButton fullWidth variant="contained" onClick={handleUpdateMaterialDetail}>
          Update
        </MUIButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateFinishedOrder;
