import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

const ProtectedRoutingWhenLogout = (props) => {
  const { Component } = props;
  // console.log("1");
  return (
    <>
      {localStorage.getItem("Token") ? (
        <>
          <Navigate to="/"/>
        </>
      ) : (
        <>
          <Component  />
        </>
      )}
    </>
  );
};

export default ProtectedRoutingWhenLogout;
