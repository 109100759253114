import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Container, Typography } from '@mui/material';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import RestaurantTables from './RestaurantTables';
const theme = createTheme();

export default function CaptianTable() {

    //Call Api and get data and set in a state tableData
    const [tableData, setTableData] = useState([])
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const gettable =async()=>{
        try {
            const res = await axios.get(`${BASE_Url}/tableview/table_view/${saasId}/${storeId}`)
            if(res.data.status){
                setTableData(res.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
  
    useEffect(() => {
      gettable()
    }, [])
    

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Restaurant Tables
        </Typography>
        <RestaurantTables data={tableData} />
      </Container>
    </ThemeProvider>
  );
}

