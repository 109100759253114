import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import AddUser from './AddUser';

const AddUserModal = ({ show, handleClose }) => {

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Add New User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddUser/>
            </Modal.Body>
        </Modal>
    );
};

export default AddUserModal;