import React, { useEffect, useState } from "react";

import axios from "axios";

import { Box, Tabs, Tab, TextField, Button, MenuItem } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { addData } from "../redux/slices/tableSlice";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import "./Tables.css";
import { BASE_Url } from "../URL.js/URL";
import Swal from "sweetalert2";
import { Grid, Typography } from "@mui/material";
import { Row } from "react-bootstrap";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import Settlementmodal from "./Settlementmodal/Settlementmodal";
import { FaHandsHelping } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

//
function Tables() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => {
    return state.tableData.data;
  });
  
  // modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [invoicemodal, setInvoiceModal] = React.useState(false);
  const invoicemodalOpen = () => setInvoiceModal(true);
  const invoicemodalClose = () => setInvoiceModal(false);
  //view table
  const [invoiceNo, setInvoiceNo] = useState("");
  const [inviceAmount, setInvoiceAmount] = useState("");
  const [dinnerArray, setDinnerArray] = useState([]);
  const [dinnerArray1, setDinnerArray1] = useState([]);
  const [dinnerArray2, setDinnerArray2] = useState([]);
  const [form, setFormData] = useState({
    category: "",
    tableNo: "",
  });
  const [multipe, setMultipe] = useState({
    category: "",
    tableNo: "",
  });
  console.log("formtable", form);
  // view table
  useEffect(() => {
    if (data.length !== 0) {
      setDinnerArray(data[0][0]?.table_list);
      setDinnerArray1(data[0][1]?.table_list);
      setDinnerArray2(data[0][2]?.table_list);
    }
  }, [data]);
  // console.log(dinnerArray);
  // if (data.length !== 0) {
  //   console.log(data);
  // }
  // create table
  const [category, setcategory] = useState("");
  const [table, setTable] = useState([]);
  /**
   *
   * const table = []
   *
   * const handleChange = e=>{
   *   table.push(e)
   * }
   *
   *
   * <button onchnage(e=>handleChange(e))>
   *
   */
  const getTables = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const apiUrl = `${BASE_Url}/tableview/table_view/${saasId}/${storeId}`;

    // Make the API call
    axios
      .get(apiUrl)
      .then((response) => {
        console.log(response);
        setTable(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getTables();
  }, []);
  // ******

  // create singal Table
  const { saasId, storeId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const createTable = () => {
    console.log("store", storeId, saasId);
    const apiUrl = `${BASE_Url}/tableview/createtable`;
    axios
      .post(apiUrl, {
        CATEGORY: form.category,
        TABLE_NUMBER: form.tableNo,
        SAAS_ID: saasId,
        STORE_ID: storeId,
      })
      .then((response) => {
        handleClose()
        console.log(response);
        {
          Swal.fire(response.data.message);
        }
        getTables();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const createmultipleTable = () => {
    console.log("store", storeId, saasId);
    const apiUrl = `${BASE_Url}/tableview/createtables`;
    axios
      .post(apiUrl, {
        CATEGORY: multipe.category,
        TABLE_NUMBER: multipe.tableNo,
        SAAS_ID: saasId,
        STORE_ID: storeId,
      })
      .then((response) => {
        handleClose()
        console.log(response);
        {
          Swal.fire(response.data.message);
        }
        getTables()
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // useEffect(() => {
  //   createTable();
  // }, []);
  //
  const getOptionLabel = (form, category) => (
    <div>
      <strong>{form?.category}</strong>
    </div>
  );


  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };


    setScreenWidth(window.innerWidth); // Initialize screenWidth

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const GetOrderIdontableid = async (tableId, status,order_id) => {
    const apiUrl = `${BASE_Url}/order/view-ordermaster/${tableId}/${saasId}/${storeId}/${order_id}`;
    try {
      const response = await axios.get(apiUrl);
      console.log(response.data.data);
      if(response.data.data) {
        if(status == "preparing" ){
          navigate(`/finishorder/${response.data.data.id}`)
        }else if(status == "serve" ){
          invoicemodalOpen()
          setInvoiceNo(response.data.data.invoice)
        }else{
          navigate(`/Update?orderId=${response.data.data.id}`)
        }
      }else{
        Swal.fire({
          title: "No Order Found",
          icon: "error",
          confirmButtonText: "Close",
        })
      }
      // navigate(`/order/create/${response.data.data.order_id}`);
    } catch (error) {
      console.error(error);
    }
  };
  

  const [key, setKey] = useState("single");
  return (
    <>
   <div className="px-4 py-2">
      <div className="flex container md:flex-row justify-between items-center ">
        <div>
          <h2 className="text-xl font-bold">Tables</h2>
        </div>
        <button  style={{background:"#0E2954"}}   onClick={handleOpen} className=" text-white py-2 px-4 rounded md:mt-0">
          Add Tables
        </button>
        {/* <button  style={{background:"#0E2954"}}   onClick={invoicemodalOpen} className=" text-white py-2 px-4 rounded md:mt-0">
          view
        </button> */}
      </div>
      <Grid container spacing={1} >
      <Grid className="grid" item xs={12} sm={6}>
       {screenWidth >= 768 ? <h6>Tap on a table to manage it.</h6>:""}
      </Grid>
            <Grid className="grid" item xs={12} sm={6}>
      <div className="flex flex-wrap lg:justify-content-end sm:justify-start items-center space-x-4">
        
       
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
          <span className="text-gray-700">Empty</span>
        </div>
        {/* <div className="flex items-center space-x-2">
          <div className="w-4 h-4 bg-yellow rounded-full"></div>
          <span className="text-gray-700">Occupied</span>
        </div> */}
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4 bg-green-500 rounded-full"></div>
          <span className="text-gray-700">In Progress</span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4 bg-blue-400 rounded-full"></div>
          <span className="text-gray-700">Invoice Printed</span>
        </div>
      </div>
      </Grid>
      </Grid>

    </div>

      {/* <div
        className="flex flex-col mt-4 m-8"
        style={{ height: "100%", overflowY: "auto" }}
      > */}
        {/* <h3 className="text-start p- mt-2  font-medium text-xl font-['inter']">
          Tables
        </h3> */}
         <div style={{ height: "75vh", overflowY: "auto", marginLeft:"25px"}}>
        
       
         <div className="flex justify-between  gap-15 w-full">
          <div className="left-2 flex-col items-center justify-center h-6 top-80 w-[200]  text-blue-500">
         
            {table.map((el) => (
              <div className="flex flex-col my-3">
                <div className="my-2 fw-bold   text-white "
               >
                <button  style={{background:"#0E2954"}}  className=" text-white py-2 px-4 rounded md:mt-0"> {el.category}</button> </div>

                <div className="flex flex-row flex-wrap">
                  {el.table_list.map((el1) => (
                    // <div
                    //   key={el1.table_id}
                    //   style={{
                    //     height: "65px",
                    //     // border: `4px solid ${
                    //     //   el1.status === "Occupied"
                    //     //     ? "#d97706" 
                    //     //     : el1.status === "Available"
                    //     //     ? "#65a30d" 
                    //     //     : "rgb(117, 116, 114)"
                    //     // }`,
                    //     position: "relative",
                    //     // borderRadius: 70,
                    //     color: "#000",
                    //     fontFamily: "Roboto",
                    //     fontSize: "16px",
                    //     fontStyle: "normal",
                    //     fontWeight: "700",
                    //     lineHeight: "normal"
 
                    //     // width: "9%", // Adjusted width for small screens
                    //   }}
                     
                    //   className="flex flex-col w-16 h-16 border-2 border-gray-300 flex items-center justify-center relative rounded-md shadow-md m-2"
                    // >
                    // <span className="text-xl"> {el1.table_name}</span>  
                    //   <div className={`absolute bottom-0 left-0 right-0 h-2 ${el1.status === "Occupied"
                    //         ? "bg-amber-600"
                    //         : el1.status === "Available"
                    //           ? "bg-lime-600"
                    //           : "bg-zinc-500"
                    //       } rounded-b-md`}></div>
                      
                    // </div>
                    <>
                    {el1.table_name !== "Menu" && <div
                    key={el1.table_id}
                    // onClick={() => {
                    //   if(el1.status === 'Occupied'){
                    //     GetOrderIdontableid(el1.table_id,el1.order_status)
                    //   }
                    // }}
                    className={` w-24 h-24 border-3 border-[gray] border-dotted flex flex-col items-center justify-center relative m-2 rounded-lg shadow-md ${
                      el1.status === 'Occupied' ? 'bg-green-200' :el1.status === 'Printed'?'bg-blue-400' : 'bg-gray-100'
                    }`}
                  >
                    {/* Time */}
                    {/* {table.time && <span className="absolute top-2 left-2 text-sm">{table.time}</span>} */}
          
                    {/* Table Number */}
                    <span className="text-lg text-black">{el1.table_name}</span>
          
                    {/* Price */}
                    {el1.total_amount && <span className="text-sm mt-1 text-black">{el1.total_amount}</span>}
          
                    {/* Icon (Different icons based on status) */}
                    <div className="absolute bottom-0 gap-2 w-20 flex justify-around">
                      {(el1.status === 'Occupied' || el1.status === 'Printed') && (
                        <>
                        {el1.order_status === 'serve' && <FaHandsHelping  onClick={() => {
                          if(el1.status === 'Occupied'|| el1.status === 'Printed'){
                            GetOrderIdontableid(el1.table_id,el1.order_status, el1.order_id)
                            setInvoiceAmount(el1.total_amount)
                          }
                        }}  className="h-6 w-6 text-gray-700 cursor-pointer hover:text-[#ffab4b]"/> }
                       {el1.order_status != 'serve' && <><RemoveRedEyeOutlinedIcon
                        onClick={() => {
                          if(el1.status === 'Occupied' || el1.status === 'Printed'){
                            GetOrderIdontableid(el1.table_id,"update",el1.order_id)
                          }
                        }} className="h-6 w-6 text-gray-700 cursor-pointer hover:text-[#ffab4b]"/>
                        <LocalPrintshopOutlinedIcon onClick={() => {
                          if(el1.status === 'Occupied' || el1.status === 'Printed'){
                            GetOrderIdontableid(el1.table_id,el1.order_status, el1.order_id)
                            // setInvoiceAmount(el1.total_amount)
                          }
                        }} className="h-6 w-6 text-gray-700 cursor-pointer hover:text-[#ffab4b]"/></>}
                        </>
                      ) }
                    </div>
                  </div>}
                  </>
                  ))}
                </div>

              </div>
            ))}
          </div>


        </div>
       
        <div className="left-2 w-32 h-6 top-80 mt-10 text-red-500">
          {data.length !== 0 ? data[0][0]?.category : ""}
        </div>
        <div className="flex mt-10   will-change-auto gap-5 cursor-pointer ">
          {dinnerArray?.map((e, index) => {
            return (
              <div className="  bg-light-grey border-dashed  border border-dark-grey flex h-16 w-16 items-center justify-center rounded-lg ">
                {e.table_id}
              </div>
            );
          })}
        </div>
        <div className="left-2 w-32 h-6 top-80 mt-10 text-red-500">
          {data.length !== 0 ? data[0][1]?.category : ""}
        </div>
        <div className="flex mt-10   will-change-auto gap-5 cursor-pointer ">
          {dinnerArray1?.map((e, index) => {
            return (
              <div className="  bg-light-grey border-dashed  border border-dark-grey flex h-16 w-16 items-center justify-center rounded-lg ">
                {e.table_id}
              </div>
            );
          })}
        </div>
        <div className="left-2 w-32 h-6 top-80 mt-10 text-red-500">
          {data.length !== 0 ? data[0][2]?.category : ""}
        </div>
        <div className="flex mt-10   will-change-auto gap-5 cursor-pointer ">
          {dinnerArray2?.map((e, index) => {
            return (
              <div className="  bg-light-grey border border-dashed  border-dark-grey flex h-16 w-16 items-center justify-center rounded-lg ">
                {e.table_id}
              </div>
            );
          })}
        </div>

        {/* <div className="left-full w-32 h-6 top-80 mt-10 mb-0   text-red-500 ">
          Category 2
        </div> */}
        {/* <div className="flex mt-10  gap-5 will-change-auto cursor-pointer"></div> */}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
     <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)", // Center horizontally and vertically
      width: "500px",
      background: "#fff",
      borderRadius: "8px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
      p: 3,
    }}
    >
      <Tabs
        value={key}
        onChange={(e, newValue) => setKey(newValue)}
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Create Single" value="single" />
        <Tab label="Create Multiple" value="multiple" />
      </Tabs>

      {/* Create Single Tab */}
      {key === "single" && (
        <Box sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <Select
            options={[
              { label: "Ac", value: "Ac" },
              { label: "Non-Ac", value: "Non-Ac" },
              { label: "Others", value: "Others" },
            ]}
            placeholder="Select Category"
            onChange={(e) => setFormData({ ...form, category: e.value })}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999, // Set a high z-index value
              }),
            }}
          />
          <TextField
            label="Table Number"
            type="number"
            value={form.tableNo}
            onChange={(e) => setFormData({ ...form, tableNo: e.target.value })}
            variant="outlined"
            fullWidth
          />
          <Button
            variant="contained"
           style={{background:"rgb(14, 41, 84)"}}
            onClick={createTable}
            fullWidth
            sx={{ textTransform: "uppercase" }}
          >
            Add Table
          </Button>
        </Box>
      )}

      {/* Create Multiple Tab */}
      {key === "multiple" && (
        <Box sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 2 }}>
     <Select
  options={[
    { label: "Ac", value: "Ac" },
    { label: "Non-Ac", value: "Non-Ac" },
    { label: "Others", value: "Others" },
  ]}
  placeholder="Select Category"
  onChange={(e) => setMultipe({ ...multipe, category: e.value })}
  styles={{
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Set a high z-index value
    }),
  }}
/>

          <TextField
            label="Table Numbers"
            type="number"
            value={multipe.tableNo}
            onChange={(e) => setMultipe({ ...multipe, tableNo: e.target.value })}
            variant="outlined"
            fullWidth
          />
          <Button
            variant="contained"
            style={{background:"rgb(14, 41, 84)"}}
            onClick={createmultipleTable}
            fullWidth
            sx={{ textTransform: "uppercase" }}
          >
            Add Tables
          </Button>
        </Box>
      )}
    </Box>
      </Modal>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Settlementmodal getTables={getTables} inviceAmount={inviceAmount} invoiceNo={invoiceNo} invoicemodal={invoicemodal} invoicemodalClose={invoicemodalClose} />
    </>
  );
}

export default Tables;
