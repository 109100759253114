import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Box, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const HourlySales = ({ Startdate }) => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
  const API_URL = `${BASE_Url}/user-master/hourly-sales-report/${saasId}/${storeId}/${Startdate}`;

  useEffect(() => {
    if (!Startdate) return;

    setLoading(true);
    setError(null);

    axios
      .get(API_URL)
      .then((response) => {
        const { hours, sales } = response.data.data;

        // Ensure sales data is converted to numbers
        const numericSales = sales.map((sale) => parseFloat(sale));

        setChartData({
          labels: hours,
          datasets: [
            {
              label: 'Total Sales (₹)',
              data: numericSales,
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
            },
          ],
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching chart data:', err);
        setError('Failed to load chart data');
        setLoading(false);
      });
  }, [Startdate]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Hourly Sales Report',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Hour of the Day',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Total Sales (₹)',
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Box sx={{ padding: 2, maxWidth: 800, margin: 'auto' }}>
      <Typography variant="h5" align="center" gutterBottom>
        Hourly Sales Report
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" align="center">
          {error}
        </Typography>
      ) : (
        <Bar data={chartData} options={options} />
      )}
    </Box>
  );
};

export default HourlySales;
