import React, { useEffect, useState } from "react";
import { BsArrowLeft, BsTypeH1 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelect } from "@material-tailwind/react";
import { BASE_Url } from "../../URL.js/URL";
import axios from "axios";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import InventoryModal from "./InventoryModal";
import { Box, Button, Divider, Grid, IconButton, InputBase, MenuItem, Paper, Select, TextField } from "@mui/material";
import { Row } from "reactstrap";
import SearchIcon from "@mui/icons-material/Search";
import AddInventoryItemModal from "./AddInventoryItemModal";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import UpdateInventoryModal from "./UpdateInventoryModal";
import { Col } from "react-bootstrap";
import { Tab, Tabs } from "react-bootstrap";
import FinishedProduct from "./FinishedProduct";
const Inventory = () => {
const [inventryShow, setInventryShow] = useState(false);
const [updateInventory, setUpdateInventory] = useState(false);
const [selectedRow, setSelectedRow] = useState(null);
const [searchTerm, setSearchTerm] = useState("");
const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const [data, setData] = useState([]);


  const [totalRows, setTotalRows] = useState(0); 
  const getAllData = (pageNumber) => {

    axios
      .get(`${BASE_Url}/getall-inventory-bom/${saasId}/${storeId}/${pageNumber}`)
      .then((res) => {
        if (res.status === 200) {
          console.log("TABLE", res);
          setData(res.data.data);
          setTotalRows(res.data.count);
        }
      })
      .catch((err) => console.log(err));
  };

    useEffect(() => {
      // Fetch initial data or perform any necessary setup
      if(!searchTerm){
        const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA")) || {};
        if (storeId && saasId) {
          getAllData(1, storeId, saasId);
        }
      }
    }, [searchTerm]); 
  // ---------------------------
  const columns = [
    {
      name: "Raw Material name",
      center: true,
      selector: (row) => row.inventory_name,
    },
    {
      name: "Item Code",
      center: true,
      selector: (row) => row.id,
    },
    {
      name: "Opening Qty",
      center: true,
     selector: (row) => row.opening_qty,
    },

    {
      name: "Closing Qty",
      center: true,
      selector: (row) => row.closing_qty,
    },
    {
      name: "Consumed Qty",
      center: true,
      selector: (row) => row.consumed,
    },
    {
      name: "Cost per Unit", 
      center: true,
      selector: (row) => row.cost,
    },
    {
      name: "Unit", 
      center: true,
      selector: (row) => row.unit,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <div style={{ display: "flex", gap: "8px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setInventryShow(true)} // Handle button click
        >
          <AddIcon /> {/* Using Add icon */}
        </Button>

        <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setSelectedRow(row); // Set selected row data for editing
              setUpdateInventory(true); // Open the update modal
            }}
          >
            <EditIcon />
          </Button>
        </div>
        
      ),
    },
  ];



  // ---------------------------
  //Search by name api intigration 
  
  // const [searchResults, setSearchResults] = useState([]);
  // useEffect(() => {
  //   if (searchTerm) {
  //     axios
  //      .get(`${BASE_Url}/search-inventory-bom/${saasId}/${storeId}/${searchTerm}`)
  //      .then((res) => {
  //         if (res.status === 200) {
  //           setData(res.data.data);
  //         }
  //       })
  //      .catch((err) => console.log(err));
  //   } else {
  //     console.log("call here why")
  //     getAllData(1);
  //   }
  // }, [searchTerm]);

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      if(searchTerm){
        const response = await axios.get(
          `${BASE_Url}/search-inventory-bom/${saasId}/${storeId}/${searchTerm}`
        );
        console.log("API response:", response.data);
        setData(response.data.data);

      }else{
        getAllData(1);
      }
    } catch (error) {
      console.error("Error fetching Api:", error);
    }
  };
  const [uomModalOpen, setUomModalOpen] = useState(false);
const navigate =useNavigate()
  const handleAddUomClick = () => {
    navigate("/AllUom")
  };
    const [activeTab, setActiveTab] = useState("RawMeterial");

  return (

    <>
     <Paper 
  elevation={3} 

><Tabs
        defaultActiveKey="RawMeterial"
        id="uncontrolled-tab-example"
        className="mb-3  fw-bold"
        fill
        style={{
          background: "#FFF",
          boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        }}
        onSelect={(key) => {
          setActiveTab(key);
        }}
      >
        <Tab eventKey="RawMeterial" title="Raw Material">
  <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
  <div className="container">
    <Grid container className="my-3 px-3" spacing={2} alignItems="center">
{/*     
      <Button 
          onClick={() => setInventryShow(true)}
          fullWidth variant="contained">Add Raw Material</Button> */}
          <Col className="mt-2" xs={12} sm={6}>
            <Paper
              component="form"
              sx={{
                height: "40px",
                p: "2px 4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // width: 600,
                background: "#f2f1f1",
                // border: "2px solid white",
                // borderRadius: "10px",
              }}
              // onSubmit={(e) => {
              //   getSearchEmployee(e);
              // }}
            >
              
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder="Search Items"
                inputProps={{ "aria-label": "search Items" }}
              />
               
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
               style={{
                backgroundColor: "dark-purple",
               }}
                // type="submit"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Col>

          <Col className="mt-2" xs={12} sm={3}>
          </Col>
          <Col className="mt-2" xs={12} sm={3}>
          <Button className='fw-bold' style={{background:"#4B0082"}} variant="contained" color="primary" onClick={handleAddUomClick}>Create UOM</Button>
          </Col>
    </Grid>
      <div className="row">
        
        {  data.length > 0 && (
            <div style={{ height: '400px', overflowY: 'scroll' }}>
            <DataTable
            columns={columns}
            responsive={true}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={10}
            onChangePage={(page) => {getAllData(page)}}
            />
             </div> 
             
            )}
        
      </div>
    </div>
  </Box>

 </Tab>
 
 <Tab eventKey="FinishedProduct" title="Finished Product">
 <FinishedProduct />
        </Tab>
      </Tabs>
</Paper>
  

      <AddInventoryItemModal getAllData={getAllData} show={inventryShow} onHide={() => setInventryShow(false)} />
      <UpdateInventoryModal 
        getAllData={getAllData} 
        show={updateInventory} 
        onHide={() => setUpdateInventory(false)} 
        selectedRow={selectedRow} 
      />

    </>
  );
};

export default Inventory;
