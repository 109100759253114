import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Button, IconButton, Box } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import Swal from "sweetalert2";
import { BASE_Url } from "../../../URL.js/URL";
import CreateUom from "./CreateUom";



const AllUom = () => {
  const [uomData, setUomData] = useState([]);
  const [uomModalOpen, setUomModalOpen] = useState(false);
  const [selectedUom, setSelectedUom] = useState(null);
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

  // Fetch UOM data
  const fetchUomData = async () => {
    try {
      const response = await axios.get(`${BASE_Url}/uom/get-all/${saasId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setUomData(response.data.data);
    } catch (error) {
      console.error("Error fetching UOM data:", error);
    }
  };

  useEffect(() => {
    fetchUomData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${BASE_Url}/uom/delete/${id}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "UOM has been deleted.",
        });

        // Refresh data
        fetchUomData();
      }
    } catch (error) {
      console.error("Failed to delete UOM:", error);
    }
  };

  const handleEdit = (uom) => {
    setSelectedUom(uom);
    setUomModalOpen(true);
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Unit",
      selector: (row) => row.unit,
      sortable: true,
    },
    {
      name: "SaaS ID",
      selector: (row) => row.saasId,
      sortable: true,
    },
    {
      name: "Actions",
      center: true,
      cell: (row) => (
        <>
          {/* <IconButton aria-label="edit" onClick={() => handleEdit(row)}>
            <Edit style={{ color: "green" }} />
          </IconButton> */}
          <IconButton aria-label="delete" onClick={() => handleDelete(row.id)}>
            <Delete style={{ color: "#C81D20" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const handleAddUomClick = () => {
    setSelectedUom(null);
    setUomModalOpen(true);
  };

  return (
    <>
    <div className="px-4 mt-4">
      <Box
        className="mb-2"
        p={2}
        borderRadius={2}
        border="1px solid #e0e0e0"
        boxShadow="0 2px 10px rgba(0,0,0,0.1)"
        width="100%"
        height="fit-content"
        bgcolor="white"
      >
        <div className="flex justify-between items-center">
          <h1 className="text-2xl">Unit of Measurement</h1>
          <Button
            className="fw-bold"
            style={{ background: "#4B0082" }}
            variant="contained"
            color="primary"
            onClick={handleAddUomClick}
          >
            Create
          </Button>
        </div>
      </Box>
      <div className="bg-white p-6 shadow rounded-lg">
        <div style={{ height: "200px", overflow: "auto" }}>
          <DataTable
            columns={columns}
            data={uomData}
            pagination
            responsive
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </div>
      </div>
    </div>

    <CreateUom
        isOpen={uomModalOpen}
        toggle={() => setUomModalOpen(!uomModalOpen)}
        fetchgetUomList={fetchUomData}
        selectedUom={selectedUom} // Pass selected UOM for editing
      />
    </>
  );
};

export default AllUom;
