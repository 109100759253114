import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Settlementmodal from "../Settlementmodal/Settlementmodal";

const PendingForSettle = () => {
  const [pendingOrders, setPendingOrders] = useState([]);
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const [open, setOpen] = useState(false)
  const [invoiceNo, setInvoiceNo] = useState("")
  const [invoiceAmount, setInvoiceAmount] = useState("")
  const CloseModal = ()=>{
    setOpen(false)
  }
   
  const Getpendingorder = async ()=>{
    try {
      const res =await axios.get(`${BASE_Url}/transaction/pending_settlement/${saasId}/${storeId}`)
      setPendingOrders(res.data.data);
    } catch (error) {
        console.log(error)
    }
  }
  useEffect(() => {
    Getpendingorder()
  }, []);

  return (
    <>
    <TableContainer
      style={{
        maxHeight: "calc(100vh - 180px)",
        overflowY: "auto",
      }}
      component={Paper}
    >
      <Table>
        <TableHead className="bg-dark-purple">
          <TableRow>
            <TableCell className=" text-white">Invoice No</TableCell>
            <TableCell className=" text-white">Order ID</TableCell>
            <TableCell className=" text-white">Business Date</TableCell>
            <TableCell className=" text-white">Total Amount</TableCell>
            <TableCell className=" text-white text-center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pendingOrders.map((order) => (
              <TableRow key={order.orderId}>
              <TableCell>{order.invoiceNo}</TableCell>
              <TableCell>{order.orderId}</TableCell>
              <TableCell>{order.businessDate}</TableCell>
              <TableCell>{order.totalAmount}</TableCell>
              <TableCell className="text-center">
                <Button
                  variant="contained"
                  className="mx-2"
                  style={{ background: "rgb(14, 41, 84)", color: "white" }}
                  color="primary"
                  onClick={()=>{
                    setInvoiceNo(order.invoiceNo)
                    setInvoiceAmount(order.totalAmount)
                    setOpen(true)
                }}
                  >
                  Settle Order
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Settlementmodal invoicemodal={open} invoicemodalClose={CloseModal} inviceAmount={invoiceAmount} invoiceNo={invoiceNo} getTables={Getpendingorder}/>
          </>
  );
};

export default PendingForSettle;
