import moment from "moment";
import React from "react";
import { PrintProvider, Print } from "react-easy-print";

function KotPrintModal({orderData}) {

  const styles = {
    fontFamily: "'Roboto Mono', monospace",
    fontSize: "medium",
    fontWeight: "bold",
  };

  const customLineStyle = {
    borderBottom: "3px dashed black",
    width: "100%",
    display: "inline-block",
    // margin: "8px 0",
  };
   //total qty calculation
   const notprintedItems = orderData?.item_detail_list.filter((item) => item.printed === false);
   const notprintedtotalqty = notprintedItems?.reduce((acc, curr) => acc + curr.item_qty, 0);
   const totalqty = orderData?.item_detail_list.reduce((acc, curr) => acc + curr.item_qty, 0);
   const ConditionQty = notprintedItems?.length > 0? notprintedtotalqty: totalqty; 

   const Conditionlayshow = notprintedItems?.length > 0 ? notprintedItems: orderData?.item_detail_list; 
   let lastCategory = null;
   
  const receiptContent = (
    <div className="px-3" style={styles}>
        <div   className="d-flex  justify-content-around fw-bold">
      <p
        style={{ fontSize: "1.6rem" }}
        className="d-flex  justify-content-around fw-bold m-0"
      >
       KOT {orderData?.Kotno}
      </p>
      </div>
      <div>
      <p
        style={{ fontSize: "1.1rem" }}
        className="d-flex justify-content-around fw-bold m-0"
      >
    Dine-in
      </p>
      </div>
      
      <div className="flex items-center justify-center m-0">
        {/* <p>KOT Printed By: {orderData.created_by}</p> */}
        <p className="m-0">{moment(new Date()).format("DD-MMM-YYYY h:mm:ss a")}</p>
      </div>
      <div className="fw-bold " style={customLineStyle}></div>
      <div >
      <p
        style={{ fontSize: "1.1rem" }}
        className="d-flex  justify-content-around fw-bold m-0"
      >
    Order ID: {orderData.order_id}
      </p>
      <p
        style={{ fontSize: "1.1rem" }}
        className="d-flex  justify-content-around fw-bold m-0"
      >
    Table No. {orderData.table_number}
      </p>
      </div>
      <div className="fw-bold " style={customLineStyle}></div>

      <table className="w-100  mx-md-auto" style={{ fontSize: "1rem" }}>
        <thead>
          <tr className="border-dark" style={{ borderBottomWidth: "2px" }}>
            {/* <th>Order ID</th> */}
            <th >Item</th>
            <th  className="text-center">Qty</th>
          </tr>
        </thead>
        <tbody>
        {Conditionlayshow.map((el)=> {
           const showCategory = el?.category !== lastCategory;
           if (showCategory) {
             lastCategory = el?.category; // Update the last displayed category
           }
              return (
                <>
                  {" "}
                  {el.item_qty > 0 && (
                    <>
                      <tr >
                      {/* <td ></td> */}
                        <td className="w-1/2 font-bold" style={{fontSize: "14px",}} >
                        {showCategory && <span>{el?.category}<br /></span>}
                       -{el?.item_name}   {el?.localItemName}
                        </td>
                        <td
                        style={{fontSize: "14px"}}
                          className="text-center font-bold"
                          
                        >
                          {el.item_qty}
                        </td>
                  
                      </tr>
                  
                    </>
                  )}
                </>
              );
            })}
        </tbody>

      </table>
 <div className="fw-bold" style={customLineStyle}></div>
      <div className="flex justify-start gap-1 px-3">
        <p>Total Qty: </p>
        <p>{ConditionQty}</p>
        <p></p>
        <p></p>
      </div>
  {orderData.note && <>
 <div className="fw-bold" style={customLineStyle}></div>
  <span>Note: {orderData.note}</span>
  </>
  }
    
    </div>
  );

  return (
    <PrintProvider>
      <Print>
        <div style={styles}>{receiptContent}</div>
      </Print>
    </PrintProvider>
  );
}

export default KotPrintModal;
