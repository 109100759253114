import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Button, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Swal from "sweetalert2";
import { BASE_Url } from '../../URL.js/URL';
import { linkCustomer } from '../../redux/slices/selectOrdersSlice';

function LinkCustomer({ open, onClose }) {
  const dispatch = useDispatch();
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_Url}/user-master/get-user-details/${saasId}/${storeId}`);
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchCategories();
    }
  }, [open, saasId, storeId]);

  const handleSave = () => {
    const customer = categories.find(category => category.mobile_number === selectedCustomer);
    if (customer) {
      dispatch(linkCustomer(customer));
      Swal.fire("Customer Linked Successfully");
      handleClose();
    } else {
      Swal.fire("Failed to Link Customer");
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedCustomer(''); // Reset selection on close
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="link-customer-dialog" fullWidth maxWidth="sm">
      <DialogTitle id="link-customer-dialog">Link Customer</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="linkCustomerLabel">Link Customer</InputLabel>
              <Select
                id="linkCustomer"
                label="Link Customer"
                value={selectedCustomer}
                onChange={(e) => setSelectedCustomer(e.target.value)}
              >
                {categories.map((option) => (
                  <MenuItem key={option.customer_id} value={option.mobile_number}>
                    {option.mobile_number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LinkCustomer;
