import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  Modal,
  Typography,
  Grid,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import Swal from 'sweetalert2';
const UpdateStore = ({ show, handleClose, selecdData }) => {
  const [formData, setFormData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const steps = ['Basic Info', 'Address Info', 'Other Details'];

  // Update form data when selecdData changes
  useEffect(() => {
    setFormData({
        store_name: selecdData.storeName || '',
        geo_code: selecdData.geoCode || '',

        online: selecdData.online || false,
        rating: selecdData.rating || 0,
        leadref_id: selecdData.leadref_id || '',
        city: selecdData.city || '',
        state: selecdData.state || '',
        country: selecdData.country || '',
        address: selecdData.address || '',
        taxable: selecdData.taxable || false,
        gst_code: selecdData.gstCode || '',
        tnc: selecdData.tnc || '',
        hsn_code: selecdData.hsnCode || '',
        store_type: selecdData.storeType || '',
        status: selecdData.status || '',
        exclusive_tax: selecdData.exclusiveTax || 0,
        delivery_charges: selecdData.deliveryCharges || 0,
        minimum_order_value: selecdData.minimum_order_value || 0,
        inclusive_tax: selecdData.inclusiveTax || 0,
        bank_account: selecdData.bankAccount || '',
        bank_ifsc: selecdData.bank_ifsc || '',
        bank_branch: selecdData.bankBranch || '',
        email: selecdData.email || '',
        phone_no: selecdData.phoneNo || '',
        check_inventory: selecdData.checkInventory || '',
        store_address1: selecdData.storeAddress1 || '',
        store_address2: selecdData.storeAddress2 || '',
        header_line1: selecdData.haderLine1 || '',
        header_line2: selecdData.haderLine2 || '',
        header_line3: selecdData.haderLine3 || '',
        header_line4: selecdData.haderLine4 || '',
        footer_line1: selecdData.footerLine1 || '',
        footer_line2: selecdData.footerLine2 || '',
        footer_line3: selecdData.footerLine3 || '',
        footer_line4: selecdData.footerLine4 || '',
        footer_line5: selecdData.footerLine5 || '',
        footer_line6: selecdData.footerLine6 || '',
        footer_line7: selecdData.footerLine7 || '',
        footer_line8: selecdData.footerLine8 || '',
        footer_line9: selecdData.footerLine9 || '',
        footer_line10: selecdData.footerLine10 || '',
        commission: selecdData.commission || false,
        whatsApp: selecdData.whatsApp || false,
    });
  }, [selecdData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

    // Save logo to the server
    const [logoFile, setLogoFile] = useState(null);
      // Handle logo file input change
    const [qrFile, setQrFile] = useState(null);

      const handleLogoChange = (event) => {
        setLogoFile(event.target.files[0]);
      };
      const handleQRChange = (event) => {
        setQrFile(event.target.files[0]);
      };
      // Save logo to the server
      const saveLogo = async (saasId) => {
        if (!logoFile) {
            return;
        }
    
        const logoFormData = new FormData();
        logoFormData.append("file", logoFile);
    
        try {
          const response = await axios.post(
            `${BASE_Url}/storeMaster/save-store-logo/${saasId}`,
            logoFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setFormData({ ...formData, store_logo: response.data.logoUrl });
        //   alert("Logo uploaded successfully!");
        } catch (error) {
          console.error("Error uploading logo:", error);
          // alert("Failed to upload logo.");
        }
      };

      const saveQR = async (saasId,storeId) => {
        if (!qrFile) {
          return;
        }
    
        const logoFormData = new FormData();
        logoFormData.append("file", qrFile);
    
        try {
          const response = await axios.post(
            `${BASE_Url}/storeMaster/save-qrcode/${saasId}/${storeId}`,
            logoFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setFormData({ ...formData, paymentQRCode: response.data.logoUrl });
        //   alert("Logo uploaded successfully!");
        } catch (error) {
          console.error("Error uploading logo:", error);
          // alert("Failed to upload logo.");
        }
      };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${BASE_Url}/storeMaster/update-store/${selecdData.saasId}/${selecdData.storeId}`,
        formData,
        { headers: { 'Content-Type': 'application/json' } }
      );
      const { saasId, storeId } = response.data.data;
      console.log(response.data);
      if ( storeId) {
        await saveLogo(storeId);
        await saveQR(saasId,storeId);

      }
         // Show success alert
    Swal.fire({
        icon: 'success',
        title: 'Store Updated Successfully',
        text: 'Your changes have been saved.',
        confirmButtonText: 'OK',
      });
      handleClose();
      setActiveStep(0)
      
    } catch (error) {
      console.error('Error updating store:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Store Name"
                name="store_name"
                value={formData.store_name || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Geo Code"
                name="geo_code"
                value={formData.geo_code || ''}
                onChange={handleChange}
              />
            </Grid>
       
            <Grid item xs={12} sm={6}>
  <TextField
    fullWidth
    select
    label="Localization"
    name="localization"
    value={formData.localization || ''}
    onChange={handleChange}
  >
    <MenuItem value="en">English</MenuItem>
    <MenuItem value="enar">English And Arabic</MenuItem>
    {/* Add more language options as needed */}
  </TextField>
</Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label="Rating"
                name="rating"
                value={formData.rating || ''}
                onChange={handleChange}
              />
            </Grid>
                 <Grid item xs={6}>
                        <TextField
                          fullWidth
                          type="file"
                          inputProps={{ accept: "image/*" }}
                          label="Store Logo"
                          onChange={handleLogoChange}
                          InputLabelProps={{
                            shrink: true,  // Moves label to top when file is selected or input is focused
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          type="file"
                          inputProps={{ accept: "image/*" }}
                          label="QR Logo"
                          onChange={handleQRChange}
                          InputLabelProps={{
                            shrink: true,  // Moves label to top when file is selected or input is focused
                          }}
                        />
                        
                      </Grid>
                      {/* <Grid item xs={6}> </Grid> */}
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="online"
                    checked={formData.online || false}
                    onChange={handleChange}
                  />
                }
                label="Online"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="whatsApp"
                    checked={formData.whatsApp || false}
                    onChange={handleChange}
                  />
                }
                label="whatsApp"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="commission"
                    checked={formData.commission || false}
                    onChange={handleChange}
                  />
                }
                label="Commission"
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                name="city"
                value={formData.city || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="State"
                name="state"
                value={formData.state || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                value={formData.country || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={formData.address || ''}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Header Line 1"
                name="header_line1"
                fullWidth
                value={formData.header_line1}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Header Line 2"
                name="header_line2"
                fullWidth
                value={formData.header_line2}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Header Line 3"
                name="header_line3"
                fullWidth
                value={formData.header_line3}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Header Line 4"
                name="header_line4"
                fullWidth
                value={formData.header_line4}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Footer Line 1"
                name="footer_line1"
                fullWidth
                value={formData.footer_line1}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Footer Line 2"
                name="footer_line2"
                fullWidth
                value={formData.footer_line2}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Footer Line 3"
                name="footer_line3"
                fullWidth
                value={formData.footer_line3}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Footer Line 4"
                name="futterLine4"
                fullWidth
                value={formData.futterLine4}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="GST Code"
                name="gst_code"
                value={formData.gst_code || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Terms and Conditions"
                name="tnc"
                value={formData.tnc || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label="Delivery Charges"
                name="delivery_charges"
                value={formData.delivery_charges || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone_no"
                value={formData.phone_no || ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: 4,
          borderRadius: 2,
          boxShadow: 24,
          width: 600,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Update Store
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 3 }}>{renderStepContent(activeStep)}</Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'Submit'}
            </Button>
          ) : (
            <Button onClick={handleNext} variant="contained">
              Next
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateStore;
