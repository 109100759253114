import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import Swal from 'sweetalert2';
import CreateDeliveryBoy from './CreateDeleveryBoy';
import EditDeliveryBoy from './EditeDeliveryBoy';

const GetDeliveryBoy = () => {

    const [open, setOpen] =  useState(false)
    const [selectedDelviryboy, setDeleveryboy] =  useState("")
    const handleClose = () => setOpen(false);
    const { storeId, saasId } = JSON.parse(
        localStorage.getItem("STORE_DATA"))
        const [deliveryBoys, setDeliveryBoys] = useState([]);
        const fetchDeliveryBoys = async () => {
            try {
                const response = await axios.get(`${BASE_Url}/delivery/get-user-delivery-details/${saasId}/${storeId}`);
                console.log(response.data.data)
                setDeliveryBoys(response.data.data);
            } catch (error) {
                console.error('Error fetching delivery boys:', error);
            }
        };

        useEffect(() => {

            fetchDeliveryBoys();
        }, []);

        const handleDelete = async (id) => {
            try {
            const res =await axios.put(`${BASE_Url}/delivery/remove-delivery-detail/${id}`);
            if(res.data.status){
                fetchDeliveryBoys();
                Swal.fire({
                    title: 'Deleted!',
                    text: 'Delivery boy has been deleted successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                })
            }
            } catch (error) {
            console.error('Error deleting delivery boy:', error);
            Swal.fire({
                title: 'Error!',
                text: 'There was an error deleting the delivery boy.',
                icon: 'error',
                confirmButtonText: 'OK'
            })
            }
        };

  return (
    <div>
        <Box
                className="mb-2"
                p={2}
                borderRadius={2}
                border="1px solid #e0e0e0"
                boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                width="100%"
                height="fit-content"
                bgcolor="white"
            >
                <div className="flex justify-between items-center">
                    <h1 className="text-2xl">Create Delivery Boy</h1>
                    <CreateDeliveryBoy fetchDeliveryBoys={fetchDeliveryBoys}/>
                    {/* <Button
                        className="fw-bold"
                        style={{ background: 'rgb(14, 41, 84)' }}
                        variant="contained"
                        color="primary"
                        // onClick={handleClickOpen}
                    >
                        Create
                    </Button> */}
                </div>
            </Box>
           <Box className=" w-100 rounded-lg" style={{  height: "calc(100vh - 250px)", marginBottom: "40%", overflowY: "auto", overflowX:"auto" }}>
                <TableContainer component={Paper}>
                    <Table className="text-nowrap" aria-label="simple table">
                        <TableHead className="bg-dark-purple">
                            <TableRow>
                                <TableCell className="text-white">User Name</TableCell>
                                <TableCell className="text-white">Mobile Number</TableCell>
                                <TableCell className="text-white">Address</TableCell>
                                <TableCell className="text-white">city</TableCell>
                                <TableCell className="text-white">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                    {/* {[1, 2, 3].map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>2023-01-01</TableCell>
                            <TableCell>2023-12-31</TableCell>
                            <TableCell>{Math.floor(Math.random() * 100)}%</TableCell>
                            <TableCell>
                                <Button variant="contained" color="secondary">Edit</Button>
                                <Button variant="contained" color="error" style={{ marginLeft: '10px' }}>Delete</Button>
                            </TableCell>
                        </TableRow>
                    ))} */}
            {deliveryBoys.map((deliveryBoy, index) => (
                <TableRow key={index}>
                    <TableCell>{deliveryBoy.user_name}</TableCell>
                    <TableCell>{deliveryBoy.mobile_number}</TableCell>
                    <TableCell>{deliveryBoy.address}</TableCell>
                    <TableCell>{deliveryBoy.city}</TableCell>
                    <TableCell>
                        <Button onClick={()=>{setOpen(true);
                            setDeleveryboy(deliveryBoy)
                        }} variant="contained" color="secondary">Edit</Button>
                        <Button onClick={()=>{handleDelete(deliveryBoy.user_id)}} variant="contained" color="error" style={{ marginLeft: '10px' }}>Delete</Button>
                    </TableCell>
                </TableRow>
            ))}
            
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
      <EditDeliveryBoy open={open} handleClose={handleClose} fetchDeliveryBoys={fetchDeliveryBoys} deliveryBoy={selectedDelviryboy} />
    </div>
  )
}

export default GetDeliveryBoy