import React, { useState } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import Swal from 'sweetalert2';

const EditDeliveryBoy = ({ open, handleClose, deliveryBoy ,fetchDeliveryBoys }) => {
    const [formData, setFormData] = useState({
        aadhaar_number: '',
        name: '',
        mobile_number: '',
        address: '',
        city: '',
        pincode: ''
    });

    React.useEffect(() => {
        if (open) {
            setFormData({
                aadhaar_number: deliveryBoy.addhaar_number || '',
                name: deliveryBoy.user_name || '',
                mobile_number: deliveryBoy.mobile_number || '',
                address: deliveryBoy.address || '',
                city: deliveryBoy.city || '',
                pincode: deliveryBoy.pincode || ''
            });
        }
    }, [open, deliveryBoy]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
           const res = await axios.put(`${BASE_Url}/delivery/update-delivery-user/${deliveryBoy.user_id}`, formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if(res.data.status){
                handleClose();
                fetchDeliveryBoys()
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Delivery boy updated successfully!'
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to update delivery boy. Please try again.'
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to update delivery boy. Please try again.'
            });
            console.error('Error updating delivery boy:', error);
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ ...modalStyle }}>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Aadhaar Number"
                        name="aadhaar_number"
                        value={formData.aadhaar_number}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Mobile Number"
                        name="mobile_number"
                        value={formData.mobile_number}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="City"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Pincode"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Update
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

export default EditDeliveryBoy;