import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import DownloadComponent from "../CSVDownload/DownloadComponent";
import { useReactToPrint } from "react-to-print";
import DownloadPDFButton from "../CSVDownload/DownloadPDFButton";

const CashProfitLoss = ({ start, todate }) => {
  const [showRevenue, setShowRevenue] = useState(false); // Toggle for Revenue and Delivery Charges
  const [showExpenses, setShowExpenses] = useState(false); // Toggle for Expense breakdown
  const [allData, setAllData] = useState(null); // State to store API response data
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const tableRef = useRef(null); // Ref for the table container
  const [printingStart, setPrintingStart] = React.useState(false);

  // Fetch data from API
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_Url}/user-master/profit-loss-report/${saasId}/${storeId}/${start}/${todate}`
      );
      if (response.data.status) {
        setAllData(response.data.data || null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [start, todate]);

  // CSV Headers
  const headers = [
    { label: "Date", key: "date" },
    { label: "Net Amount", key: "netAmount" },
    { label: "Gross Amount", key: "grossAmount" },
    { label: "Total Payment Amount", key: "total_payment_amount" },
    { label: "Total Due Amount", key: "total_due_amount" },
  ];

  const handleTablePrint = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: "NetSales_Report",
    onBeforePrint: () => {
      setPrintingStart(true);
    },
    onAfterPrint: () => {
      setPrintingStart(false);
    },
  });

  if (!allData) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" align="center">
          Loading...
        </Typography>
      </Box>
    );
  }

  const { totalSale, deliveryCharges, totalExpenses, expenses,received } = allData;

  return (
    <Box >
      {/* <DownloadComponent headers={headers} data={allData} fileName="NetSalesReport" /> */}
      {/* <Button
        variant="contained"
        className="mx-2"
        style={{ background: "rgb(14, 41, 84)", color: "white" }}
        onClick={handleTablePrint}
      >
        PRINT
      </Button> */}
      {/* <DownloadPDFButton
        data={allData}
        headers={headers}
        fileName="NetSalesReport.pdf"
      /> */}

      <Card className="mt-3" ref={tableRef} sx={{ maxWidth: 900, margin: "0 auto" }}>
        <CardContent>
          {/* Header Section */}
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
            Cash Profit & Loss
          </Typography>

          {/* Profit Summary */}
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
            <Typography variant="subtitle1">Net Cash Profit Summary</Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#249b00" }}
            >
              {totalSale - totalExpenses}
            </Typography>
          </Box>

          <Divider />

          {/* Revenue Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginY: 2,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Total Revenue : {totalSale + deliveryCharges}
            </Typography>
            <IconButton onClick={() => setShowRevenue(!showRevenue)}>
              {showRevenue ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          {showRevenue && (
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Total Sales</TableCell>
                    <TableCell sx={{ textAlign: "right" }}>{totalSale}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Received</TableCell>
                    <TableCell sx={{ textAlign: "right" }}>{received}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Delivery Charges</TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      {deliveryCharges}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Divider />

          {/* Expenses Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginY: 2,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Total Expenses : {totalExpenses}
            </Typography>
            <IconButton onClick={() => setShowExpenses(!showExpenses)}>
              {showExpenses ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          {showExpenses && expenses && (
            <TableContainer>
              <Table>
                <TableBody>
                  {Object.entries(expenses).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>{value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Divider sx={{ marginY: 2 }} />

          {/* Net Cash Profit */}
          <Box
            sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}
          >
            <Typography variant="h6">Net Cash Profit</Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#249b00" }}
            >
              {totalSale - totalExpenses}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CashProfitLoss;
