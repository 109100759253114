import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert2
import { BASE_Url } from "../../URL.js/URL";

const LoginModal = ({ show, setShow ,setIsPhoneNumberSaved}) => {
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile_numbers: "",
    otp: "",
  });
  const [errors, setErrors] = useState({});

  const handleCloseModal = () => setShow(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateFirstStep = () => {
    const errors = {};
    if (!formData.mobile_numbers || formData.mobile_numbers.length !== 10)
      errors.mobile_numbers = "Phone number must be 10 digits";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateSecondStep = () => {
    const errors = {};
    if (!formData.otp) errors.otp = "OTP is required";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onSubmitFirstStep = async (e) => {
    e.preventDefault();
    if (!validateFirstStep()) return;

    try {
      setPhoneNumber(formData.mobile_numbers);
     
      localStorage.setItem("phone_number", formData.mobile_numbers);
      const response = await axios.get(
        `${BASE_Url}/otp/resend-otp/${formData.mobile_numbers}`
      );
      if (response.status === 200) {
        // Swal.fire({
        //   icon: "success",
        //   title: "Success",
        //   text: "OTP sent successfully!",
        // });
        setStep(2); // Move to second step
      }
    } catch (error) {
      if (error.response.data.message === "User Already Registered") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "User Already Registered!",
        });
    
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error resending OTP. Please try again later.",
        });
      }
    }
  };

  const onSubmitSecondStep = async (e) => {
    e.preventDefault();
    if (!validateSecondStep()) return;

    try {
      const response = await axios.post(`${BASE_Url}/otp/validate-otp`, {
        mobile_no: phoneNumber,
        otp: formData.otp,
      });
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "OTP validated successfully!",
        });
        setIsPhoneNumberSaved(formData.mobile_numbers)
        handleCloseModal(); // Move to next step or page
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to validate OTP.",
      });
    }
  };

  return (
    <div>
      {/* MUI Modal */}
      <Modal open={show} onClose={handleCloseModal}>
        <Box
          sx={{
            width: 400,
            margin: "auto",
            marginTop: "10%",
            backgroundColor: "white",
            padding: 3,
            borderRadius: "8px",
          }}
        >
          {step === 1 && (
            <form onSubmit={onSubmitFirstStep}>
            
              <div>
                <TextField
                  label="Phone Number"
                  name="mobile_numbers"
                  value={formData.mobile_numbers}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(errors.mobile_numbers)}
                  helperText={errors.mobile_numbers}
                  type="number"
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Next
              </Button>
            </form>
          )}

          {step === 2 && (
            <form onSubmit={onSubmitSecondStep}>
              <div>
                <TextField
                  label="Enter OTP"
                  name="otp"
                  value={formData.otp}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(errors.otp)}
                  helperText={errors.otp}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Validate OTP
              </Button>
            </form>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default LoginModal;
