import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button, 
  Box, 
  Typography 
} from '@mui/material';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import Swal from 'sweetalert2';

export function PasswordUpdateModal({ isOpen, onClose }) {
  const [formValues, setFormValues] = useState({
    mobile_number: '',
    password: '',
    confirm_password: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validate = () => {
    const errors = {};
    if (!formValues.mobile_number) {
      errors.mobile_number = 'User ID is required';
    }
    if (!formValues.password) {
      errors.password = 'Old Password is required';
    } 
    if (!formValues.confirm_password) {
      errors.confirm_password = 'New password is required';
    } else if (formValues.password == formValues.confirm_password) {
      errors.confirm_password = "Old password and new password can't be same";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    setIsLoading(true);
    try {
        const result = await axios.put(`${BASE_Url}/user-master/change-password-user`, formValues);
        if(result.data.status){
            console.log("Password Updated");
            setIsLoading(false);
            setFormValues({ mobile_number: '', password: '', confirm_password: '' });
           onClose();
           Swal.fire ({
            icon: 'success',
            title: 'Password Updated',
            text: result.data.message,
        })
        }else{
            setIsLoading(false);
            console.log("Password not Updated");
            onClose();
            Swal.fire ({
              icon: 'error',
              title: 'Error',
              text: result.data.message,
          })
        }
        
    } catch (error) {
        console.log("Error", error);
        setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Update Password</DialogTitle>
      <form >
        <DialogContent>
          <Typography variant="body2" color="textSecondary" paragraph>
            Enter your mobile number and new password to update.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              name="mobile_number"
              label="User ID"
              variant="outlined"
              fullWidth
              value={formValues.mobile_number}
              onChange={handleChange}
              error={!!formErrors.mobile_number}
              helperText={formErrors.mobile_number}
            />
            <TextField
              name="password"
              label="Old Password"
              type="password"
              variant="outlined"
              fullWidth
              value={formValues.password}
              onChange={handleChange}
              error={!!formErrors.password}
              helperText={formErrors.password}
            />
            <TextField
              name="confirm_password"
              label="New Password"
              type="password"
              variant="outlined"
              fullWidth
              value={formValues.confirm_password}
              onChange={handleChange}
              error={!!formErrors.confirm_password}
              helperText={formErrors.confirm_password}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="button" color="primary" variant="contained" disabled={isLoading}>
            {isLoading ? 'Updating...' : 'Update Password'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
