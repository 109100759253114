import { Button } from '@mui/material';
import React from 'react';

const DownloadComponent = ({ headers, data, fileName }) => {
  const handleDownloadXLSX = () => {
    // Create XML header
    const xmlHeader =
      `<?xml version="1.0"?>` +
      `<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet"` +
      ` xmlns:o="urn:schemas-microsoft-com:office:office"` +
      ` xmlns:x="urn:schemas-microsoft-com:office:excel"` +
      ` xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">`;

    // Add worksheet and table
    let xmlRows = `<Worksheet ss:Name="Sheet1"><Table>`;
    xmlRows += `<Row>`; // Add header row
    headers.forEach((header) => {
      xmlRows += `<Cell><Data ss:Type="String">${header.label}</Data></Cell>`;
    });
    xmlRows += `</Row>`;

    // Add data rows
    data.forEach((row) => {
      xmlRows += `<Row>`;
      headers.forEach((header) => {
        xmlRows += `<Cell><Data ss:Type="String">${row[header.key] || ''}</Data></Cell>`;
      });
      xmlRows += `</Row>`;
    });

    // Close table and worksheet
    xmlRows += `</Table></Worksheet>`;

    // Close workbook
    const xmlFooter = `</Workbook>`;

    // Combine all parts
    const xmlContent = xmlHeader + xmlRows + xmlFooter;

    // Create a Blob and trigger download
    const blob = new Blob([xmlContent], { type: 'application/vnd.ms-excel' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xls`;
    link.click();
  };

  return (
    <Button
      variant="contained"
      style={{ background: 'rgb(14, 41, 84)', color: 'white' }}
      className="my-2"
      onClick={handleDownloadXLSX}
    >
      Download XLSX
    </Button>
  );
};

export default DownloadComponent;


// import { Button } from '@mui/material';
// import React from 'react'
// import { CSVLink } from 'react-csv';

// const DownloadComponent = ({headers, data, fileName}) => {
//   return (
//     <CSVLink data={data} headers={headers} filename={fileName} style={{ textDecoration: 'none' }}>
//       <Button variant="contained"     style={{background:"rgb(14, 41, 84)",color:"white"}} className='my-2'>
//         Download CSV
//       </Button>
//     </CSVLink>
//   )
// }

// export default DownloadComponent